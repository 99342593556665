// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.vandenbussche.models

import com.lightningkite.lightningdb.*
import com.lightningkite.UUID
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareAddressFields() {
    val props: Array<SerializableProperty<Address, *>> = arrayOf(Address_individualName, Address_businessName, Address_street, Address_unitOrSuite, Address_city, Address_subcountry, Address_country, Address_postalCode)
    Address.serializer().properties { props }
}
val <K> DataClassPath<K, Address>.individualName: DataClassPath<K, String?> get() = this[Address_individualName]
val <K> DataClassPath<K, Address>.businessName: DataClassPath<K, String> get() = this[Address_businessName]
val <K> DataClassPath<K, Address>.street: DataClassPath<K, String> get() = this[Address_street]
val <K> DataClassPath<K, Address>.unitOrSuite: DataClassPath<K, String?> get() = this[Address_unitOrSuite]
val <K> DataClassPath<K, Address>.city: DataClassPath<K, String> get() = this[Address_city]
val <K> DataClassPath<K, Address>.subcountry: DataClassPath<K, IsoCode2Letter> get() = this[Address_subcountry]
val <K> DataClassPath<K, Address>.country: DataClassPath<K, IsoCode2Letter> get() = this[Address_country]
val <K> DataClassPath<K, Address>.postalCode: DataClassPath<K, String> get() = this[Address_postalCode]
inline val Address.Companion.path: DataClassPath<Address, Address> get() = path<Address>()


object Address_individualName: SerializableProperty<Address, String?> {
    override val name: String by lazy { Address.serializer().descriptor.getElementName(0) }
    override fun get(receiver: Address): String? = receiver.individualName
    override fun setCopy(receiver: Address, value: String?) = receiver.copy(individualName = value)
    override val serializer: KSerializer<String?> by lazy { (Address.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { Address.serializer().descriptor.getElementAnnotations(0) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object Address_businessName: SerializableProperty<Address, String> {
    override val name: String by lazy { Address.serializer().descriptor.getElementName(1) }
    override fun get(receiver: Address): String = receiver.businessName
    override fun setCopy(receiver: Address, value: String) = receiver.copy(businessName = value)
    override val serializer: KSerializer<String> by lazy { (Address.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Address.serializer().descriptor.getElementAnnotations(1) }
}
object Address_street: SerializableProperty<Address, String> {
    override val name: String by lazy { Address.serializer().descriptor.getElementName(2) }
    override fun get(receiver: Address): String = receiver.street
    override fun setCopy(receiver: Address, value: String) = receiver.copy(street = value)
    override val serializer: KSerializer<String> by lazy { (Address.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Address.serializer().descriptor.getElementAnnotations(2) }
}
object Address_unitOrSuite: SerializableProperty<Address, String?> {
    override val name: String by lazy { Address.serializer().descriptor.getElementName(3) }
    override fun get(receiver: Address): String? = receiver.unitOrSuite
    override fun setCopy(receiver: Address, value: String?) = receiver.copy(unitOrSuite = value)
    override val serializer: KSerializer<String?> by lazy { (Address.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { Address.serializer().descriptor.getElementAnnotations(3) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object Address_city: SerializableProperty<Address, String> {
    override val name: String by lazy { Address.serializer().descriptor.getElementName(4) }
    override fun get(receiver: Address): String = receiver.city
    override fun setCopy(receiver: Address, value: String) = receiver.copy(city = value)
    override val serializer: KSerializer<String> by lazy { (Address.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Address.serializer().descriptor.getElementAnnotations(4) }
}
object Address_subcountry: SerializableProperty<Address, IsoCode2Letter> {
    override val name: String by lazy { Address.serializer().descriptor.getElementName(5) }
    override fun get(receiver: Address): IsoCode2Letter = receiver.subcountry
    override fun setCopy(receiver: Address, value: IsoCode2Letter) = receiver.copy(subcountry = value)
    override val serializer: KSerializer<IsoCode2Letter> by lazy { (Address.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<IsoCode2Letter> }
    override val annotations: List<Annotation> by lazy { Address.serializer().descriptor.getElementAnnotations(5) }
}
object Address_country: SerializableProperty<Address, IsoCode2Letter> {
    override val name: String by lazy { Address.serializer().descriptor.getElementName(6) }
    override fun get(receiver: Address): IsoCode2Letter = receiver.country
    override fun setCopy(receiver: Address, value: IsoCode2Letter) = receiver.copy(country = value)
    override val serializer: KSerializer<IsoCode2Letter> by lazy { (Address.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<IsoCode2Letter> }
    override val annotations: List<Annotation> by lazy { Address.serializer().descriptor.getElementAnnotations(6) }
}
object Address_postalCode: SerializableProperty<Address, String> {
    override val name: String by lazy { Address.serializer().descriptor.getElementName(7) }
    override fun get(receiver: Address): String = receiver.postalCode
    override fun setCopy(receiver: Address, value: String) = receiver.copy(postalCode = value)
    override val serializer: KSerializer<String> by lazy { (Address.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Address.serializer().descriptor.getElementAnnotations(7) }
}
