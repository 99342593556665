// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.vandenbussche.models

import com.lightningkite.lightningdb.*
import com.lightningkite.UUID
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareWarehouseFields() {
    val props: Array<SerializableProperty<Warehouse, *>> = arrayOf(Warehouse__id, Warehouse_erpId, Warehouse_name, Warehouse_address, Warehouse_public, Warehouse_permittedAccounts)
    Warehouse.serializer().properties { props }
}
val <K> DataClassPath<K, Warehouse>._id: DataClassPath<K, UUID> get() = this[Warehouse__id]
val <K> DataClassPath<K, Warehouse>.erpId: DataClassPath<K, String?> get() = this[Warehouse_erpId]
val <K> DataClassPath<K, Warehouse>.name: DataClassPath<K, String> get() = this[Warehouse_name]
val <K> DataClassPath<K, Warehouse>.address: DataClassPath<K, Address> get() = this[Warehouse_address]
val <K> DataClassPath<K, Warehouse>.public: DataClassPath<K, Boolean> get() = this[Warehouse_public]
val <K> DataClassPath<K, Warehouse>.permittedAccounts: DataClassPath<K, Set<UUID>> get() = this[Warehouse_permittedAccounts]
inline val Warehouse.Companion.path: DataClassPath<Warehouse, Warehouse> get() = path<Warehouse>()


object Warehouse__id: SerializableProperty<Warehouse, UUID> {
    override val name: String by lazy { Warehouse.serializer().descriptor.getElementName(0) }
    override fun get(receiver: Warehouse): UUID = receiver._id
    override fun setCopy(receiver: Warehouse, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (Warehouse.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { Warehouse.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object Warehouse_erpId: SerializableProperty<Warehouse, String?> {
    override val name: String by lazy { Warehouse.serializer().descriptor.getElementName(1) }
    override fun get(receiver: Warehouse): String? = receiver.erpId
    override fun setCopy(receiver: Warehouse, value: String?) = receiver.copy(erpId = value)
    override val serializer: KSerializer<String?> by lazy { (Warehouse.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { Warehouse.serializer().descriptor.getElementAnnotations(1) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object Warehouse_name: SerializableProperty<Warehouse, String> {
    override val name: String by lazy { Warehouse.serializer().descriptor.getElementName(2) }
    override fun get(receiver: Warehouse): String = receiver.name
    override fun setCopy(receiver: Warehouse, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> by lazy { (Warehouse.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Warehouse.serializer().descriptor.getElementAnnotations(2) }
}
object Warehouse_address: SerializableProperty<Warehouse, Address> {
    override val name: String by lazy { Warehouse.serializer().descriptor.getElementName(3) }
    override fun get(receiver: Warehouse): Address = receiver.address
    override fun setCopy(receiver: Warehouse, value: Address) = receiver.copy(address = value)
    override val serializer: KSerializer<Address> by lazy { (Warehouse.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<Address> }
    override val annotations: List<Annotation> by lazy { Warehouse.serializer().descriptor.getElementAnnotations(3) }
}
object Warehouse_public: SerializableProperty<Warehouse, Boolean> {
    override val name: String by lazy { Warehouse.serializer().descriptor.getElementName(4) }
    override fun get(receiver: Warehouse): Boolean = receiver.public
    override fun setCopy(receiver: Warehouse, value: Boolean) = receiver.copy(public = value)
    override val serializer: KSerializer<Boolean> by lazy { (Warehouse.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { Warehouse.serializer().descriptor.getElementAnnotations(4) }
    override val default: Boolean = true
    override val defaultCode: String? = "true" 
}
object Warehouse_permittedAccounts: SerializableProperty<Warehouse, Set<UUID>> {
    override val name: String by lazy { Warehouse.serializer().descriptor.getElementName(5) }
    override fun get(receiver: Warehouse): Set<UUID> = receiver.permittedAccounts
    override fun setCopy(receiver: Warehouse, value: Set<UUID>) = receiver.copy(permittedAccounts = value)
    override val serializer: KSerializer<Set<UUID>> by lazy { (Warehouse.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<Set<UUID>> }
    override val annotations: List<Annotation> by lazy { Warehouse.serializer().descriptor.getElementAnnotations(5) }
    override val default: Set<UUID> = setOf()
    override val defaultCode: String? = "setOf()" 
}
