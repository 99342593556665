package com.vandenbussche.views.screens.cart

import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.InvalidSemantic
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.Writable
import com.lightningkite.kiteui.reactive.asDouble
import com.lightningkite.kiteui.reactive.bind
import com.lightningkite.kiteui.reactive.flatten
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.lens
import com.lightningkite.kiteui.reactive.lensByElementWithIdentity
import com.lightningkite.kiteui.reactive.modify
import com.lightningkite.kiteui.reactive.nullToBlank
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.reactive.waitForNotNull
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.bold
import com.lightningkite.kiteui.views.card
import com.lightningkite.kiteui.views.centered
import com.lightningkite.kiteui.views.compact
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.direct.button
import com.lightningkite.kiteui.views.direct.checkbox
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.kiteui.views.direct.confirmDanger
import com.lightningkite.kiteui.views.direct.h4
import com.lightningkite.kiteui.views.direct.h5
import com.lightningkite.kiteui.views.direct.h6
import com.lightningkite.kiteui.views.direct.icon
import com.lightningkite.kiteui.views.direct.numberInput
import com.lightningkite.kiteui.views.direct.onClick
import com.lightningkite.kiteui.views.direct.onlyWhen
import com.lightningkite.kiteui.views.direct.padded
import com.lightningkite.kiteui.views.direct.row
import com.lightningkite.kiteui.views.direct.rowCollapsingToColumn
import com.lightningkite.kiteui.views.direct.scrolls
import com.lightningkite.kiteui.views.direct.separator
import com.lightningkite.kiteui.views.direct.sizeConstraints
import com.lightningkite.kiteui.views.direct.stack
import com.lightningkite.kiteui.views.direct.subtext
import com.lightningkite.kiteui.views.direct.text
import com.lightningkite.kiteui.views.direct.textInput
import com.lightningkite.kiteui.views.dynamicTheme
import com.lightningkite.kiteui.views.expanding
import com.lightningkite.kiteui.views.fieldTheme
import com.lightningkite.kiteui.views.forEachUpdating
import com.lightningkite.kiteui.views.important
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.kiteui.views.warning
import com.lightningkite.lightningdb.*
import com.lightningkite.serialization.lensPath
import com.vandenbussche.models.*
import com.vandenbussche.models.Cart
import com.vandenbussche.models.PriceInCents
import com.vandenbussche.models.cents
import com.vandenbussche.models.keepAfterOrder
import com.vandenbussche.models.priceString
import com.vandenbussche.models.quantity
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.sdk.utils.creationError
import com.vandenbussche.sdk.utils.preferredWarehouse
import com.vandenbussche.theming.*
import com.vandenbussche.theming.cart
import com.vandenbussche.theming.checkboxTheme
import com.vandenbussche.validation.Validator
import com.vandenbussche.views.components.productCard
import com.vandenbussche.views.components.selectSourceWarehouse
import com.vandenbussche.views.emptyView
import com.vandenbussche.views.screens.common.ContentDeterminedByAccount
import com.vandenbussche.views.screens.common.HasNarrowContent
import com.vandenbussche.views.textFormatting.formatName
import kotlin.time.Duration.Companion.milliseconds

inline fun <T> Iterable<T>.sumPrice(transform: (T) -> PriceInCents): PriceInCents {
    var total = 0.cents
    for (item in this) {
        total += transform(item)
    }
    return total
}

@Routable("/Carts")
class CartScreen : ContentDeterminedByAccount, Validator() {
    private val selectedCart = Carts.Selected.debounceWrite(500.milliseconds)

    private val items: Writable<List<CartItem>> = selectedCart.lensPath { it.items }.lens(get = { it.toList() }, set = { it.toSet() })
    private val itemList = items.lensByElementWithIdentity { it.product }

    private val savedCarts = Carts.allCarts

    private val products = shared {
        val session = currentSession()
        val prods: List<UUID> = items().map { it.product }

        session.products.query(
            Query(
                condition { it._id inside prods },
                limit = 1000
            )
        )().associateBy { it._id }
    }

    val itemPrices = Property(emptyMap<UUID, PriceInCents?>())

    val totalPrice = shared {
        itemPrices().values.filterNotNull().sumPrice { it }
    }

    override val noAccountMessage = "You don't have an account, so you can't have any carts"

    override fun ViewWriter.hasAccount() {
        col {
            card - rowCollapsingToColumn(20.rem) {
                weight(4f) - field("Cart Name") {
                    textInput {
                        hint = "Cart Name"
                        ::enabled { currentSession().mayOrder }
                        content bind selectedCart.lensPath { it.name }.nullToBlank()
                    }
                }

                weight(3f) - selectSourceWarehouse()

                atBottomEnd - important - button {
                    centered - text("Saved Carts")

                    onClick {
                        dialogScreenNavigator.navigate(
                            object : Screen {
                                val filter = Property("")
                                override fun ViewWriter.render() {
                                    dismissBackground {
                                        spacing = 3.rem

                                        listContainerTheme - col {
                                            row {
                                                centered - h4("Saved Carts")
                                                expanding - fieldTheme - textInput {
                                                    content bind filter
                                                    hint = "Filter"
                                                    keyboardHints = KeyboardHints.title
                                                }
                                                button {
                                                    themeChoice += ThemeDerivation {
                                                        it.copy(
                                                            foreground = Color.interpolate(it.background.closestColor(), it.foreground.closestColor(), 0.5f)
                                                        ).withoutBack
                                                    }

                                                    0.5.rem?.let { spacing = it }
                                                    centered - icon(Icon.add, "Add new cart")
                                                    this.onClick {
                                                        val session = currentSession()
                                                        val new = session.carts.insert(
                                                            Cart(
                                                                account = session.accountId ?: return@onClick,
                                                                warehouse = preferredWarehouse()._id
                                                            )
                                                        )() ?: creationError<Cart>()
                                                        Carts.selectedID.value = new._id
                                                    }
                                                }
                                            }

                                            expanding - col {
                                                forEachUpdating(shared { savedCarts().filter { (it.name ?: "Unnamed").contains(filter(), true) } }) { cart ->
                                                    card - button {
                                                        col {
                                                            h6 { ::content { cart().name ?: "Unnamed" } }
                                                            row {
                                                                expanding - subtext {
                                                                    ::content {
                                                                        val count = cart().items.size
                                                                        if (count == 1) "1 item"
                                                                        else "$count items"
                                                                    }
                                                                }

                                                                subtext {
                                                                    val previousOrder = shared {
                                                                        cart().previousOrder?.let {
                                                                            currentSession().orders[it]()
                                                                        }
                                                                    }

                                                                    exists = false
                                                                    ::exists {
                                                                        val ord = previousOrder()
                                                                        (ord != null) and (ord?.items == cart().items)
                                                                    }

                                                                    ::content {
                                                                        "Previously ${previousOrder()?.total}"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        onClick {
                                                            Carts.selectedID.value = cart()._id
                                                            screenNavigator.dismiss()
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        )
                    }
                }
            }
            expanding - col {
                expanding - scrolls - col {
                    exists = false
                    ::exists { items().isNotEmpty() }

                    forEachUpdating(itemList) { item ->
                        val foundProduct = shared { products()[item()().product] }
                        val associatedProduct = foundProduct.waitForNotNull

                        val pricing = shared {
                           val price = currentSession().pricing.request(
                                PricingRequestInfo(
                                    account = selectedCart().account,
                                    items = setOf(CartItem(
                                        product = associatedProduct()._id,
                                        productErpId = associatedProduct().erpId,
                                        quantity = item()().quantity.toInt().takeIf { it > 0 } ?: 1
                                    )),
                                    warehouse = selectedCart().warehouse,
                                    shipTo = selectedCart().shipTo,
                                    shipASAP = selectedCart().shipASAP
                                ))().firstOrNull { it.product == associatedProduct() ._id }
                            itemPrices.value = itemPrices.value.plus(item.state.raw.value .product to price?.previousLineTotal)
                            price
                        }
                        this@col.card - this@col.rowCollapsingToColumn(HasNarrowContent.breakpoint) {
                            spacing = 0.px

                            expanding - productCard(associatedProduct, 5.rem)

                            padded - row {
                                rowCollapsingToColumn(30.rem) {
                                    centered - sizeConstraints(width = 5.rem) - fieldTheme - numberInput {
                                        hint = "Quantity"
                                        keyboardHints = KeyboardHints.integer
                                        ::enabled { currentSession().mayOrder }

                                        dynamicTheme {
                                            if (item()().quantity <= 0) InvalidSemantic
                                            else null
                                        }
                                        content bind item.flatten().lensPath { it.quantity }.lens<Int, Int?>(
                                            get = { it },
                                            set = { it ?: 0 }
                                        ).asDouble()
                                    }

                                    val inStock = shared {
                                        foundProduct()?._id?.let {
                                            Carts.selectedSourceWarehouse().inStock(it)()
                                        }
                                    }

                                    centered - onlyWhen { inStock()?.let { item()().quantity > it } == true } - warning - compact
                                    centered - subtext {
                                        align = Align.Center
                                        ::content { "Only ${inStock()} in stock" }
                                    }
                                }

                                    centered - text{
                                        ::exists { currentSession().self().showPricing }
                                        content = "X"
                                    }

                                    centered - sizeConstraints(width = 5.rem) - text {
                                        ::exists { currentSession().self().showPricing }
                                        ::content { pricing()?.previousPrice?.toString() ?: "-" } }

                                    expanding - centered - col {
                                        ::exists { currentSession().self().showPricing }
                                         separator() }

                                    centered - sizeConstraints(width = 5.rem) - bold - h6 {
                                        ::exists { currentSession().self().showPricing }
                                        ::content {
                                            pricing()?.previousLineTotal?.takeUnless { it == 0.cents }?.toString() ?: "-"
                                        }
                                    }


                                centered - important - button {
                                    spacing = 0.5.rem
                                    icon { source = Icon.delete }
                                    onClick {
                                        confirmDanger(
                                            title = "Remove Cart Item",
                                            body = "Are you sure you want to remove this item?",
                                            actionName = "I'm sure.",
                                            action = {
                                                items.modify { it.filter { it.product != item()().product } }
                                            }
                                        )
                                    }
                                }
                            }
                        }
                    }
                }

                expanding - emptyView {
                    ::exists { items().isEmpty() }
                    centered - row {
                        centered - icon(Icon.cart, "")
                        centered - h4("No items yet")
                    }
                }
            }
            card - col {
                rowCollapsingToColumn(23.rem) {
                    spacing = 0.5.rem
                    bold - h5 {
                        ::exists { currentSession().self().showPricing }
                        ::content {
                            "Total: ${totalPrice()}${if(totalPrice().isNullOrZero()) "" else "+ "}"

                        }
                    }
                    expanding - stack()
                    SubtextSemantic.onNext - compact - run {
                        this@rowCollapsingToColumn.row {
                            centered - checkboxTheme - checkbox {
                                checked bind selectedCart.lensPath { it.keepAfterOrder }
                                ::enabled { currentSession().mayOrder }
                            }
                            centered - text("Keep After Checkout")
                        }
                        Unit
                    }
                }

                row {
                    expanding - important - button {
                        ::enabled {
                            val read = items()
                            read.isNotEmpty() and read.all { it.quantity > 0 } && currentSession().mayOrder
                        }

                        centered - h4{
                            content ="Checkout"
                            wraps = false
                        }

                        onClick {
                            dialogScreenNavigator.navigate(checkout(selectedCart))
                        }
                    }
                    card - button {
                        centered - text {
                                ::content {
                                    "Save for${if(AppState.windowInfo().width <30.rem) "\n" else ""} Later" }
                                wraps = true
                        }

                        onClick {
                            if(selectedCart().name == null) {
                                alert("Cart Needs a Name", "Please set a name for the cart at the top of the page first.")
                                return@onClick
                            }
                            val session = currentSession()
                            val new = session.carts.insert(
                                Cart(
                                    account = session.accountId ?: return@onClick,
                                    warehouse = preferredWarehouse()._id
                                )
                            )() ?: creationError<Cart>()
                            Carts.selectedID.value = new._id
                        }
                    }

                    onlyWhen { items().anyUnderstocked() } - warning - button {
                        centered - icon(Icon.warning, "warning")

                        onClick {
                            dialogScreenNavigator.navigate(
                                object : Screen {
                                    override fun ViewWriter.render() {
                                        dismissBackground {
                                            spacing = 2.rem

                                            centered - warning - col {
                                                text("Warning")
                                                text {
                                                    ::content {
                                                        "One or more cart items have a higher quantity than what's in stock at ${
                                                            Carts.selectedSourceWarehouse().formatName()
                                                        } Warehouse. Ordering more than what's in stock can result in delays."
                                                    }
                                                }
                                                atEnd - important - button {
                                                    centered - text("Dismiss")

                                                    onClick { screenNavigator.dismiss() }
                                                }
                                            }
                                        }
                                    }
                                }
                            )
                        }
                    }

                    important - button {
                        centered - icon(Icon.deleteForever, "Delete Cart")
                        ::enabled { currentSession().mayOrder }
                        onClick {
                            confirmDanger(
                                title = "Delete Cart",
                                body = "Are you sure you want to delete this cart? This cannot be undone.",
                                actionName = "I'm sure.",
                                action = {
                                    Carts.deleteSelected()
                                }
                            )
                        }
                    }
                }
            }
        }
    }

}


