package com.vandenbussche.mappings

import com.lightningkite.kiteui.models.InvalidSemantic
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.dynamicTheme
import com.lightningkite.lightningdb.*
import com.lightningkite.serialization.*


fun <T> draftOf(getWritable: ReactiveContext.() -> Writable<T>): Draft<T> =
    Draft(shared(useLastWhileLoading = true, action = getWritable).flatten())


inline fun <reified O: Any, T> Draft<O?>.lens(getPath: (DataClassPath<O?, O>) -> DataClassPath<O?, T>): Writable<T> {
    val path = getPath(path<O?>().notNull)
    return (this as Writable<O?>).lens<O?, T>(
        get = {
            @Suppress("UNCHECKED_CAST")
            path.get(it) as T
        },
        modify = { o, t ->
            o?.let { path.set(it, t) }
        }
    )
}

fun <T, V> Readable<Writable<T>>.flatLens(
    get: (T) -> V,
    modify: (T, V) -> T
): Writable<V> =
    shared { get(this@flatLens()()) }.withWrite { value ->
        val write = this@flatLens.await()
        write set modify(write(), value)
    }

fun <T, V> Readable<Writable<T>>.flatLens(path: DataClassPath<T, V>): Writable<V> = flatLens(
    get = { path.get(it) as V },
    modify = { old, value -> path.set(old, value) }
)

inline fun <reified T, V> Readable<Writable<T>>.flatLens(getPath: (DataClassPath<T, T>) -> DataClassPath<T, V>): Writable<V> =
    flatLens(getPath(path()))

fun <O, T> Readable<O>.getWritable(writable: (O) -> Writable<T>): Writable<T> =
    shared { writable(this@getWritable())() }.withWrite { writable(this@getWritable.await()).set(it) }

fun <T> Writable<Set<T>>.asList(): Writable<List<T>> = lens(
    get = { it.toList() },
    set = { it.toSet() }
)

class UnWritable<T>(override val state: ReadableState<T> = ReadableState.notReady): Writable<T> {

    // Do nothing
    override fun addListener(listener: () -> Unit): () -> Unit = {}

    // Do nothing
    override suspend fun set(value: T) {}
}


fun RView.validate(condition: ReactiveContext.() -> Boolean) {
    dynamicTheme {
        if (condition()) null else InvalidSemantic
    }
}