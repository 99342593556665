package com.vandenbussche.sdk

import com.lightningkite.kiteui.*
import com.lightningkite.lightningdb.multiplexSocket
import com.lightningkite.lightningserver.StringArrayFormat
import com.lightningkite.now
import com.lightningkite.serialization.ClientModule
import kotlin.reflect.typeOf
import kotlinx.serialization.KSerializer
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.serializer
import kotlin.time.Duration.Companion.minutes

val json = Json {
    serializersModule = ClientModule
    ignoreUnknownKeys = true
}
val stringArrayFormat = StringArrayFormat(ClientModule)
inline fun <reified T> T.urlify(): String = stringArrayFormat.encodeToString(this)

suspend inline fun <reified T> RequestResponse.readJson() = json.decodeFromString<T>(text())
suspend fun RequestResponse.discard() = Unit
suspend inline fun <reified T> T.toJsonRequestBody() = json.encodeToString(this)

// 2 problems:
// Retry logic AND batch logic

//val recentUrlHits = ArrayList<Pair<Instant, String>>()

suspend inline fun <reified OUT> fetch(
    url: String,
    method: HttpMethod = HttpMethod.GET,
    noinline token: (suspend () ->String)? = null,
    masquerade: String? = null,
    headers: HttpHeaders = httpHeaders(),
    bodyJson: String?
): OUT {
//    if(url.startsWith("http://")) {
//        return connectivityFetch(
//            url = url,
//            method = method,
//            headers = {
//                headers.apply {
//                    token?.let { append("Authorization", "Bearer ${it()}") }
//                }
//            },
//            body = RequestBodyText(bodyJson ?: "{}", "application/json")
//        ).let { it: RequestResponse ->
//            if (it.ok && OUT::class == Unit::class) Unit as OUT
//            else if (it.ok) it.readJson()
//            else {
//                throw IllegalStateException(it.text())
//            }
//        }
//    } else {
//        recentUrlHits += now() to url
        val oneMinuteAgo = now() - 1.minutes
//        recentUrlHits.removeAll { it.first < oneMinuteAgo }
        return batchFetch(url, method, token, bodyJson)
//    }
}

suspend inline fun <reified IN, reified OUT> fetch(
    url: String,
    method: HttpMethod = HttpMethod.GET,
    noinline token: (suspend () ->String)? = null,
    masquerade: String? = null,
    headers: HttpHeaders = httpHeaders(),
    body: IN
): OUT = fetch(url, method, token, masquerade, headers, json.encodeToString(body))

suspend inline fun <reified OUT> fetch(
    url: String,
    method: HttpMethod = HttpMethod.GET,
    noinline token: (suspend () -> String)? = null,
    masquerade: String? = null,
    headers: HttpHeaders = httpHeaders()
): OUT = fetch(url, method, token, masquerade, headers, null)

inline fun <reified IN, reified OUT> multiplexedSocket(
    socketUrl: String,
    path: String,
    token: String?,
): TypedWebSocket<IN, OUT> = multiplexSocket(
    url = "$socketUrl/?path=multiplex${token?.let { "?jwt=${encodeURIComponent(it)}" } ?: ""}",
    path = path,
    params = emptyMap(),
    json = json,
    pingTime = 5_000,
).typed(json, json.serializersModule.serializer(typeOf<IN>()) as KSerializer<IN>, json.serializersModule.serializer(typeOf<OUT>()) as KSerializer<OUT>)

