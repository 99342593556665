// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.vandenbussche.models

import com.lightningkite.lightningdb.*
import com.lightningkite.UUID
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareAccountProductFields() {
    val props: Array<SerializableProperty<AccountProduct, *>> = arrayOf(AccountProduct_account, AccountProduct_product)
    AccountProduct.serializer().properties { props }
}
val <K> DataClassPath<K, AccountProduct>.account: DataClassPath<K, UUID> get() = this[AccountProduct_account]
val <K> DataClassPath<K, AccountProduct>.product: DataClassPath<K, UUID> get() = this[AccountProduct_product]
inline val AccountProduct.Companion.path: DataClassPath<AccountProduct, AccountProduct> get() = path<AccountProduct>()


object AccountProduct_account: SerializableProperty<AccountProduct, UUID> {
    override val name: String by lazy { AccountProduct.serializer().descriptor.getElementName(0) }
    override fun get(receiver: AccountProduct): UUID = receiver.account
    override fun setCopy(receiver: AccountProduct, value: UUID) = receiver.copy(account = value)
    override val serializer: KSerializer<UUID> by lazy { (AccountProduct.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { AccountProduct.serializer().descriptor.getElementAnnotations(0) }
}
object AccountProduct_product: SerializableProperty<AccountProduct, UUID> {
    override val name: String by lazy { AccountProduct.serializer().descriptor.getElementName(1) }
    override fun get(receiver: AccountProduct): UUID = receiver.product
    override fun setCopy(receiver: AccountProduct, value: UUID) = receiver.copy(product = value)
    override val serializer: KSerializer<UUID> by lazy { (AccountProduct.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { AccountProduct.serializer().descriptor.getElementAnnotations(1) }
}
