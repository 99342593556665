package com.vandenbussche.views.screens.cart

import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.ImportantSemantic
import com.lightningkite.kiteui.models.SelectedSemantic
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.LsErrorException
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.now
import com.lightningkite.serialization.*

import com.vandenbussche.mappings.fromData
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.sdk.flatten
import com.vandenbussche.sdk.utils.activeWarehouses
import com.vandenbussche.sdk.utils.creationError
import com.vandenbussche.sdk.utils.notFoundError
import com.vandenbussche.theming.LightOutline
import com.vandenbussche.theming.checkboxTheme
import com.vandenbussche.theming.lightOutline
import com.vandenbussche.validation.Validator
import com.vandenbussche.validation.interceptWrite
import com.vandenbussche.views.components.atStartOfDay
import com.vandenbussche.views.screens.account.orders.OrderScreen
import com.vandenbussche.views.screens.common.HasNarrowContent
import com.vandenbussche.views.textFormatting.formatName
import com.vandenbussche.views.textFormatting.randomID
import kotlinx.coroutines.delay
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.seconds

suspend fun checkout(cart: Writable<Cart>): CheckoutDialog {
    val read = cart()
    return CheckoutDialog(read.items, read.warehouse, cartId = read._id)
}

class CheckoutDialog(val items: Set<CartItem>, sourceWarehouse: UUID, val cartId: UUID? = null) : Screen,
    HasNarrowContent, Validator() {
    val account: Readable<CustomerAccount> = shared {
        val session = currentSession()
        session.customerAccounts[session.accountId!!]() ?: notFoundError<CustomerAccount>()
    }

    private val contacts = shared<List<VBErpContact>> {
        currentSession().contacts.request()()
    }

    val pricing = shared { currentSession().pricing.request(order())() }

    val order = Draft {
        val session = currentSession()
        val self = session.self()

        Order(
            account = account()._id,
            poNum = "",
            items = items.toSet(),
            listPriceTotal = (-1).dollars,
            total = (-1).dollars,
            warehouse = sourceWarehouse,
//            contactErpID = contacts.once().firstOrNull { it?.email == self.email }?.contactId,
//            contactEmail = contacts.once().firstOrNull { it?.email == self.email }?.email,
            orderedBy = currentSession().self()._id
        )
    }

    override fun ViewWriter.render() {
        dismissBackground {
            spacing = 2.rem
            ::spacing {
                if (narrow()) 2.rem
                else 7.rem
            }

            reactiveScope {
                clearChildren()
                if (!narrow()) sizeConstraints(width = 50.rem)
            }
            centered - card - scrolls - col {
                spacing = 1.5.rem

                row {
                    expanding - h2("Checkout")

                    atTopEnd - button {
                        spacing = 0.rem
                        icon { source = Icon.close }
                        onClick { dialogScreenNavigator.dismiss() }
                    }
                }

                separator()

                val warehouses = shared {
                    val locations = activeWarehouses()()

                    val preferred = account().preferredPickupLocation ?: return@shared locations
                    locations.sortedByDescending {
                        it._id == preferred
                    }
                }

                val sourceWarehouse = order.lensPath { it.warehouse }.fromData(warehouses)

                val shippingLocations = shared {
                    val session = currentSession()
                    val a = account()
                    val locations = session.shippingAddresses.query(
                        Query(
                            condition { it.account eq a._id }
                        ))() + null

                    val preferred = account().preferredShippingAddress ?: return@shared locations
                    locations.sortedByDescending {
                        it?._id == preferred
                    }
                }

                val shippingLocation = order.lensPath { it.shipTo }.fromData(shippingLocations)

                label {
                    content = "Source Warehouse"

                    fieldTheme - select {
                        bind(
                            edits = sourceWarehouse,
                            data = warehouses,
                            render = { it.formatName() }
                        )
                    }
                }

                separator()

                col {
                    val ship = LazyProperty { shippingLocation() != null }.interceptWrite {
                        if (!it) shippingLocation set null
                        value = it
                    }

                    row {
                        expanding - lightOutline - radioToggleButton {
                            centered - text("Pickup")
                            checked bind ship.equalTo(false)
                        }

                        centered - text("or")

                        expanding - lightOutline - radioToggleButton {
                            centered - text("Ship")
                            checked bind ship
                        }
                    }

                    onlyWhen { ship() } - label {
                        content = "Shipping Location"

                        fieldTheme - validate {
                            if (ship()) (shippingLocation() != null) else true
                        } - select {
                            bind(
                                edits = shippingLocation,
                                data = shippingLocations,
                                render = {
                                    if (it == null) "None"
                                    else {
                                        it.name ?: it.address.businessName
                                    }
                                }
                            )
                        }
                    }
                }

                separator()

                label {
                    content = "Custom Purchase Order Number (Optional)"
                    fieldTheme - textInput {
                        hint = "PO Number"

                        content bind order.lensPath { it.poNum }
                    }
                }

                label {
                    content = "Order Comment (Optional)"

                    fieldTheme - textArea {
                        content bind order.lensPath { it.comment }
                    }
                }

                // Change order - remove contacts. Keeping commented in case it is needed again.
//                stack {
//                    label {
//                        content = "Contact"
//
////                                ::exists { contacts().size > 1 }
//
//                        val contact = order.lensPath { it.contactErpID }.dynamicLens(
//                            get = { id -> contacts().find { it.contactId == id } },
//                            set = { it?.contactId },
//                        )
//                        launch {
//                            contacts().firstOrNull()?.let {
//                                if (contact() == null) contact set it
//                            }
//                        }
//
//                        fieldTheme - select {
//                            bind(contact, contacts) { it?.fullName ?: "None" }
//                        }
//                    }
//                }

                fun getFulfillmentOptionSelected(subtractDays:Duration, addDays: Duration, order: Order): Boolean {
                    val fullmentDateLocalDate =
                        order.fulfillmentExpectation?.toLocalDateTime(TimeZone.currentSystemDefault())?.date
                            ?.let { "${it.monthNumber}/${it.dayOfMonth}/${it.year}" }
                    val nowLocalDate =
                        now().minus(subtractDays).plus(addDays).toLocalDateTime(TimeZone.currentSystemDefault()).date
                            .let { "${it.monthNumber}/${it.dayOfMonth}/${it.year}" }
                    return fullmentDateLocalDate == nowLocalDate
                }

                lightOutline - col {
                    h3("Fulfillment Expectation")
                    rowCollapsingToColumn(60.rem) {
                        lightOutline - toggleButton {
                            text("Yesterday")
                            checked bind order.lens(
                                get = {
                                    getFulfillmentOptionSelected(1.days,0.days,it)
                                },
                                set = {
                                    order.state.raw.copy(fulfillmentExpectation = now().minus(1.days))
                                }
                            )
                        }
                        lightOutline - toggleButton {
                            text("Today")
                            checked bind order.lens(
                                get = {
                                    getFulfillmentOptionSelected(0.days,0.days,it)
                                },
                                set = {
                                    order.state.raw.copy(fulfillmentExpectation = now().minus(0.days))
                                }
                            )
                        }
                        lightOutline - toggleButton {
                            text("Tomorrow")
                            checked bind order.lens(
                                get = {
                                    getFulfillmentOptionSelected(0.days,1.days,it)
                                },
                                set = {
                                    order.state.raw.copy(fulfillmentExpectation = now().plus(1.days))
                                }
                            )
                        }
                        lightOutline - localDateField {
                            dynamicTheme {
                               if(order().fulfillmentExpectation?.let { it> now().plus(1.days) == true} == true) SelectedSemantic else null
                            }
                            content bind order.lens(get = {it.fulfillmentExpectation?.toLocalDateTime(TimeZone.currentSystemDefault())?.date},
                                set = {
                                    order.state.raw.copy(fulfillmentExpectation = it?.atStartOfDay())
                                })
                        }
                    }
                    onlyWhen { items.anyUnderstocked(order().warehouse)() } -  subtext("We do not currently have all the products available at this warehouse to fulfill your order.  Please rest assured that we will fulfill your order as soon as possible, we likely have these products available at another location.  Someone from our team will reach out to you shortly with our plan to fulfill your order in full.")
                }

                cartId?.let {
                    val cart = shared { currentSession().carts[it] }.flatten().waitForNotNull
                    reactive {
                        println("Cart keep = ${cart().keepAfterOrder}")
                    }
                    reactive {
                        println("Cart keep 2 = ${cart.lensPath { it.keepAfterOrder }()}")
                    }
                    lightOutline - row {
                        checkboxTheme - checkbox {
                            checked bind cart.lensPath { it.keepAfterOrder }
                        }
                        centered - text {
                            ::content {
                                val name = cart().name?.let { "'$it'" } ?: "Cart"
                                "Keep $name After Order"
                            }
                        }
                    }
                }
                separator()

                rowCollapsingToColumn {
                    fun total(setup: RView.() -> Unit) {
                        h6 {
                            setup()
                            ::content { "Order Total: ${pricing().priceString()}" }
                        }
                    }

                    centered - bold - expanding - total { existsWhenWide() }
                    bold - total { existsWhenNarrow() }

                    card - button {
                        h6("Cancel")
                        onClick { dialogScreenNavigator.dismiss() }
                    }

                    important - button {
                        ::enabled { allValid() }

                        h6("Place Order")

                        onClick {
                            val itemsWithPreviousPrices = pricing()

                            order.modify { ord ->
                                ord.copy(
                                    poNum = ord.poNum.takeUnless { it.isBlank() } ?: randomID(),
                                    items = itemsWithPreviousPrices,
                                    total = itemsWithPreviousPrices.mapNotNull { if(it.previousLineTotal==null) null else it }.sumPrice { it.previousLineTotal!! },
                                    listPriceTotal = itemsWithPreviousPrices.mapNotNull { if(it.previousLineTotal==null) null else it }.sumPrice { it.previousListPrice!! * it.quantity },
                                    orderedAt = now()
                                )
                            }

                            val order = order.publish()

                            currentSession().orders.insert(order)() ?: creationError<Order>()

                            cartId?.let {
                                val cartProp = currentSession().carts[it]
                                val cart = cartProp()
                                if (cart?.keepAfterOrder == true) {
                                    cartProp.modify(modification {
                                        it.items assign itemsWithPreviousPrices
                                        it.previousOrder assign order._id
                                    })
                                } else cartProp.delete()
                            }

                            mainScreenNavigator.navigate(OrderScreen(order._id).also {
                                it.isConfirmation = true
                            })
                            dialogScreenNavigator.dismiss()
                        }
                    }
                    space { existsWhenNarrow() }
                }
                onlyWhen { order().items.size > 50 } - warning  - row {
                    centered - icon(Icon.warning, "warning")
                    text("Large orders may take a few minutes to process. Please do not leave this page or close dialog until it is done processing")
                }
            }

        }
    }
}
