package com.vandenbussche.admin

import com.ilussobsa.views.multiselect
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.now
import com.lightningkite.serialization.*
import com.lightningkite.uuid
import com.vandenbussche.csv.CsvFormat
import com.vandenbussche.csv.StringDeferringConfig
import com.vandenbussche.defaultImage
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.theming.chevronUp
import kotlinx.coroutines.*
import kotlinx.serialization.encodeToString




@Routable("/products")
class AdminProductsScreen() : Screen {
    override val title: Readable<String> = Constant<String>("Products")
    @QueryParameter("searchProducts")
    val searchProducts = Property("")
    @QueryParameter("showRemoved")
    val showRemoved = Property<Boolean>(false)

    @QueryParameter("limitToNoImageProducts")
    val limitToNoImageProducts = Property<Boolean>(false)

    val query = shared {
        Query(
            condition<Product> {
                Condition.And(
                    listOfNotNull(
                        searchProducts.debounce(500)().let { s ->
                            if (s.isBlank()) condition(true)
                            else Condition.And(s.split(' ').map { part ->
                                Condition.Or(
                                    listOfNotNull(
                                        it.title.contains(part, true),
                                        it.erpId.contains(part, true),
                                        it.manufacturer.contains(part, true),
                                        it.sku.contains(part, true),
                                    )
                                )
                            })
                        },
                        if (!showRemoved.await()) it.active neq false else null,
                        if (limitToNoImageProducts.await()) it.image eq null else null
                    )
                )
            },
            orderBy = sort { it.order.ascending() }
        )
    }
    private val products = shared { currentSession.awaitNotNull().products.query(query.await()) }
    override fun ViewWriter.render() = col {
        row {
            compact - button {
                row {
                    icon(Icon.download, "Download a spreadsheet of products")
                    centered - text("Export")

                }
                onClick {
                    val allProducts = currentSession().products.query(Query(condition { condition(true) },
                        limit = 10_000))()
                    val format = CsvFormat(StringDeferringConfig(ClientModule))
                    format.encodeToString(allProducts).toBlob().let { ExternalServices.download("products.csv", it) }
                }
            }

            compact - button {
                row {
                    icon(Icon.chevronUp, "Import a spreadsheet of products")
                    centered - text("Import")
                }
                onClick {
                    val file = ExternalServices.requestFile(listOf("*/*")) ?: return@onClick
                    val req = currentSession().nonCached.api.uploadFileForRequest()
                    fetch(req.uploadUrl, HttpMethod.PUT, body = file)
                    currentSession().nonCached.product.uploadProductSpreadsheet(ServerFile(req.futureCallToken))
                    currentSession().products.totallyInvalidate()
                    currentSession().productCategories.totallyInvalidate()
                }
            }

            expanding - fieldTheme - row {
                icon(Icon.search, "Search")
                expanding - textInput {
                    content bind searchProducts
                    hint = "Search by name, number, or SKU"
                }
            }

            row {
                centered - checkbox {
                    checked bind showRemoved
                }
                centered - text("Show removed products")
            }
            row {
                centered - checkbox {
                    checked bind limitToNoImageProducts
                }
                centered - text("Limit to products without image")
            }
        }
        space()
        padded - row {
            weight(0.5f) - text("Image")
            weight(1f) - text("Product Number")
            weight(2f) - text("Name")
            weight(1f) - text("Manufacturer")
            weight(0.5f) - text("Qty Type")
            weight(1f) - text("SKU")
            weight(0.5f) - text("Status")
            weight(1f) - compact - button {
                row {
                    centered - icon(Icon.add, "Add a new product")
                    centered - text("Add Product")
                }
                onClick {
                    dialogScreenNavigator.navigate(ProductModal(null))
                }
            }

        }
        expanding - col {
            expanding - recyclerView {
                reactiveScope {
                    if (lastVisibleIndex() > products().limit - 20)
                        products().limit = lastVisibleIndex() + 100
                }
                val items = shared { products()() }
                children(items) { product ->
                    card - sizeConstraints(maxHeight = 10.rem) - row {
                        weight(0.5f) - stack {
                            sizeConstraints(height = 5.rem, width = 5.rem) - image {
//                                ::exists{ product().image?.location !== null }
                                ::source { product().image?.location?.let(::ImageRemote)?: defaultImage() }
                            }
                        }
                        weight(1f) - gravity(Align.Start, Align.Center) - text { ::content{ product().erpId ?: "" } }
                        weight(2f) - gravity(Align.Start, Align.Center) - text {
                            ellipsis = true
                            wraps = false
                            ::content{ product().title.replace("\n", "") }
                        }
                        weight(1f) - gravity(Align.Start, Align.Center) - text {
                            ellipsis = true
                            wraps = false
                            ::content{ product().manufacturer }
                        }
                        weight(0.5f) - gravity(Align.Start, Align.Center) - text { ::content{ product().quantityType.toString() } }
                        weight(1f) - gravity(Align.Start, Align.Center) - text { ::content{ product().sku } }
                        weight(0.5f) - gravity(Align.Start, Align.Center) - text { ::content{ if (product().active) "Active" else "Removed" } }
                        weight(1f) - row {
                            compact - button {
                                row {
                                    centered - text("Edit")
                                }
                                onClick {
                                    dialogScreenNavigator.navigate(ProductModal(product?.invoke()))
                                }
                            }
                            compact - button {
                                dynamicTheme {
                                    if(product().active) DangerSemantic else null
                                }
                                centered - text {
                                    ::content { if(product().active) "Remove" else "Restore" }
                                }
                                onClick {
                                    if(product().active) {
                                        confirmDanger("Remove Product", "This action will remove ${product().title}") {
                                            currentSession().products[product()._id].modify(
                                                modification<Product> {
                                                    it.active assign false
                                                })
                                        }
                                    } else {
                                        confirmDanger("Restore Product", "This action will restore ${product().title} to public visibility") {
                                            currentSession().products[product()._id].modify(
                                                modification<Product> {
                                                    it.active assign true
                                                })
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

class ProductModal(
    val product: Product? = null,
    val category: ProductCategory? = null
) : Screen {
    val productNum = Property(product?.erpId ?: "")
    val name = Property(product?.name ?: "")
    val description = Property(product?.manufacturerDescription ?: "")
    val manufacturer = Property(product?.manufacturer ?: "")
    val qtyType = Property(product?.quantityType ?: QuantityType.Each)
    val categories = if (category != null) Property(setOf(category._id)) else Property(product?.categories ?: setOf())
    val order = Property(product?.order)
    val sku = Property(product?.sku ?: "")
    val prodImage = Property<Pair<ServerFile, ImageSource>?>(product?.image?.let { it to ImageRemote(it.location) })

    val allCategories =
        shared { currentSession().productCategories.query(Query(condition { condition(true) }, limit = 10000))() }

    suspend fun clear() {
        productNum.set("")
        name.set("")
        description.set("")
        manufacturer.set("")
        qtyType.set(QuantityType.Each)
        categories.set(emptySet())
        sku.set("")
        prodImage.set(null)
        order.set(null)
    }

    override fun ViewWriter.render() {
        dismissBackground {
            centered - card - scrolls - col {
                h2(if (product == null) "Add New Product" else "Edit Product")
                launch {
                    val count = currentSession().nonCached.product.count(Condition.Always)
                    if(product == null) order.set((count +1).toDouble())
                }
                separator()
                label {
                    content = "Product Number *"
                    fieldTheme - compact - textInput {
                        content bind productNum
                    }
                }
                label {
                    content = "Name *"
                    fieldTheme - compact - textInput {
                        content bind name
                    }
                }

                sizeConstraints(maxWidth = 30.rem) - scrolls - col {
                    label {
                        content = "Categories"
                        multiselect<ProductCategory, UUID>(
                            query = { input ->
                                input.takeUnless { it.isBlank() }?.let { s ->
                                    allCategories().filter { it.path.contains(s, true) }
                                } ?: allCategories()
                            },
                            pull = {
                                currentSession().productCategories[it]() ?: ProductCategory.EMPTY
                            },
                            getId = { it._id },
                            toString = { it.path },
                            items = categories,
                        )
                    }
                }
                label {
                    content = "order In List"
                    fieldTheme - compact - numberInput {
                        content bind order
                    }
                }
                label {
                    content = "Description"
                    fieldTheme - textArea {
                        content bind description
                    }
                }
                label {
                    content = "Manufacturer"
                    fieldTheme - compact - textInput {
                        content bind manufacturer
                    }
                }
                row {
                    label {
                        content = "Qty Type *"
                        fieldTheme - compact - select {
                            bind<QuantityType>(
                                edits = shared { qtyType.value }.withWrite { value -> qtyType.set(value) },
                                data = Constant(QuantityType.entries),
                                render = { qType ->
                                    (qType.name)
                                }
                            )
                        }
                    }
                    label {
                        content = "SKU *"
                        fieldTheme - compact - textInput {
                            content bind sku
                        }
                    }
                }
                centered - col {
                    row {
                        sizeConstraints(maxHeight = 5.rem, maxWidth = 5.rem) - image {
                            ::source { prodImage()?.second }
                        }
                        compact - button {
                            row {
                                centered - icon(Icon.chevronUp, "Upload an image")
                                centered - text("Upload Image")
                            }
                            onClick {
                                val file = ExternalServices.requestFile(listOf("image/*")) ?: return@onClick
                                val req = currentSession().nonCached.api.uploadFileForRequest()
                                fetch(req.uploadUrl, HttpMethod.PUT, body = file)
                                prodImage.set(ServerFile(req.futureCallToken) to ImageLocal(file))
                            }
                        }
                    }
                }
                subtext("*Required")
                separator()
                row {
                    button {
                        text("Cancel")
                        onClick {
                            clear()
                            dialogScreenNavigator.dismiss()
                        }
                    }
                    expanding - space()
                    important - button {
                        ::enabled{
                            productNum().isNotBlank() && name().isNotBlank() && sku().isNotBlank()
                        }
                        text(if (product == null) "Save" else "Save Changes")

                        onClick {
                            if (product !== null) {
                                currentSession().products[product._id].modify(
                                    modification {
                                        it.erpId assign productNum()
                                        it.categories assign categories()
                                        it.title assign name()
                                        it.manufacturer assign manufacturer()
                                        it.manufacturerDescription assign description()
                                        it.sku assign sku()
                                        it.updatedAt assign now()
                                        it.image assign prodImage()?.first
                                        it.quantityType assign qtyType()
                                        it.order assign (order()?:0.0)
                                    }
                                )
                            } else {
                                val count = currentSession().nonCached.product.count(Condition.Always)
                                currentSession().products.insert(
                                    Product(
                                        _id = uuid(),
                                        erpId = productNum(), //required
                                        categories = categories(),
                                        title = name(), //required
                                        manufacturerDescription = description(),
                                        manufacturer = manufacturer(),
                                        sku = sku(), //required
                                        updatedAt = now(),
                                        image = prodImage()?.first,
                                        imageLarge = null,
                                        quantityType = qtyType(),
                                        outOfDate = false,
                                        active = true,
                                        order = (count+1).toDouble()
                                    )
                                )
                            }
                            clear()
                            dialogScreenNavigator.dismiss()
                        }
                    }
                }
            }
        }
    }
}