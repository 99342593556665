package com.vandenbussche.views.screens.products

import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.px
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.card
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.db.LimitReadable
import com.vandenbussche.admin.*
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.sdk.utils.modify
import com.vandenbussche.theming.chevronDown
import com.vandenbussche.theming.chevronUp
import com.vandenbussche.views.screens.common.HasNarrowContent
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.joinAll
import kotlinx.coroutines.launch

@Routable("admin/category/{categoryID}")
class AdminCategoryScreen(val categoryID: UUID) : Screen, HasNarrowContent {

    val category = shared {
        currentSession().productCategories[categoryID]()!!
    }
    val query = shared {
        Query(
            condition<ProductCategory> {
                Condition.And(
                    listOfNotNull(
                        it.parent eq categoryID
//                        search.debounce(500)().let { s ->
//                            if (s.isBlank()) condition(true)
//                            else Condition.And(s.split(' ').map { part ->
//                                it.path.contains(part, true)
//                            })
//                        },
//                        if (limitToNoImage.await()) it.image eq null else null
                    )
                )
            },
            orderBy = sort {
                it.order.ascending()
            }
        )
    }

    val childCategories = shared(useLastWhileLoading = true)  { currentSession.awaitNotNull().productCategories.watch(query.await()) }
    val products = shared(useLastWhileLoading = true) {

        currentSession().products.query(Query(
            condition { prod -> prod.categories.any { it eq categoryID } },
            orderBy = sort { it.order.ascending() }
        ))
    }

    override val title: Readable<String> = shared {
        return@shared category().path
    }
    override fun ViewWriter.render() {

        col {
            row {
                col {
                    ::exists{ childCategories()().isEmpty() && products()().isEmpty() }
                    centered - h2("There are no subcategories or products")
                }
                expanding - space()
                button {
                    centered - row {
                        centered - icon(Icon.add, "")
                        centered - text("Add Category")
                    }
                    onClick {
                        dialogScreenNavigator.navigate(AdminCategoryScreenDialog(null, category()._id))
                    }
                }
                button {
                    ::exists{ products()().isNotEmpty() || (childCategories()().isEmpty() && products()().isEmpty()) }

                    row {
                        centered - icon(Icon.add, "Add a new product")
                        centered - text("Add Product")
                    }
                    onClick {
                        dialogScreenNavigator.navigate(ProductModal(null, category()))
                    }
                }
            }
            expanding -  swapView {
                swapping(
                    current = {
                        childCategories()().isNotEmpty()
                    },
                    views = { hasChildCategories ->
                        if (hasChildCategories) {

                            recyclerView {
                                reactiveScope {
                                    if (lastVisibleIndex() > childCategories().limit - 20)
                                        childCategories().limit = lastVisibleIndex() + 100
                                }
                                val items = shared { childCategories()() }

                                children(items) {
                                    card - row {
                                        centered - orderChange(it, childCategories)
                                        expanding -  adminProductCategoryCard(it)
                                    }
                                }
                            }
                        } else {
                            recyclerView {
                                reactiveScope {
                                    if (lastVisibleIndex() > products().limit - 20)
                                        products().limit = lastVisibleIndex() + 100
                                }
                                val items = shared { products()() }
                                children(items) {
                                    card - row {
                                        centered - orderChange(it, products)
                                        expanding - adminProductCardNoLink(it, imageHeight = 5.rem)
                                    }
                                }
                            }
                        }
                    }
                )
            }
        }
    }
}


fun ViewWriter.orderChange(product: Readable<Product>, products: Readable<LimitReadable<Product>>) {
    col {
        val controlsSpacing = 0.3.rem
        spacing = 0.px

        button {
            spacing = controlsSpacing
            centered - icon(Icon.chevronUp, "Move up")
            onClick {
                product().increment(products()(), product())
            }
        }

        button {
            spacing = controlsSpacing
            centered - icon(Icon.chevronDown, "Move down")
            onClick {
                product().decrement( products()(), product())
            }
        }
    }
}
suspend fun Product.increment(products: List<Product>, product: Product) {
    val p = currentSession().products
    val swapIndexIncrement = products.indexOf(product) - 1
    if(swapIndexIncrement >= products.size || swapIndexIncrement <0) return
    val nextHighestProduct = products.elementAt(swapIndexIncrement)

    coroutineScope {
        listOf(
            launch { p.get(_id).modify { it.order assign nextHighestProduct.order } },
            launch { p.get(nextHighestProduct._id).modify { it.order assign product.order } },
        ).joinAll()
    }
}


suspend fun Product.decrement(products: List<Product>, product: Product) {
    val p = currentSession().products
    val swapIndexDecrement = products.indexOf(product) + 1
    if(swapIndexDecrement >= products.size || swapIndexDecrement <0) return
    val nextLowestProduct = products.elementAt(swapIndexDecrement)

    coroutineScope {
        listOf(
            launch { p.get(_id).modify { it.order assign nextLowestProduct.order } },
            launch { p.get(nextLowestProduct._id).modify { it.order assign product.order } },
        ).joinAll()
    }
}