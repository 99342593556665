// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.vandenbussche.models

import com.lightningkite.lightningdb.*
import com.lightningkite.UUID
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareProductFields() {
    val props: Array<SerializableProperty<Product, *>> = arrayOf(Product__id, Product_erpId, Product_categories, Product_order, Product_title, Product_manufacturerDescription, Product_manufacturer, Product_sku, Product_createdAt, Product_updatedAt, Product_imageUrlToMigrate, Product_image, Product_imageLarge, Product_quantityType, Product_outOfDate, Product_active)
    Product.serializer().properties { props }
}
val <K> DataClassPath<K, Product>._id: DataClassPath<K, UUID> get() = this[Product__id]
val <K> DataClassPath<K, Product>.erpId: DataClassPath<K, String> get() = this[Product_erpId]
val <K> DataClassPath<K, Product>.categories: DataClassPath<K, Set<UUID>> get() = this[Product_categories]
val <K> DataClassPath<K, Product>.order: DataClassPath<K, Double> get() = this[Product_order]
val <K> DataClassPath<K, Product>.title: DataClassPath<K, String> get() = this[Product_title]
val <K> DataClassPath<K, Product>.manufacturerDescription: DataClassPath<K, String> get() = this[Product_manufacturerDescription]
val <K> DataClassPath<K, Product>.manufacturer: DataClassPath<K, String> get() = this[Product_manufacturer]
val <K> DataClassPath<K, Product>.sku: DataClassPath<K, String> get() = this[Product_sku]
val <K> DataClassPath<K, Product>.createdAt: DataClassPath<K, Instant> get() = this[Product_createdAt]
val <K> DataClassPath<K, Product>.updatedAt: DataClassPath<K, Instant> get() = this[Product_updatedAt]
val <K> DataClassPath<K, Product>.imageUrlToMigrate: DataClassPath<K, String?> get() = this[Product_imageUrlToMigrate]
val <K> DataClassPath<K, Product>.image: DataClassPath<K, ServerFile?> get() = this[Product_image]
val <K> DataClassPath<K, Product>.imageLarge: DataClassPath<K, ServerFile?> get() = this[Product_imageLarge]
val <K> DataClassPath<K, Product>.quantityType: DataClassPath<K, QuantityType> get() = this[Product_quantityType]
val <K> DataClassPath<K, Product>.outOfDate: DataClassPath<K, Boolean> get() = this[Product_outOfDate]
val <K> DataClassPath<K, Product>.active: DataClassPath<K, Boolean> get() = this[Product_active]
inline val Product.Companion.path: DataClassPath<Product, Product> get() = path<Product>()


object Product__id: SerializableProperty<Product, UUID> {
    override val name: String by lazy { Product.serializer().descriptor.getElementName(0) }
    override fun get(receiver: Product): UUID = receiver._id
    override fun setCopy(receiver: Product, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (Product.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { Product.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object Product_erpId: SerializableProperty<Product, String> {
    override val name: String by lazy { Product.serializer().descriptor.getElementName(1) }
    override fun get(receiver: Product): String = receiver.erpId
    override fun setCopy(receiver: Product, value: String) = receiver.copy(erpId = value)
    override val serializer: KSerializer<String> by lazy { (Product.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Product.serializer().descriptor.getElementAnnotations(1) }
}
object Product_categories: SerializableProperty<Product, Set<UUID>> {
    override val name: String by lazy { Product.serializer().descriptor.getElementName(2) }
    override fun get(receiver: Product): Set<UUID> = receiver.categories
    override fun setCopy(receiver: Product, value: Set<UUID>) = receiver.copy(categories = value)
    override val serializer: KSerializer<Set<UUID>> by lazy { (Product.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<Set<UUID>> }
    override val annotations: List<Annotation> by lazy { Product.serializer().descriptor.getElementAnnotations(2) }
}
object Product_order: SerializableProperty<Product, Double> {
    override val name: String by lazy { Product.serializer().descriptor.getElementName(3) }
    override fun get(receiver: Product): Double = receiver.order
    override fun setCopy(receiver: Product, value: Double) = receiver.copy(order = value)
    override val serializer: KSerializer<Double> by lazy { (Product.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<Double> }
    override val annotations: List<Annotation> by lazy { Product.serializer().descriptor.getElementAnnotations(3) }
    override val default: Double = 0.0
    override val defaultCode: String? = "0.0" 
}
object Product_title: SerializableProperty<Product, String> {
    override val name: String by lazy { Product.serializer().descriptor.getElementName(4) }
    override fun get(receiver: Product): String = receiver.title
    override fun setCopy(receiver: Product, value: String) = receiver.copy(title = value)
    override val serializer: KSerializer<String> by lazy { (Product.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Product.serializer().descriptor.getElementAnnotations(4) }
}
object Product_manufacturerDescription: SerializableProperty<Product, String> {
    override val name: String by lazy { Product.serializer().descriptor.getElementName(5) }
    override fun get(receiver: Product): String = receiver.manufacturerDescription
    override fun setCopy(receiver: Product, value: String) = receiver.copy(manufacturerDescription = value)
    override val serializer: KSerializer<String> by lazy { (Product.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Product.serializer().descriptor.getElementAnnotations(5) }
}
object Product_manufacturer: SerializableProperty<Product, String> {
    override val name: String by lazy { Product.serializer().descriptor.getElementName(6) }
    override fun get(receiver: Product): String = receiver.manufacturer
    override fun setCopy(receiver: Product, value: String) = receiver.copy(manufacturer = value)
    override val serializer: KSerializer<String> by lazy { (Product.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Product.serializer().descriptor.getElementAnnotations(6) }
}
object Product_sku: SerializableProperty<Product, String> {
    override val name: String by lazy { Product.serializer().descriptor.getElementName(7) }
    override fun get(receiver: Product): String = receiver.sku
    override fun setCopy(receiver: Product, value: String) = receiver.copy(sku = value)
    override val serializer: KSerializer<String> by lazy { (Product.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Product.serializer().descriptor.getElementAnnotations(7) }
}
object Product_createdAt: SerializableProperty<Product, Instant> {
    override val name: String by lazy { Product.serializer().descriptor.getElementName(8) }
    override fun get(receiver: Product): Instant = receiver.createdAt
    override fun setCopy(receiver: Product, value: Instant) = receiver.copy(createdAt = value)
    override val serializer: KSerializer<Instant> by lazy { (Product.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { Product.serializer().descriptor.getElementAnnotations(8) }
    override val default: Instant = Clock.System.now()
    override val defaultCode: String? = "Clock.System.now()" 
}
object Product_updatedAt: SerializableProperty<Product, Instant> {
    override val name: String by lazy { Product.serializer().descriptor.getElementName(9) }
    override fun get(receiver: Product): Instant = receiver.updatedAt
    override fun setCopy(receiver: Product, value: Instant) = receiver.copy(updatedAt = value)
    override val serializer: KSerializer<Instant> by lazy { (Product.serializer() as GeneratedSerializer<*>).childSerializers()[9] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { Product.serializer().descriptor.getElementAnnotations(9) }
    override val default: Instant = Clock.System.now()
    override val defaultCode: String? = "Clock.System.now()" 
}
object Product_imageUrlToMigrate: SerializableProperty<Product, String?> {
    override val name: String by lazy { Product.serializer().descriptor.getElementName(10) }
    override fun get(receiver: Product): String? = receiver.imageUrlToMigrate
    override fun setCopy(receiver: Product, value: String?) = receiver.copy(imageUrlToMigrate = value)
    override val serializer: KSerializer<String?> by lazy { (Product.serializer() as GeneratedSerializer<*>).childSerializers()[10] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { Product.serializer().descriptor.getElementAnnotations(10) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object Product_image: SerializableProperty<Product, ServerFile?> {
    override val name: String by lazy { Product.serializer().descriptor.getElementName(11) }
    override fun get(receiver: Product): ServerFile? = receiver.image
    override fun setCopy(receiver: Product, value: ServerFile?) = receiver.copy(image = value)
    override val serializer: KSerializer<ServerFile?> by lazy { (Product.serializer() as GeneratedSerializer<*>).childSerializers()[11] as KSerializer<ServerFile?> }
    override val annotations: List<Annotation> by lazy { Product.serializer().descriptor.getElementAnnotations(11) }
    override val default: ServerFile? = null
    override val defaultCode: String? = "null" 
}
object Product_imageLarge: SerializableProperty<Product, ServerFile?> {
    override val name: String by lazy { Product.serializer().descriptor.getElementName(12) }
    override fun get(receiver: Product): ServerFile? = receiver.imageLarge
    override fun setCopy(receiver: Product, value: ServerFile?) = receiver.copy(imageLarge = value)
    override val serializer: KSerializer<ServerFile?> by lazy { (Product.serializer() as GeneratedSerializer<*>).childSerializers()[12] as KSerializer<ServerFile?> }
    override val annotations: List<Annotation> by lazy { Product.serializer().descriptor.getElementAnnotations(12) }
    override val default: ServerFile? = null
    override val defaultCode: String? = "null" 
}
object Product_quantityType: SerializableProperty<Product, QuantityType> {
    override val name: String by lazy { Product.serializer().descriptor.getElementName(13) }
    override fun get(receiver: Product): QuantityType = receiver.quantityType
    override fun setCopy(receiver: Product, value: QuantityType) = receiver.copy(quantityType = value)
    override val serializer: KSerializer<QuantityType> by lazy { (Product.serializer() as GeneratedSerializer<*>).childSerializers()[13] as KSerializer<QuantityType> }
    override val annotations: List<Annotation> by lazy { Product.serializer().descriptor.getElementAnnotations(13) }
    override val default: QuantityType = QuantityType.Each
    override val defaultCode: String? = "QuantityType.Each" 
}
object Product_outOfDate: SerializableProperty<Product, Boolean> {
    override val name: String by lazy { Product.serializer().descriptor.getElementName(14) }
    override fun get(receiver: Product): Boolean = receiver.outOfDate
    override fun setCopy(receiver: Product, value: Boolean) = receiver.copy(outOfDate = value)
    override val serializer: KSerializer<Boolean> by lazy { (Product.serializer() as GeneratedSerializer<*>).childSerializers()[14] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { Product.serializer().descriptor.getElementAnnotations(14) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object Product_active: SerializableProperty<Product, Boolean> {
    override val name: String by lazy { Product.serializer().descriptor.getElementName(15) }
    override fun get(receiver: Product): Boolean = receiver.active
    override fun setCopy(receiver: Product, value: Boolean) = receiver.copy(active = value)
    override val serializer: KSerializer<Boolean> by lazy { (Product.serializer() as GeneratedSerializer<*>).childSerializers()[15] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { Product.serializer().descriptor.getElementAnnotations(15) }
    override val default: Boolean = true
    override val defaultCode: String? = "true" 
}
