package com.vandenbussche.views.screens.account

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.bind
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.direct.col
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import com.vandenbussche.mappings.equalToDynamic
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.sdk.flatten
import com.vandenbussche.sdk.utils.activeWarehouses
import com.vandenbussche.views.screens.common.ContentDeterminedByAccount
import com.vandenbussche.views.screens.common.HasNarrowContent
import com.vandenbussche.views.textFormatting.format
import com.vandenbussche.views.textFormatting.formatName

@Routable("/Pickup-Locations")
class PickupLocationsScreen: ContentDeterminedByAccount, HasNarrowContent {

    override val noAccountMessage: String = "You don't have an account, so you can't access pickup locations."

    override fun ViewWriter.hasAccount() {
        val distributorPickupLocations = shared { activeWarehouses()() }

        val preferredPickupLocation = shared {
            val session = currentSession()
            session.customerAccounts[session.accountId!!]
        }.flatten().lensPath { it.notNull.preferredPickupLocation }

        scrolls - col {
            forEachUpdating(distributorPickupLocations) { loc ->
                card - rowCollapsingToColumn {
                    expanding - col {
                        bold - h6 { ::content { loc().formatName() } }

                        text { ::content { loc().address.format() } }
                        // TODO: Detailed Address.format()
                    }

                    centered - radioToggleButton {
                        icon {
                            description = "Preferred Pickup Location"
                            ::source { if(checked()) Icon.starFilled else Icon.star }
                        }
                        ::enabled { currentSession().mayOrder }
                        checked bind preferredPickupLocation.equalToDynamic(shared { loc()._id })
                    }
                }
            }
        }
    }
}