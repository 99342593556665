package com.vandenbussche.views.textFormatting

import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime


fun LocalDate.format(): String = "$month $dayOfMonth, $year"

fun Instant.format(): String = buildString {
    val dateTime = this@format.toLocalDateTime(TimeZone.currentSystemDefault())

    append("${dateTime.month.toString().allCapsToTitle()} ${dateTime.dayOfMonth}, ")
    append("${dateTime.year}, ")
    append(
        "${
            (dateTime.hour + 11) % 12 + 1
        }:${
            dateTime.minute.toString().padStart(2, '0')
        } ${
            if (dateTime.hour / 12 < 1) "AM" else "PM"
        }"
    )
}

fun Duration.format(
    days: Boolean = true,
    hours: Boolean = true,
    minutes: Boolean = true,
    seconds: Boolean = true,
    padFirst: Boolean = true,
): String {
    var firstEncountered = false

    return listOfNotNull(
        inWholeDays.takeIf { days }?.takeIf { it > 0 },
        inWholeHours.takeIf { hours }?.let { if (days) it % 24 else it },
        inWholeMinutes.takeIf { minutes }?.mod(60),
        inWholeSeconds.takeIf { seconds }?.mod(60),
    ).joinToString(":") { it.toString().let { str ->
        if (padFirst || firstEncountered) str.padStart(2, '0')
        else {
            firstEncountered = true
            return@let str
        }
    } }
}

fun Duration.formatRelativeToSize(): String {
    var days: Boolean = true
    val hours: Boolean = true
    val minutes: Boolean = true
    var seconds: Boolean = true

    var firstEncountered = false

    if (this > 1.days) {
        seconds = false
    } else {
        days = false
    }

    return listOfNotNull(
        inWholeDays.takeIf { days }?.let { "${it}d" },
        inWholeHours.takeIf { hours }?.let { if (days) it % 24 else it }?.let { "${it}h" },
        inWholeMinutes.takeIf { minutes }?.mod(60)?.let { "${it}m" },
        inWholeSeconds.takeIf { seconds }?.mod(60)?.let { "${it}s" },
    ).joinToString(" ")
}