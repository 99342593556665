package com.vandenbussche.sdk.utils

import com.lightningkite.kiteui.reactive.*
import com.lightningkite.now
import kotlinx.coroutines.*
import kotlin.time.Duration
import kotlinx.datetime.Instant
import kotlin.time.Duration.Companion.seconds

class CachedCalculation<T>(
    val cacheTime: Duration,
    val calculation: suspend CoroutineScope.() -> T
): BaseReadable<T>() {
    var lastLoaded: Instant = Instant.DISTANT_PAST
    private var cyclingCheck: Job? = null
    override fun deactivate() {
        cyclingCheck?.cancel()
        cyclingCheck = null
        super.deactivate()
    }

    override fun activate() {
        super.activate()
        cyclingCheck?.cancel()
        cyclingCheck = GlobalScope.launch {
            while(true) {
                delay((cacheTime - (now() - lastLoaded)).coerceAtLeast(0.01.seconds))
                state = readableState { calculation() }
                lastLoaded = now()
                delay(cacheTime)
            }
        }
    }
}
