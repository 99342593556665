package com.vandenbussche.views.screens.auth

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.awaitOnce
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.vandenbussche.Resources
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.sdk.selectedApi
import com.vandenbussche.views.emptyView
import com.vandenbussche.views.screens.products.CatalogScreen
import com.lightningkite.kiteui.models.rem

@Routable("/")
class Landing: Screen, AuthScreen {
    override fun ViewWriter.render() {
        println("Landing")
        launchGlobal {
            println("Checking...")
            val session = currentSession.invoke()
            println("session: $session")
            val self = session?.self?.invoke()
            println("self: $self")
            if(self != null) {
                screenNavigator.reset(CatalogScreen)
            } else {
                screenNavigator.reset(LoginScreen())
            }
            println("OK")
        }

        emptyView {
            centered - col {
                sizeConstraints(height = 5.rem) - centered - image {
                    source = Resources.imagesLogoFull
                    description = "Vanden Bussche"
                }
                centered - h2("Authenticating...")
                centered - activityIndicator()
            }
            atTopEnd - button {
                centered - h6 {
                    ::content { selectedApi().apiName } }

                onClick {
                    selectedApi.value = selectedApi.awaitOnce().next()
                }
            }
        }
    }
}