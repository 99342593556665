package com.vandenbussche.admin

import com.ilussobsa.views.multiselect
import com.lightningkite.*
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import com.vandenbussche.mappings.calculatingLens
import com.vandenbussche.mappings.nullToBlank
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.views.textFormatting.formatName
import kotlinx.coroutines.*


@Routable("/customers")
class AdminAccountsScreen() : Screen {
    override val title: Readable<String> = Constant<String>("Customers")

    @QueryParameter("search")
    val search = Property("")
    @QueryParameter("representatives")
    val representatives = Property(setOf<UUID>())

    val query = shared {
        Query(
            condition<CustomerAccount> {
                Condition.And(listOfNotNull(
                    search.debounce(500)().let { s ->
                        if (s.isBlank()) condition(true)
                        else Condition.And(s.split(' ').map { part ->
                            Condition.Or(
                                listOfNotNull(
                                    it.email.contains(part, true),
                                    it.address.businessName.contains(part, true),
                                    part.toLongOrNull()?.let { l -> it.erpId.notNull.eq(l) },
                                    it.phoneNumber.contains(part, true),
                                )
                            )
                        })
                    },
                    representatives().takeIf { it.isNotEmpty() }?.let { d -> it.representatives.any { it inside d } },
                ))
            },
            limit = 25,
            orderBy = sort {
                it.address.businessName.ascending()
            }
        )
    }

    private val accounts = shared {
        currentSession.awaitNotNull().customerAccounts.query(query.await())
    }

    override fun ViewWriter.render() = col {
        row {
            expanding - fieldTheme - row {
                icon(Icon.search, "Search")
                expanding - textInput {
                    content bind search
                    hint = "Search by company name, email, phone, or account number"
                }
            }
            space()
            centered - text("Representatives: ")
            fieldTheme - compact - multiselect<User, UUID>(
                query = { q ->
                    currentSession().users.query(Query(Condition.And(listOfNotNull(
                        condition<User> { it.role.inside(UserRole.values().filter { it >= UserRole.Representative }) },
                        q.takeUnless { it.isBlank() }?.let { q ->
                            Condition.And(q.split(' ').map { part ->
                                Condition.Or(
                                    listOf(
                                        condition<User> { it.email.contains(part, true) },
                                        condition<User> { it.firstName.contains(part, true) },
                                        condition<User> { it.lastName.contains(part, true) },
                                        condition<User> { it.phoneNumber.contains(part, true) },
                                    )
                                )
                            })
                        }
                    ))))()
                },
                pull = {
                    currentSession().users[it]() ?: User(
                        _id = it,
                        firstName = "?",
                        lastName = "?",
                        email = "?"
                    )
                },
                toString = { "${it.firstName} ${it.lastName}" },
                getId = { it._id },
                items = representatives
            )
        }
        padded - row {
            weight(2f) - col { text("Company Name") }
            weight(1f) - col { text("Phone") }
            weight(1f) - col { text("Account #") }
            weight(1.5f) - col { text("Warehouse") }
            weight(1.5f) - col { text("Representatives") }
            weight(2f) - col { text("Actions") }
        }
        expanding - col {
            expanding - padded - recyclerView {
                reactiveScope {
                    if (lastVisibleIndex() > accounts().limit - 20)
                        accounts().limit = lastVisibleIndex() + 100
                }
                val items = shared { accounts()() }
                children(items) { account ->
                    card - row {
                        weight(2f) - text { ::content { account().address.businessName } }
                        weight(1f) - text { ::content { account().phoneNumber } }
                        weight(1f) - text { ::content { account().erpId?.toString() ?: "None" } }
                        weight(1.5f) - text {
                            ::content {
                                account().preferredPickupLocation?.let {
                                    currentSession().warehouses[it]()?.formatName()
                                } ?: ""
                            }
                        }
                        weight(1.5f) - text {
                            ::content {
                                account().representatives?.map {
                                    currentSession().users[it]()?.name ?: ""
                                }?.joinToString() ?: ""
                            }
                        }
                        weight(2f) - row {
                            compact - link {
                                centered - text("Orders")
                                ::to {
                                    account().let {
                                        {
                                            AdminOrdersScreen().apply {
                                                this.accounts.value = setOf(it._id)
                                            }
                                        }
                                    } ?: { Screen.Empty }
                                }
                            }

                            compact - link {
                                centered - text("Users")
                                ::to {
                                    account().let {
                                        {
                                            AdminUsersScreen().apply {
                                                this.accounts.value = setOf(it._id)
                                            }
                                        }
                                    } ?: { Screen.Empty }
                                }
                            }

                            compact - button {
                                centered - text("Edit")
                                onClick {
                                    dialogScreenNavigator.navigate(
                                        AdminAccountScreenDialog(account())
                                    )
                                }
                            }

                            danger - compact - button {
                                centered - text("Remove")
                                onClick {
                                    confirmDanger("Remove User", "This action will disable the account.") {
                                        currentSession().customerAccounts[account()._id].modify(
                                            modification<CustomerAccount> {
                                                it.active assign false
                                            })
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

class AdminAccountScreenDialog(
    val account: CustomerAccount
) : Screen {

    val email = Property(account.email)
    val phoneNumber = Property(account.phoneNumber)
    val erpId = Property(account.erpId)
    val address = Property(account.address)
    val active = Property(account.active)
    val representatives = Property(account.representatives)
    val preferredShippingAddress = Property(account.preferredShippingAddress)
    val preferredPickupLocation = Property(account.preferredPickupLocation)

    override fun ViewWriter.render() {
        dismissBackground {
            centered - card - scrolls - col {
                h2("Edit Account")
                separator()

                row {
                    weight(1f) - label {
                        content = "Contact Name"
                        fieldTheme - compact - textInput {
                            keyboardHints = KeyboardHints.title
                            content bind address.lensPath { it.individualName }.nullToBlank()
                        }
                    }
                    weight(1f) - label {
                        content = "Business Name"
                        fieldTheme - compact - textInput {
                            keyboardHints = KeyboardHints.title
                            content bind address.lensPath { it.businessName }
                        }
                    }
                }
                row {
                    weight(2f) - label {
                        content = "Street"
                        fieldTheme - compact - textInput {
                            keyboardHints = KeyboardHints.title
                            content bind address.lensPath { it.street }
                        }
                    }
                    weight(1f) - label {
                        content = "Unit / Suite"
                        fieldTheme - compact - textInput {
                            keyboardHints = KeyboardHints.title
                            content bind address.lensPath { it.unitOrSuite }.nullToBlank()
                        }
                    }
                }
                row {
                    weight(1f) - label {
                        content = "City"
                        fieldTheme - compact - textInput {
                            keyboardHints = KeyboardHints.title
                            content bind address.lensPath { it.city }
                        }
                    }
                    weight(1f) - label {
                        content = "Province"
                        fieldTheme - compact - textInput {
                            keyboardHints = KeyboardHints.title
                            content bind address.lensPath { it.subcountry }
                        }
                    }
                }
                row {
                    weight(1f) - label {
                        content = "Province"
                        fieldTheme - compact - textInput {
                            keyboardHints = KeyboardHints.title
                            content bind address.lensPath { it.country }
                        }
                    }
                    weight(1f) - label {
                        content = "Postal Code"
                        fieldTheme - compact - textInput {
                            keyboardHints = KeyboardHints.integer
                            content bind address.lensPath { it.postalCode }
                        }
                    }
                }
                row {
                    weight(1f) - col {
                        label {
                            content = "Contact Email"
                            fieldTheme - compact - textInput {
                                keyboardHints = KeyboardHints.email
                                content bind email
                            }
                        }
                    }
                    weight(1f) - col {
                        label {
                            content = "Contact Phone"
                            fieldTheme - compact - textInput {
                                keyboardHints = KeyboardHints.phone
                                content bind phoneNumber
                            }
                        }
                    }
                }
                row {
                    weight(1f) - col {
                        label {
                            content = "ERP ID"
                            fieldTheme - compact - numberInput {
                                keyboardHints = KeyboardHints.id
                                content bind erpId.lens(
                                    get = { it?.toDouble() },
                                    set = { it?.toLong() }
                                )
                            }
                        }
                    }
                }
                row {
                    weight(1f) - col {
                        label {
                            content = "Preferred Shipping"
                            fieldTheme - compact - select {
                                val options = shared {
                                    currentSession().shippingAddresses.query(
                                        Query(
                                            condition { it.account eq account._id },
                                            sort { it.name.notNull.ascending() })
                                    )() + listOf(null)
                                }
                                bind<ShippingAddress?>(
                                    edits = preferredShippingAddress.calculatingLens(
                                        get = {
                                            it?.let { currentSession().shippingAddresses[it]() }
                                        },
                                        set = { it?._id }
                                    ),
                                    data = options,
                                    render = { it?.name ?: "None" }
                                )
                            }
                        }
                    }
                    weight(1f) - col {
                        label {
                            content = "Preferred Warehouse"
                            fieldTheme - compact - select {
                                val options = shared {
                                    currentSession().warehouses.query(
                                        Query(
                                            condition { (it.public eq true) or (it.permittedAccounts.any { it eq account._id }) },
                                            sort { it.public.ascending(); it.name.ascending() })
                                    )() + listOf(null)
                                }
                                bind<Warehouse?>(
                                    edits = preferredPickupLocation.calculatingLens(
                                        get = {
                                            it?.let { currentSession().warehouses[it]() }
                                        },
                                        set = { it?._id }
                                    ),
                                    data = options,
                                    render = { it?.name ?: "None" }
                                )
                            }
                        }
                    }
                }
                label {
                    content = "Representatives"
                    fieldTheme - compact - multiselect<User, UUID>(
                        query = { q ->
                            currentSession().users.query(Query(Condition.And(listOfNotNull(
                                condition<User> { it.role.inside(UserRole.values().filter { it >= UserRole.Representative }) },
                                q.takeUnless { it.isBlank() }?.let { q ->
                                    Condition.And(q.split(' ').map { part ->
                                        Condition.Or(
                                            listOf(
                                                condition<User> { it.email.contains(part, true) },
                                                condition<User> { it.firstName.contains(part, true) },
                                                condition<User> { it.lastName.contains(part, true) },
                                                condition<User> { it.phoneNumber.contains(part, true) },
                                            )
                                        )
                                    })
                                }
                            ))))()
                        },
                        pull = {
                            currentSession().users[it]() ?: User(
                                _id = it,
                                firstName = "?",
                                lastName = "?",
                                email = "?"
                            )
                        },
                        toString = { "${it.firstName} ${it.lastName}" },
                        getId = { it._id },
                        items = representatives
                    )
                }

                separator()
                row {
                    button {
                        centered - text("Cancel")
                        onClick {
                            dialogScreenNavigator.dismiss()
                        }
                    }
                    expanding - space()
                    important - button {
                        centered - text("Save Changes")
                        onClick {
                            currentSession().customerAccounts[account._id].modify(
                                modification<CustomerAccount> {
                                    it.email assign email()
                                    it.phoneNumber assign phoneNumber()
                                    it.erpId assign erpId()
                                    it.address assign address()
                                    it.active assign active()
                                    it.representatives assign representatives()
                                    it.preferredShippingAddress assign preferredShippingAddress()
                                    it.preferredPickupLocation assign preferredPickupLocation()
                                }
                            )
                            dialogScreenNavigator.dismiss()
                        }
                    }
                }
            }
        }
    }
}