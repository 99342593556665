package com.vandenbussche

import com.lightningkite.kiteui.monitoring.FunnelControl
import com.lightningkite.kiteui.monitoring.funnel


class FunnelsManager() {
    val trackPlatformUsed = "trackPlatformUsed"
    val funnels: MutableMap<String, FunnelControl?> = mutableMapOf()

    fun startTracking(funnelName:String) {
        funnels.put(funnelName, funnel(funnelName))
    }
    fun step(funnelName:String, stepNumber:Int) {
        if(funnels[funnelName] ==null)return
        funnels[funnelName]?.step(stepNumber)
    }
    fun success(funnelName: String) {
        if(funnels[funnelName] ==null)return
        funnels[funnelName]?.success()
    }
    fun error(funnelName: String, errorMessage:String) {
        if(funnels[funnelName] ==null)return
        funnels[funnelName]?.error(errorMessage)
    }

    fun trackPlatforms() {
        funnel(trackPlatformUsed).success()
    }
}