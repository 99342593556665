// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.vandenbussche.models

import com.lightningkite.lightningdb.*
import com.lightningkite.UUID
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareCartItemFields() {
    val props: Array<SerializableProperty<CartItem, *>> = arrayOf(CartItem_product, CartItem_productErpId, CartItem_quantity, CartItem_previousPrice, CartItem_previousLineTotal, CartItem_previousListPrice, CartItem_previousDiscountType)
    CartItem.serializer().properties { props }
}
val <K> DataClassPath<K, CartItem>.product: DataClassPath<K, UUID> get() = this[CartItem_product]
val <K> DataClassPath<K, CartItem>.productErpId: DataClassPath<K, String?> get() = this[CartItem_productErpId]
val <K> DataClassPath<K, CartItem>.quantity: DataClassPath<K, Int> get() = this[CartItem_quantity]
val <K> DataClassPath<K, CartItem>.previousPrice: DataClassPath<K, PriceInCents?> get() = this[CartItem_previousPrice]
val <K> DataClassPath<K, CartItem>.previousLineTotal: DataClassPath<K, PriceInCents?> get() = this[CartItem_previousLineTotal]
val <K> DataClassPath<K, CartItem>.previousListPrice: DataClassPath<K, PriceInCents?> get() = this[CartItem_previousListPrice]
val <K> DataClassPath<K, CartItem>.previousDiscountType: DataClassPath<K, String?> get() = this[CartItem_previousDiscountType]
inline val CartItem.Companion.path: DataClassPath<CartItem, CartItem> get() = path<CartItem>()


object CartItem_product: SerializableProperty<CartItem, UUID> {
    override val name: String by lazy { CartItem.serializer().descriptor.getElementName(0) }
    override fun get(receiver: CartItem): UUID = receiver.product
    override fun setCopy(receiver: CartItem, value: UUID) = receiver.copy(product = value)
    override val serializer: KSerializer<UUID> by lazy { (CartItem.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { CartItem.serializer().descriptor.getElementAnnotations(0) }
}
object CartItem_productErpId: SerializableProperty<CartItem, String?> {
    override val name: String by lazy { CartItem.serializer().descriptor.getElementName(1) }
    override fun get(receiver: CartItem): String? = receiver.productErpId
    override fun setCopy(receiver: CartItem, value: String?) = receiver.copy(productErpId = value)
    override val serializer: KSerializer<String?> by lazy { (CartItem.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { CartItem.serializer().descriptor.getElementAnnotations(1) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object CartItem_quantity: SerializableProperty<CartItem, Int> {
    override val name: String by lazy { CartItem.serializer().descriptor.getElementName(2) }
    override fun get(receiver: CartItem): Int = receiver.quantity
    override fun setCopy(receiver: CartItem, value: Int) = receiver.copy(quantity = value)
    override val serializer: KSerializer<Int> by lazy { (CartItem.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<Int> }
    override val annotations: List<Annotation> by lazy { CartItem.serializer().descriptor.getElementAnnotations(2) }
}
object CartItem_previousPrice: SerializableProperty<CartItem, PriceInCents?> {
    override val name: String by lazy { CartItem.serializer().descriptor.getElementName(3) }
    override fun get(receiver: CartItem): PriceInCents? = receiver.previousPrice
    override fun setCopy(receiver: CartItem, value: PriceInCents?) = receiver.copy(previousPrice = value)
    override val serializer: KSerializer<PriceInCents?> by lazy { (CartItem.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<PriceInCents?> }
    override val annotations: List<Annotation> by lazy { CartItem.serializer().descriptor.getElementAnnotations(3) }
    override val default: PriceInCents? = null
    override val defaultCode: String? = "null" 
}
object CartItem_previousLineTotal: SerializableProperty<CartItem, PriceInCents?> {
    override val name: String by lazy { CartItem.serializer().descriptor.getElementName(4) }
    override fun get(receiver: CartItem): PriceInCents? = receiver.previousLineTotal
    override fun setCopy(receiver: CartItem, value: PriceInCents?) = receiver.copy(previousLineTotal = value)
    override val serializer: KSerializer<PriceInCents?> by lazy { (CartItem.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<PriceInCents?> }
    override val annotations: List<Annotation> by lazy { CartItem.serializer().descriptor.getElementAnnotations(4) }
    override val default: PriceInCents? = null
    override val defaultCode: String? = "null" 
}
object CartItem_previousListPrice: SerializableProperty<CartItem, PriceInCents?> {
    override val name: String by lazy { CartItem.serializer().descriptor.getElementName(5) }
    override fun get(receiver: CartItem): PriceInCents? = receiver.previousListPrice
    override fun setCopy(receiver: CartItem, value: PriceInCents?) = receiver.copy(previousListPrice = value)
    override val serializer: KSerializer<PriceInCents?> by lazy { (CartItem.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<PriceInCents?> }
    override val annotations: List<Annotation> by lazy { CartItem.serializer().descriptor.getElementAnnotations(5) }
    override val default: PriceInCents? = null
    override val defaultCode: String? = "null" 
}
object CartItem_previousDiscountType: SerializableProperty<CartItem, String?> {
    override val name: String by lazy { CartItem.serializer().descriptor.getElementName(6) }
    override fun get(receiver: CartItem): String? = receiver.previousDiscountType
    override fun setCopy(receiver: CartItem, value: String?) = receiver.copy(previousDiscountType = value)
    override val serializer: KSerializer<String?> by lazy { (CartItem.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { CartItem.serializer().descriptor.getElementAnnotations(6) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
