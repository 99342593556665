package com.vandenbussche.admin

import com.ilussobsa.views.searchSelect
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.db.LimitReadable
import com.lightningkite.lightningserver.files.ServerFile
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.sdk.utils.modify
import com.vandenbussche.theming.chevronDown
import com.vandenbussche.theming.chevronUp
import com.vandenbussche.views.components.productCategoryCardContent
import com.vandenbussche.views.components.productImage
import com.vandenbussche.views.screens.products.AdminCategoryScreen
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.joinAll
import kotlinx.coroutines.launch


@QueryParameter("search")
val search = Property("")

@QueryParameter("limitToNoImage")
val limitToNoImage = Property<Boolean>(false)


val topLevelCategories = shared(useLastWhileLoading = true) {
    currentSession.awaitNotNull().productCategories.watch(
        Query(
        condition<ProductCategory> {
            Condition.And(
                listOfNotNull(
                    search.debounce(500)().let { s ->
                        if (s.isBlank()) condition(true)
                        else Condition.And(s.split(' ').map { part ->
                            it.path.contains(part, true)
                        })
                    },
                    if (limitToNoImage.await()) it.image eq null else null,
                    it.parent eq null
                )
            )
        },
        orderBy = sort {
            it.order.ascending()
        }
    ))
}
val categories = shared(useLastWhileLoading = true) {
    currentSession.awaitNotNull().productCategories.watch(
        Query(
            condition<ProductCategory> {
                Condition.And(
                    listOfNotNull(
                        search.debounce(500)().let { s ->
                            if (s.isBlank()) condition(true)
                            else Condition.And(s.split(' ').map { part ->
                                it.path.contains(part, true)
                            })
                        },
                        if (limitToNoImage.await()) it.image eq null else null,
                    )
                )
            },
            orderBy = sort {
                it.order.ascending()
            },
            limit = 1000
        )
    )
}

@Routable("/categories")
class AdminCatalogScreen() : Screen {
    override val title: Readable<String> = Constant<String>("Categories")

    override fun ViewWriter.render() = col {
        row {
            expanding - fieldTheme - row {
                icon(Icon.search, "Search")
                expanding - textInput {
                    content bind search
                    hint = "Search"
                }
            }
            row {
                centered - checkbox {
                    checked bind limitToNoImage
                }
                centered - text("Limit to categories without image")
            }
            button {
                centered - row {
                    centered - icon(Icon.add, "")
                    centered - text("Add ")
                }
                onClick {
                    dialogScreenNavigator.navigate(AdminCategoryScreenDialog(null, null))
                }
            }
        }
        expanding - col {
            expanding - recyclerView {
                reactiveScope {
                    if (lastVisibleIndex() > topLevelCategories().limit - 20)
                        topLevelCategories().limit = lastVisibleIndex() + 100
                }
                val items = shared { topLevelCategories()() }
                children(items) { category ->
                    card - row {
                        centered - orderChange(category, topLevelCategories)
                        expanding - adminProductCategoryCard(category)
                    }
                }
            }
        }
    }
}

fun ViewWriter.adminProductCategoryCard(category: Readable<ProductCategory>) = row {

    expanding - link {
        spacing = 0.rem
        ::to {
            val id = category()._id
            { AdminCategoryScreen(id) }
        }
        productCategoryCardContent(category)
    }
    col {
        compact - button {
            centered - text("Edit")
            onClick {
                dialogScreenNavigator.navigate(AdminCategoryScreenDialog(category(), category().parent))
            }
        }
        compact - danger - button {
            centered - text {
                ::content { "Remove" }
            }
            onClick {
                confirmDanger(
                    "Remove Category",
                    "This action will remove ${category().name} and any subcategories will be relocated to this page."
                ) {
                    if (category().parent == null) {
                        currentSession().productCategories.bulkModify(
                            MassModification(
                                condition { it.parent eq category()._id },
                                modification { it.parent assign null }
                            )
                        )
                        currentSession().nonCached.productCategory.delete(category()._id)

                    } else {
                        currentSession().productCategories.bulkModify(
                            MassModification(
                                condition { it.parent eq category()._id },
                                modification { it.parent assign category().parent }
                            ))
                        currentSession().nonCached.productCategory.delete(category()._id)

                    }
                    currentSession().products.bulkModify(
                        MassModification(
                            condition { it.categories.any { it eq category()._id } },
                            modification { it.categories.removeAll { it eq category()._id } }
                        )
                    )
                }
            }
        }
    }
}

class AdminCategoryScreenDialog(
    val category: ProductCategory?,
    val parentCategoryId: UUID?
) : Screen {
    val categoryImage =
        Property<Pair<ServerFile, ImageSource>?>(category?.image?.let { it to ImageRemote(it.location) })
    private val categoryName = Property(category?.name ?: "")
    val image = Property(category?.image)
    val selectedParentCategory = Property(category?.parent ?: parentCategoryId)

    override fun ViewWriter.render() {
        dismissBackground {
            centered - card - col {
                h2(if (category == null) "Add Category" else "Edit Category")
                separator()
                label {
                    content = "Category Name"
                    fieldTheme - compact - textInput {
                        keyboardHints = KeyboardHints.title
                        content bind categoryName
                    }
                }
                label {
                    content = "Parent Category"
                    searchSelect<ProductCategory, UUID>(
                        query = { input ->
                            input.takeUnless { it.isBlank() }?.let { s ->
                                categories()().filter { it.path.contains(s, true) }
                            } ?: categories()()
                        },
                        pull = {
                            currentSession().productCategories[it]() ?: ProductCategory.EMPTY
                        },
                        getId = { it._id },
                        toString = { it.path },
                        selected = selectedParentCategory
                    )
                }

                centered - col {
                    row {
                        sizeConstraints(maxHeight = 5.rem, maxWidth = 5.rem) - image {
                            ::source { categoryImage()?.second }
                        }
                        compact - button {
                            row {
                                centered - icon(Icon.chevronUp, "Upload an image")
                                centered - text("Upload Image")
                            }
                            onClick {
                                val file = ExternalServices.requestFile(listOf("image/*")) ?: return@onClick
                                val req = currentSession().nonCached.api.uploadFileForRequest()
                                fetch(req.uploadUrl, HttpMethod.PUT, body = file)
                                categoryImage.set(ServerFile(req.futureCallToken) to ImageLocal(file))
                            }
                        }
                    }
                }
                separator()
                row {
                    button {
                        centered - text("Cancel")
                        onClick {
                            dialogScreenNavigator.dismiss()
                        }
                    }
                    expanding - space()
                    important - button {
                        ::exists { categoryName().isNotBlank() }
                        centered - text("Save Changes")
                        onClick {
                            val path = selectedParentCategory()?.let {currentSession().productCategories[it]}?.invoke()?.path?.let {"$it > ${categoryName()}"} ?: categoryName()
                            category?._id?.let { id ->
                                currentSession().productCategories[category._id].modify(
                                    modification<ProductCategory> {
                                        it.name assign categoryName()
                                        it.path assign path
                                        it.image assign categoryImage()?.first
                                        it.parent assign selectedParentCategory()
                                    }
                                )
                            } ?: run {
                                val category = currentSession().productCategories.insert(
                                    ProductCategory(
                                        name = categoryName(),
                                        image = categoryImage()?.first,
                                        parent = if (selectedParentCategory.value != null) selectedParentCategory() else parentCategoryId,
                                        path =  path
                                    )
                                )()
                                val productsToMove = parentCategoryId?.let { id ->
                                    currentSession().products.query(
                                        Query(
                                            condition { it.categories.any { it.eq(id) } }
                                        ))
                                }?.invoke()
                                if (productsToMove != null && category != null) {
                                    currentSession().products.bulkModify(
                                        MassModification(
                                        condition { it._id.inside(productsToMove.map { it._id }) },
                                        modification {
                                            it.categories.forEachIf(
                                                { it eq parentCategoryId },
                                                { it assign category._id }
                                            )
                                        }
                                    )
                                    )
                                }
                            }
                            dialogScreenNavigator.dismiss()
                        }
                    }
                }
            }
        }
    }
}

fun ViewWriter.adminProductCardNoLink(
    product: Readable<Product>,
    imageHeight: Dimension,
) {
    row {
        sizeConstraints(width = imageHeight, height = imageHeight) - productImage(product)

        expanding - col {
            spacing = 0.px
            text { ::content { product().title } }
            subtext { ::content { product().manufacturer ?: "No Part Number" } }
            subtext { ::content { product().erpId ?: "No Part Number" } }
        }
        centered - text {
            ::content{ "Order in \n List: ${product().order}" }
        }
        space(multiplier = 2.0)
        col {
            compact - button {
                centered - text("Edit")
                onClick {
                    dialogScreenNavigator.navigate(ProductModal(product.invoke()))
                }
            }
            compact - button {
                dynamicTheme {
                    if (product().active) DangerSemantic else null
                }
                centered - text {
                    ::content { if (product().active) "Remove" else "Restore" }
                }
                onClick {
                    if (product().active) {
                        confirmDanger("Remove Product", "This action will remove ${product().title}") {
                            currentSession().products[product()._id].modify(
                                modification<Product> {
                                    it.active assign false
                                })
                        }
                    } else {
                        confirmDanger(
                            "Restore Product",
                            "This action will restore ${product().title} to public visibility"
                        ) {
                            currentSession().products[product()._id].modify(
                                modification<Product> {
                                    it.active assign true
                                })
                        }
                    }
                }
            }
        }

    }
}

fun ViewWriter.orderChange(category: Readable<ProductCategory>, categories: Readable<LimitReadable<ProductCategory>>) {
    col {
        val controlsSpacing = 0.3.rem
        spacing = 0.px

        button {
            spacing = controlsSpacing
            centered - icon(Icon.chevronUp, "Move up")
            onClick {
                category().increment(categories()(), category())
            }
        }

        button {
            spacing = controlsSpacing
            centered - icon(Icon.chevronDown, "Move down")
            onClick {
                category().decrement(categories()(), category())
            }
        }
    }
}

suspend fun ProductCategory.increment(categories: List<ProductCategory>, category: ProductCategory) {
    val p = currentSession().productCategories
    val swapIndexIncrement = categories.indexOf(category) - 1
    if (swapIndexIncrement >= categories.size || swapIndexIncrement < 0) return
    val nextHighestCategory = categories.elementAt(swapIndexIncrement)

    coroutineScope {
        listOf(
            launch { p.get(_id).modify { it.order assign nextHighestCategory.order } },
            launch { p.get(nextHighestCategory._id).modify { it.order assign category.order } },
        ).joinAll()
    }
}

suspend fun ProductCategory.decrement(categories: List<ProductCategory>, category: ProductCategory) {
    val p = currentSession().productCategories
    val swapIndexDecrement = categories.indexOf(category) + 1
    if (swapIndexDecrement >= categories.size || swapIndexDecrement < 0) return
    val nextLowestCategory = categories.elementAt(swapIndexDecrement)

    coroutineScope {
        listOf(
            launch { p.get(_id).modify { it.order assign nextLowestCategory.order } },
            launch { p.get(nextLowestCategory._id).modify { it.order assign category.order } },
        ).joinAll()
    }
}