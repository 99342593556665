package com.vandenbussche.views.screens.products

import com.lightningkite.UUID
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.reactive.reactiveScope
import com.lightningkite.kiteui.reactive.shared
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.card
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.lightningdb.*
import com.vandenbussche.models.categories
import com.vandenbussche.models.order
import com.vandenbussche.models.parent
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.views.components.productCard
import com.vandenbussche.views.components.productCategoryCard
import com.vandenbussche.views.screens.common.HasNarrowContent

@Routable("Category/{categoryID}")
class CategoryScreen(val categoryID: UUID) : Screen, HasNarrowContent {

    val category = shared {
        currentSession().productCategories[categoryID]()!!
    }

    val childCategories = shared {
        currentSession().productCategories.query(Query(
            condition = condition { it.parent eq categoryID },
            orderBy = sort { it.order.ascending() }
        )).invoke()
    }
    val products = shared {
        currentSession().products.query(Query(
            condition { prod -> prod.categories.any { it eq categoryID } },
            orderBy = sort { it.order.ascending() }
        ))()
    }

    override val title: Readable<String> = shared {
        return@shared if
                (narrow())
                    if(category().name.length > 25)category().name.substring(0, 25) else category().name
        else category().path
    }

    override fun ViewWriter.render() {
        stack {
            centered - activityIndicator {
                ::exists {
                    childCategories().isEmpty() && products().isEmpty()
                }
            }
             swapView {
                swapping(
                    current = {
                        childCategories().isNotEmpty()
                    },
                    views = { hasChildCategories ->
                        if (hasChildCategories) {
//                            col {
                                recyclerView {
//                                    ::columns { if (narrow()) 1 else 2 }

                                    children(childCategories) {
                                        productCategoryCard(it)
                                    }
                                }
//                            }
                        } else {
//                            col {
                                 recyclerView {
//                                    ::columns { if (narrow()) 1 else 2 }
//
                                    children(products) {
                                        card - productCard(it, imageHeight = 5.rem)
                                    }
                                }
//                            }
                        }
                    }
                )
            }
        }
    }
//    }
}