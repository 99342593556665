// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.vandenbussche.models

import com.lightningkite.lightningdb.*
import com.lightningkite.UUID
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareManufacturerFields() {
    val props: Array<SerializableProperty<Manufacturer, *>> = arrayOf(Manufacturer__id)
    Manufacturer.serializer().properties { props }
}
val <K> DataClassPath<K, Manufacturer>._id: DataClassPath<K, String> get() = this[Manufacturer__id]
inline val Manufacturer.Companion.path: DataClassPath<Manufacturer, Manufacturer> get() = path<Manufacturer>()


object Manufacturer__id: SerializableProperty<Manufacturer, String> {
    override val name: String by lazy { Manufacturer.serializer().descriptor.getElementName(0) }
    override fun get(receiver: Manufacturer): String = receiver._id
    override fun setCopy(receiver: Manufacturer, value: String) = receiver.copy(_id = value)
    override val serializer: KSerializer<String> by lazy { (Manufacturer.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Manufacturer.serializer().descriptor.getElementAnnotations(0) }
}
