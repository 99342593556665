package com.vandenbussche.admin

import com.ilussobsa.views.multiselect
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.views.textFormatting.format
import com.vandenbussche.views.textFormatting.formatName



@Routable("/warehouses")
class AdminWarehousesScreen() : Screen {
    override val title: Readable<String> = Constant<String>("Warehouses")
    private val warehouses = shared {
        val session = currentSession()
        session.warehouses.query(
            Query(
//            condition { it.distributor.eq(session.distributor._id) },
                orderBy = sort { it.name.ascending() },
                limit = 1000
            )
        )()
    }

    override fun ViewWriter.render() = col {
        padded - row {
            weight(1f) - col {
                text("Name")
            }
            weight(2f) - col {
                text("Address")
            }
            weight(0.5f) - col {
                centered - text("Public")
            }
            weight(2f) - col {
                centered - text("Accounts")
            }
        }
        expanding - col {

            expanding - recyclerView {
                children(warehouses) { wh ->
                    card - row {
                        weight(1f) - centered - text { ::content{ wh().formatName() } }
                        weight(2f) - centered - text { ::content{ wh().address.format() } }
                        weight(0.5f) - stack {
                            centered - switch {
                                checked bind shared { wh().public }.withWrite { value ->
                                    currentSession().warehouses[wh()._id].modify(modification { it.public assign value })
                                }
                            }
                        }
                        weight(2f) - col {
                            fieldTheme - compact - multiselect(
                                query = { q ->
                                    currentSession().customerAccounts.query(Query(Condition.And(listOfNotNull(
                                        Condition.Always(),
                                        q.takeUnless { it.isBlank() }?.let { q ->
                                            Condition.And(q.split(' ').map { part ->
                                                Condition.Or(
                                                    listOf(
                                                        condition<CustomerAccount> {
                                                            it.address.businessName.contains(
                                                                part,
                                                                true
                                                            )
                                                        },
                                                        condition<CustomerAccount> { it.email.contains(part, true) },
                                                        condition<CustomerAccount> { it.phoneNumber.contains(part, true) },
                                                    )
                                                )
                                            })
                                        }
                                    ))))()
                                },
                                pull = {
                                    currentSession().customerAccounts[it]() ?: CustomerAccount(
                                        _id = it,
                                        email = "?",
                                        phoneNumber = "?",
                                        address = Address.EMPTY
                                    )
                                },
                                toString = { it.address.businessName },
                                getId = { it._id },
                                items = shared { wh().permittedAccounts }
                                    .withWrite { v ->
                                        currentSession().warehouses[wh()._id].modify(modification {
                                            it.permittedAccounts assign v
                                        })
                                    }
                            )
                        }
                    }
                }
            }
        }
    }
}