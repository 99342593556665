// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.vandenbussche.models

import com.lightningkite.lightningdb.*
import com.lightningkite.UUID
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareProductCategoryFields() {
    val props: Array<SerializableProperty<ProductCategory, *>> = arrayOf(ProductCategory__id, ProductCategory_name, ProductCategory_order, ProductCategory_productCount, ProductCategory_path, ProductCategory_imageUrlToMigrate, ProductCategory_image, ProductCategory_parent)
    ProductCategory.serializer().properties { props }
}
val <K> DataClassPath<K, ProductCategory>._id: DataClassPath<K, UUID> get() = this[ProductCategory__id]
val <K> DataClassPath<K, ProductCategory>.name: DataClassPath<K, String> get() = this[ProductCategory_name]
val <K> DataClassPath<K, ProductCategory>.order: DataClassPath<K, Double> get() = this[ProductCategory_order]
val <K> DataClassPath<K, ProductCategory>.productCount: DataClassPath<K, Int> get() = this[ProductCategory_productCount]
val <K> DataClassPath<K, ProductCategory>.path: DataClassPath<K, String> get() = this[ProductCategory_path]
val <K> DataClassPath<K, ProductCategory>.imageUrlToMigrate: DataClassPath<K, String?> get() = this[ProductCategory_imageUrlToMigrate]
val <K> DataClassPath<K, ProductCategory>.image: DataClassPath<K, ServerFile?> get() = this[ProductCategory_image]
val <K> DataClassPath<K, ProductCategory>.parent: DataClassPath<K, UUID?> get() = this[ProductCategory_parent]
inline val ProductCategory.Companion.path: DataClassPath<ProductCategory, ProductCategory> get() = path<ProductCategory>()


object ProductCategory__id: SerializableProperty<ProductCategory, UUID> {
    override val name: String by lazy { ProductCategory.serializer().descriptor.getElementName(0) }
    override fun get(receiver: ProductCategory): UUID = receiver._id
    override fun setCopy(receiver: ProductCategory, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (ProductCategory.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ProductCategory.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object ProductCategory_name: SerializableProperty<ProductCategory, String> {
    override val name: String by lazy { ProductCategory.serializer().descriptor.getElementName(1) }
    override fun get(receiver: ProductCategory): String = receiver.name
    override fun setCopy(receiver: ProductCategory, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> by lazy { (ProductCategory.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { ProductCategory.serializer().descriptor.getElementAnnotations(1) }
}
object ProductCategory_order: SerializableProperty<ProductCategory, Double> {
    override val name: String by lazy { ProductCategory.serializer().descriptor.getElementName(2) }
    override fun get(receiver: ProductCategory): Double = receiver.order
    override fun setCopy(receiver: ProductCategory, value: Double) = receiver.copy(order = value)
    override val serializer: KSerializer<Double> by lazy { (ProductCategory.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<Double> }
    override val annotations: List<Annotation> by lazy { ProductCategory.serializer().descriptor.getElementAnnotations(2) }
    override val default: Double = 0.0
    override val defaultCode: String? = "0.0" 
}
object ProductCategory_productCount: SerializableProperty<ProductCategory, Int> {
    override val name: String by lazy { ProductCategory.serializer().descriptor.getElementName(3) }
    override fun get(receiver: ProductCategory): Int = receiver.productCount
    override fun setCopy(receiver: ProductCategory, value: Int) = receiver.copy(productCount = value)
    override val serializer: KSerializer<Int> by lazy { (ProductCategory.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<Int> }
    override val annotations: List<Annotation> by lazy { ProductCategory.serializer().descriptor.getElementAnnotations(3) }
    override val default: Int = 0
    override val defaultCode: String? = "0" 
}
object ProductCategory_path: SerializableProperty<ProductCategory, String> {
    override val name: String by lazy { ProductCategory.serializer().descriptor.getElementName(4) }
    override fun get(receiver: ProductCategory): String = receiver.path
    override fun setCopy(receiver: ProductCategory, value: String) = receiver.copy(path = value)
    override val serializer: KSerializer<String> by lazy { (ProductCategory.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { ProductCategory.serializer().descriptor.getElementAnnotations(4) }
}
object ProductCategory_imageUrlToMigrate: SerializableProperty<ProductCategory, String?> {
    override val name: String by lazy { ProductCategory.serializer().descriptor.getElementName(5) }
    override fun get(receiver: ProductCategory): String? = receiver.imageUrlToMigrate
    override fun setCopy(receiver: ProductCategory, value: String?) = receiver.copy(imageUrlToMigrate = value)
    override val serializer: KSerializer<String?> by lazy { (ProductCategory.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { ProductCategory.serializer().descriptor.getElementAnnotations(5) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object ProductCategory_image: SerializableProperty<ProductCategory, ServerFile?> {
    override val name: String by lazy { ProductCategory.serializer().descriptor.getElementName(6) }
    override fun get(receiver: ProductCategory): ServerFile? = receiver.image
    override fun setCopy(receiver: ProductCategory, value: ServerFile?) = receiver.copy(image = value)
    override val serializer: KSerializer<ServerFile?> by lazy { (ProductCategory.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<ServerFile?> }
    override val annotations: List<Annotation> by lazy { ProductCategory.serializer().descriptor.getElementAnnotations(6) }
    override val default: ServerFile? = null
    override val defaultCode: String? = "null" 
}
object ProductCategory_parent: SerializableProperty<ProductCategory, UUID?> {
    override val name: String by lazy { ProductCategory.serializer().descriptor.getElementName(7) }
    override fun get(receiver: ProductCategory): UUID? = receiver.parent
    override fun setCopy(receiver: ProductCategory, value: UUID?) = receiver.copy(parent = value)
    override val serializer: KSerializer<UUID?> by lazy { (ProductCategory.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<UUID?> }
    override val annotations: List<Annotation> by lazy { ProductCategory.serializer().descriptor.getElementAnnotations(7) }
    override val default: UUID? = null
    override val defaultCode: String? = "null" 
}
