package com.vandenbussche.views.screens.products

import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.LazyProperty
import com.lightningkite.kiteui.views.RView
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.now
import com.lightningkite.serialization.*
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession

object MessageHandler {
    var now = now()
    val message = LazyProperty {
        currentSession().popupMessages.query(
            Query(
                condition { (it.startTime lte now) and (it.stopTime gte now) },
                orderBy = sort { it.startTime.descending() },
                limit = 1
            )
        )().firstOrNull()
    }

    private var remover = {}
    fun RView.startedViewing() {
        now = now()

        // When the user navigates somewhere else, stop viewing
        remover = mainScreenNavigator.stack.addListener {
            stoppedViewing()
        }
    }
    fun stoppedViewing() {
        if (message.state.success) {
            remover()
            val viewed = message.value ?: return

            if (viewed.behavior != PopupBehavior.Always) {
                message.value = null
            }
        }
    }
    fun update(message: PopupMessage) {
        this.message.value = message
    }
    fun close() {
        if (message.state.success) {
            remover()
            message.value = null
        }
    }
}