package com.vandenbussche.admin

import com.ilussobsa.views.multiselect
import com.ilussobsa.views.searchSelect
import com.lightningkite.UUID
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.locale.renderToString
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.monitoring.funnel
import com.lightningkite.lightningserver.monitoring.success
import com.lightningkite.lightningserver.monitoring.user
import com.lightningkite.serialization.*
import com.vandenbussche.funnelsManager
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import kotlinx.coroutines.launch

@Routable("/users")
class AdminUsersScreen() : Screen {
    override val title: Readable<String> = Constant<String>("Users")

    @QueryParameter("search")
    val search = Property("")

    @QueryParameter("showRemoved")
    val showRemoved = Property(false)

    @QueryParameter("editUser")
    val editUser = Property<String?>(null)

    @QueryParameter("accounts")
    val accounts = Property<Set<UUID>>(setOf())

    val query = shared {
        Query(
            condition<User> {
                Condition.And(
                    listOfNotNull(
                        search.debounce(500)().let { s ->
                            if (s.isBlank()) condition(true)
                            else Condition.And(s.split(' ').map { part ->
                                Condition.Or(
                                    listOfNotNull(
                                        it.email.contains(part, true),
                                        it.phoneNumber.contains(part, true),
                                        it.firstName.contains(part, true),
                                        it.lastName.contains(part, true),
                                    )
                                )
                            })
                        },
                        accounts().takeIf { it.isNotEmpty() }?.let { d -> it.account inside d },
                        if (showRemoved()) null else it.active.neq(false),
                        it.role inside UserRole.values()
                            .filter { it < UserRole.Representative },
                    )
                )
            },
            limit = 25,
            orderBy = sort {
                it.account.notNull.ascending()
                it.active.descending()
                it.lastName.ascending()
            }
        )
    }

    private val users = shared {
        currentSession.awaitNotNull().users.query(query.await())
    }

    override fun ViewWriter.render() = col {
        launch {
            editUser()?.let  {
                val user = currentSession().users[UUID.parse(it)]
                dialogScreenNavigator.navigate(
                    AdminUserScreenDialog(user(), {
                        editUser.set(null)
                    })
                )
            }
        }
        row {
            expanding - fieldTheme - row {
                icon(Icon.search, "Search")
                expanding - textInput {
                    content bind search
                    hint = "Search by name, email, or phone"
                }
            }
            centered - text("Companies: ")
            fieldTheme - compact - multiselect(
                query = { q ->
                    currentSession().customerAccounts.query(Query(Condition.And(listOfNotNull(
                        Condition.Always(),
                        q.takeUnless { it.isBlank() }?.let { q ->
                            Condition.And(q.split(' ').map { part ->
                                Condition.Or(
                                    listOf(
                                        condition<CustomerAccount> {
                                            it.address.businessName.contains(
                                                part,
                                                true
                                            )
                                        },
                                        condition<CustomerAccount> { it.email.contains(part, true) },
                                        condition<CustomerAccount> { it.phoneNumber.contains(part, true) },
                                    )
                                )
                            })
                        }
                    ))))()
                },
                pull = {
                    currentSession().customerAccounts[it]() ?: CustomerAccount(
                        _id = it,
                        email = "?",
                        phoneNumber = "?",
                        address = Address.EMPTY
                    )
                },
                toString = { it.address.businessName },
                getId = { it._id },
                items = accounts
            )
            centered - row {
                centered - checkbox { checked bind showRemoved }
                centered - text("Show Removed")
            }
            buttonTheme - button {
                centered - row {
                    centered - icon(Icon.add, "")
                    centered - text("Add User")
                }
                onClick {
                    dialogScreenNavigator.navigate(AdminUserScreenDialog(null){
                        editUser.set(null)
                    })
                }
            }
        }
        padded - row {
            weight(2f) - col { text("Email") }
            weight(1f) - col { text("First Name") }
            weight(1f) - col { text("Last Name") }
            weight(1f) - col { text("Phone Number") }
            weight(1f) - col { text("Role") }
            weight(2f) - col { text("Account") }
            weight(1f) - col { text("Last Logged in")}
            weight(0.75f) - col { text("Status") }
            weight(2f) - col { text("Actions") }
        }
        expanding - col {
            expanding - padded - recyclerView {
                reactiveScope {
                    if (lastVisibleIndex() > users().limit - 20)
                        users().limit = lastVisibleIndex() + 100
                }
                val items = shared { users()() }
                children(items) { user ->
                    card - row {
                        weight(2f) - centered - text { ::content { user().email } }
                        weight(1f) - centered - text { ::content { user().firstName } }
                        weight(1f) - centered - text { ::content { user().lastName } }
                        weight(1f) - centered - text { ::content { user().phoneNumber } }
                        weight(1f) - centered - text { ::content { user().role.display } }
                        weight(2f) - centered - button {
                            text {
                                wraps = false
                                ellipsis = true
                                ::content {
                                    user().account?.let {
                                        currentSession().customerAccounts[it]()?.address?.businessName
                                    } ?: "None"
                                }
                            }
                            onClick {
                                user().account?.let {
                                    currentSession().customerAccounts[it]()?.let {
                                        dialogScreenNavigator.navigate(AdminAccountScreenDialog(it))
                                    }
                                }
                            }
                        }
                        weight(1f) - text{
                            ::content {
                                    val user = user()
                                    val userId = user._id
                                println("debug userId ${userId}")
                                    val mostRecentActivity = currentSession().funnelInstance.query(
                                        Query(
                                        condition { it.user.notNull.contains(userId.toString()) and it.funnel.eq(funnelsManager.trackPlatformUsed)},
                                            orderBy = sort {
                                                it.success.notNull.descending()
                                            }
                                    )).invoke().firstOrNull()
                                val userAgent = mostRecentActivity?.userAgent
                                "${mostRecentActivity?.success?.renderToString()?:""} \n ${
                                    when {
                                        userAgent?.contains("Browser") ==true && (userAgent.contains("Android") || userAgent.contains("iOS")) -> "Mobile Web"
                                        userAgent?.contains("Browser") ==true -> "Web"
                                        userAgent?.contains("Android") == true -> "Android"
                                        userAgent?.contains("iOS") == true -> "IOS"
                                        else -> ""
                                    }
                                }"
                            }
                        }
                        weight(0.75f) - centered - text {
                            ::content {
                                val user = user()
                                when {
                                    !user.active -> "Removed"
                                    user.role >= UserRole.Representative -> user.role.display
                                    user.account == null -> "New"
                                    else -> "Active"
                                }
                            }
                        }
                        weight(2f) - row {
                            compact - link {
                                centered - text("Orders")
                                ::to {
                                    val user = user()
                                    user.account?.let { it ->
                                        currentSession().customerAccounts[it]()?.let {
                                            {
                                                AdminOrdersScreen().apply {
                                                    accounts.value = setOf(it._id)
                                                    users.value = setOf(user._id)
                                                }
                                            }
                                        }
                                    } ?: { Screen.Empty }
                                }
                            }

                            compact - button {
                                centered - text("Edit")
                                onClick {
                                    println("navigating")
                                    editUser.set(user()._id.toString())
                                    dialogScreenNavigator.navigate(
                                        AdminUserScreenDialog(user().also {
                                            println("user id: $it") }) {
                                            editUser.set(null)
                                        })
                                }
                            }

                            danger - compact - button {
                                ::exists { user().active }
                                centered - text("Remove")
                                onClick {
                                    confirmDanger("Remove User", "This action will disable the account.") {
                                        currentSession().users[user()._id].modify(
                                            modification<User> {
                                                it.active assign false
                                            }
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


class AdminUserScreenDialog(
    val user: User?,
    val onClose: suspend ()->Unit?
) : Screen {

    val email = Property(user?.email ?: "")
    val firstName = Property(user?.firstName ?: "")
    val lastName = Property(user?.lastName ?: "")
    val phoneNumber = Property(user?.phoneNumber ?: "")
    val account = Property(user?.account)
    val showPricing = Property(user?.showPricing ?: true)
    val showInventory = Property(user?.showInventory ?: true)
    val emailOnNewOrderStatus = Property(user?.emailOnNewOrderStatus ?: true)
    val role = Property(user?.role ?: UserRole.Customer)
    val emailOnNewUsers = Property(user?.emailOnNewUsers ?: false)
    val active = Property(user?.active ?: true)

    override fun ViewWriter.render() {
//        val accounts = shared {
//            currentSession().customerAccounts.query(Query(condition<CustomerAccount> {
//                condition(
//                    true
//                )
//            }))()
//        }
        dismissBackground {
            centered - card - scrolls - col {
                h2("Edit User")
                separator()
                row {
                    weight(1f) - col {
                        label {
                            content = "Email"
                            fieldTheme - compact - textInput {
                                keyboardHints = KeyboardHints.email
                                content bind email
                            }
                        }
                    }
                    weight(1f) - col {
                        label {
                            content = "Phone"
                            fieldTheme - compact - textInput {
                                keyboardHints = KeyboardHints.phone
                                content bind phoneNumber
                            }
                        }
                    }
                }
                row {
                    weight(1f) - col {
                        label {
                            content = "First Name"
                            fieldTheme - compact - textInput {
                                keyboardHints = KeyboardHints.title
                                content bind firstName
                            }
                        }
                    }
                    weight(1f) - col {
                        label {
                            content = "Last Name"
                            fieldTheme - compact - textInput {
                                keyboardHints = KeyboardHints.title
                                content bind lastName
                            }
                        }
                    }
                }
                label {
                    content = "Role"
                    fieldTheme - compact - select {
                        bind(
                            edits = role,
                            data = Constant(UserRole.values().toList()),
                            render = { it.display }
                        )
                    }
                }
                label {
                    content = "Desired Company"
                    text(user?.desiredCompany ?: "N/A")
                    container.exists = user?.account == null
                }
                col {
                    label {
                        content = "Company"
                        fieldTheme - compact - searchSelect(
                            query = { q ->
                                currentSession().customerAccounts.query(Query(Condition.And(listOfNotNull(
                                    Condition.Always(),
                                    q.takeUnless { it.isBlank() }?.let { q ->
                                        Condition.And(q.split(' ').map { part ->
                                            Condition.Or(
                                                listOf(
                                                    condition<CustomerAccount> {
                                                        it.address.businessName.contains(
                                                            part,
                                                            true
                                                        )
                                                    },
                                                    condition<CustomerAccount> { it.email.contains(part, true) },
                                                    condition<CustomerAccount> { it.phoneNumber.contains(part, true) },
                                                )
                                            )
                                        })
                                    }
                                ))))()
                            },
                            pull = {
                                currentSession().customerAccounts[it]() ?: CustomerAccount(
                                    _id = it,
                                    email = "?",
                                    phoneNumber = "?",
                                    address = Address.EMPTY
                                )
                            },
                            toString = { it.address.businessName },
                            getId = { it._id },
                            selected = account
                        )
                    }
                }
//                button {
//                    exists = user != null
//                    centered - text("Reset password")
//                    onClick {
//                        dialogScreenNavigator.navigate(object: Screen {
//                            val pass = Property("")
//                            override fun ViewWriter.render(){
//                                dismissBackground {
//                                    centered - sizeConstraints(width = 40.rem) - card - col {
//                                        h1("Reset Password")
//                                        label {
//                                            content = "New Password"
//                                            fieldTheme - textInput {
//                                                keyboardHints = KeyboardHints.newPassword
//                                                content bind pass
//                                            }
//                                        }
//                                        button {
//                                            text("OK")
//                                            onClick {
//                                                currentSession().nonCached.passwordProof.setOtherPassword(
//                                                    subject = "User",
//                                                    id = user!!._id.toString(),
//                                                    input = EstablishPassword(pass())
//                                                )
//                                                screenNavigator.dismiss()
//                                            }
//                                        }
//                                    }
//                                }
//
//                            }
//                        })
//                    }
//                }
                row {
                    centered - checkbox {
                        checked bind showPricing
                    }
                    centered - text("Show Pricing")
                }
                row {
                    centered - checkbox {
                        checked bind showInventory
                    }
                    centered - text("Show Inventory")
                }
                row {
                    ::exists { account() != null }
                    centered - checkbox {
                        checked bind emailOnNewOrderStatus
                    }
                    centered - text("Send email when order status changes")
                }
                row {
                    ::exists { role() >= UserRole.Representative }
                    centered - checkbox {
                        checked bind emailOnNewUsers
                    }
                    centered - text("Send email when a new user is added")
                }
                row {
                    centered - checkbox {
                        checked bind active
                    }
                    centered - text("Active")
                }
                separator()
                row {
                    button {
                        centered - text("Cancel")
                        onClick {
                            onClose()
                            dialogScreenNavigator.dismiss()
                        }
                    }
                    expanding - space()
                    important - button {
                        centered - text("Save Changes")
                        onClick {
                            user?._id?.let { id ->
                                currentSession().users[user._id].modify(
                                    modification<User> {
                                        it.email assign email()
                                        it.firstName assign firstName()
                                        it.lastName assign lastName()
                                        it.phoneNumber assign phoneNumber()
                                        it.account assign account()
                                        it.showPricing assign showPricing()
                                        it.showInventory assign showInventory()
                                        it.emailOnNewOrderStatus assign emailOnNewOrderStatus()
                                        it.role assign role()
                                        it.emailOnNewUsers assign emailOnNewUsers()
                                        it.active assign active()
                                    }
                                )
                            } ?: run {
                                currentSession().users.insert(
                                    User(
                                        email = email(),
                                        firstName = firstName(),
                                        lastName = lastName(),
                                        phoneNumber = phoneNumber(),
                                        account = account(),
                                        showPricing = showPricing(),
                                        showInventory = showInventory(),
                                        emailOnNewOrderStatus = emailOnNewOrderStatus(),
                                        role = role(),
                                        emailOnNewUsers = emailOnNewUsers(),
                                        active = active(),
                                    )
                                )
                            }
                            onClose()
                            dialogScreenNavigator.dismiss()
                        }
                    }
                }
            }
        }
    }
}