package com.vandenbussche.views.screens.common

import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.reactiveScope
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.stack

interface HasNarrowLayout: HasNarrowContent, Screen {
    fun ViewWriter.renderNarrow(): Unit
    fun ViewWriter.renderWide(): Unit

    override fun ViewWriter.render() {
        stack {
            val split = split()
            
            reactiveScope {
                if (HasNarrowContent.narrow()) {
                    clearChildren()

                    split.renderNarrow()
                }
                else {
                    clearChildren()

                    split.renderWide()
                }
            }
        }
    }
}

interface HasNarrowLayoutDeterminedByAccount: HasNarrowLayout, ContentDeterminedByAccount {
    override fun ViewWriter.hasAccount() {}

    override fun ViewWriter.render() {
        stack {
            val split = split()

            reactiveScope {
                if (userHasAccount()) {
                    if (HasNarrowContent.narrow()) {
                        clearChildren()

                        split.renderNarrow()
                    }
                    else {
                        clearChildren()

                        split.renderWide()
                    }
                }
                else {
                    clearChildren()

                    split.noAccount()
                }
            }
        }
    }
}