package com.vandenbussche.admin

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.dp
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.canvas.TextAlign
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.monitoring.funnel
import com.lightningkite.lightningserver.monitoring.userAgent
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import com.vandenbussche.csv.CsvFormat
import com.vandenbussche.csv.StringDeferringConfig
import com.vandenbussche.funnelsManager
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.views.components.atEndOfDay
import com.vandenbussche.views.components.atStartOfDay
import com.vandenbussche.views.textFormatting.format
import kotlinx.coroutines.*
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString


@Routable("/reports")
class AdminReportsScreen() : Screen {
    override val title: Readable<String> = Constant<String>("Reports")

    @QueryParameter
    val startDate = Property<LocalDate?>(null)

    @QueryParameter
    val endDate = Property<LocalDate?>(null)

    val format = CsvFormat(StringDeferringConfig(ClientModule))
    val liveState = shared { currentSession().syncTaskStatus.watch(Query())() }

    val platformUsageReport = sharedSuspending {
        val total = currentSession().nonCached.funnelInstance.count(condition {
            it.funnel.eq(funnelsManager.trackPlatformUsed)
        }).toFloat()
        val web = currentSession().nonCached.funnelInstance.count(condition {
            it.funnel.eq(funnelsManager.trackPlatformUsed) and it.userAgent.contains("Browser")
        }).toFloat()
        val webMobile = currentSession().nonCached.funnelInstance.count(condition {
            it.funnel.eq(funnelsManager.trackPlatformUsed) and it.userAgent.contains("Browser") and (it.userAgent.contains("Android") or it.userAgent.contains("iOS"))
        }).toFloat()
        val android = currentSession().nonCached.funnelInstance.count(condition {
            it.funnel.eq(funnelsManager.trackPlatformUsed) and it.userAgent.contains("Android") and !it.userAgent.contains("Browser")
        }).toFloat()
        val ios = currentSession().nonCached.funnelInstance.count(condition {
            it.funnel.eq(funnelsManager.trackPlatformUsed) and it.userAgent.contains("iOS") and !it.userAgent.contains("Browser")
        }).toFloat()
            mapOf(
                "Web" to PlatformUsage(
                    percent = (web/total),
                    displayPercentage= ((web /total).times(100)).toInt().toString(),
                    amount =web.toInt()),
                "Web Mobile" to PlatformUsage(
                    percent = (webMobile/total).toFloat(),
                    displayPercentage= ((webMobile /total).times(100)).toInt().toString(),
                    amount =webMobile.toInt()
                ),
                "Android" to PlatformUsage(
                    percent = (android/total).toFloat(),
                    displayPercentage= ((android /total).times(100)).toInt().toString(),
                    amount =android.toInt()),
                "IOS" to PlatformUsage(
                    percent = (ios/total).toFloat(),
                    displayPercentage= ((ios /total).times(100)).toInt().toString(),
                    amount =ios.toInt()),
            ).toList()
    }

    override fun ViewWriter.render() = scrolls - col {
        rowCollapsingToColumn(50.rem) {
            expanding - card - col {
                h3("Order Export")

                text("Date Range")
                row {
                    centered - text("From:")
                    fieldTheme - compact - localDateField { content bind startDate }

                    centered - text("To:")
                    fieldTheme - compact - localDateField { content bind endDate }
                }
                row {
                    button {
                        centered - h6("Export Order Items")

                        onClick {
                            val orders = currentSession().orders.query(
                                Query(
                                    condition<Order> {
                                        Condition.And(
                                            listOfNotNull(
                                                startDate()?.atStartOfDay()?.let { d -> it.orderedAt.gte(d) },
                                                endDate()?.atEndOfDay()?.let { d -> it.orderedAt.lte(d) },
                                            )
                                        )
                                    },
                                    orderBy = sort { it.orderedAt.descending() },
                                    limit = 10_000
                                )
                            )()
                            val allAccounts =
                                currentSession().customerAccounts.query(Query(condition { condition(true) },
                                    limit = 10_000))()

                            val products = currentSession().products.query(Query(condition { condition(true) },
                                limit = 10_000))()

                            format.encodeToString(
                                orders.flatMap { order ->
                                    order.items.map { item ->
                                        ExportOrderItem(
                                            orderId = order.erpId,
                                            status = order.status.code.name,
                                            company = allAccounts.find { it._id == order.account }?.address?.businessName
                                                ?: "",

                                            productNum = products.find { it._id == item.product }?.erpId ?: "",
                                            SKU = products.find { it._id == item.product }?.sku ?: "",
                                            productTitle = products.find { it._id == item.product }?.title ?: "",
                                            description = products.find { it._id == item.product }?.manufacturerDescription
                                                ?: "",
                                            manufacturer = products.find { it._id == item.product }?.manufacturer ?: "",
                                            quantity = item.quantity,
                                            price = item.previousPrice?.toString() ?: "",
                                            unit = (products.find { it._id == item.product }?.quantityType
                                                ?: "").toString(),
                                        )
                                    }
                                }
                            ).toBlob().let { ExternalServices.download("orderItems.csv", it) }
                        }
                    }
                    button {
                        centered - h6("Export Orders")
                        onClick {
                            val orders = currentSession().orders.query(
                                Query(
                                    condition<Order> {
                                        Condition.And(
                                            listOfNotNull(
                                                startDate()?.atStartOfDay()?.let { d -> it.orderedAt.gte(d) },
                                                endDate()?.atEndOfDay()?.let { d -> it.orderedAt.lte(d) },
                                            )
                                        )
                                    },
                                    orderBy = sort { it.orderedAt.descending() },
                                    limit = 10_000
                                )
                            )()
                            val allAccounts =
                                currentSession().customerAccounts.query(Query(condition { condition(true) }))()
                            val shipTo =
                                currentSession().shippingAddresses.query(Query(condition { condition(true) }))()
                            val allWarehouses =
                                currentSession().warehouses.query(Query(condition { condition(true) }))()
                            format.encodeToString(
                                orders.map { order ->
                                    ExportOrder(

                                        date = order.orderedAt.format(),
                                        status = order.status.code.name,
                                        account = allAccounts.find { it._id == order.account }?.address?.businessName
                                            ?: "",
                                        orderId = order.erpId,
                                        warehouse = allWarehouses.find { it._id == order.warehouse }?.name ?: "",
                                        shipTo = shipTo.find { it._id == order.shipTo }?.name ?: "",
//                                        contactErpId = order.contactErpID,
//                                        contactEmail = order.contactEmail ?: "",
                                        shipASAP = order.shipASAP,
                                        fulfillmentExpectation = order.fulfillmentExpectation,
                                        comment = order.comment,
                                        poNum = order.poNum,
                                        total = "",
                                    )
                                }
                            ).toBlob().let { ExternalServices.download("orders.csv", it) }

                        }
                    }
                }

            }
            expanding - card - col {
                h3("User Export")
                button {
                    centered - h6("Export All Users")
                    onClick {
                        val allUsers = currentSession().users.query(
                            Query(
                                condition { condition(true) },
                                limit = 10_000
                            )
                        )()
                        val format = CsvFormat(StringDeferringConfig(ClientModule))
                        val allAccounts =
                            currentSession().customerAccounts.query(
                                Query(
                                    condition { condition(true) },
                                    limit = 10_000
                                )
                            )()

                        format.encodeToString(
                            allUsers.map { user ->
                                val account = allAccounts.find { it._id == user.account }
                                ExportUser(
                                    firstName = user.firstName,
                                    lastName = user.lastName,
                                    active = user.active,
                                    email = user.email,
                                    account = account?.address?.businessName ?: "",
                                    role = user.role,
                                    emailsForNewOrderStatus = user.emailOnNewOrderStatus,
                                    emailsForNewUsers = user.emailOnNewUsers,
                                )
                            }
                        ).toBlob().let { ExternalServices.download("users.csv", it) }

                    }
                }
            }
        }
        card - col {
            h3("Sync ERP")
            rowCollapsingToColumn(50.rem) {
                expanding - col {
                    button {
                        text("Sync Now")
                        onClick {
                            currentSession().nonCached.syncNow()
                        }
                    }

                    col {
                        forEachUpdating(liveState) { task ->
                            row {
                                weight(1f) - text {
                                    ::content { task()._id }
                                }
                                weight(1f) - text {
                                    ::content {
                                        task().startedAt?.format()?.let { "Last run $it" } ?: "Not Started" }
                                }
                                weight(1f) - text {
                                    ::content { task()?.status ?: "-" }
                                }
                                weight(1f) - compact - button {
                                    text("Run Now")
                                    onClick {
                                        currentSession().nonCached.syncNowPartial(task()._id)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        card - col {
            h3("Platform Usage")
            rowCollapsingToColumn(50.rem) {
                forEachUpdating(platformUsageReport) {
                    expanding - col {
                        centered - h4{
                            ::content {
                                it().first
                            }
                        }
                        centered - stack {
                            centered - sizeConstraints(height = 8.rem, width = 7.rem) - circularProgress {
                                ::ratio {
                                    it().second.percent
                                }
                            }
                            centered - stack {
                                expanding - bold - centered - text {
                                    align = Align.Center
                                    ::content {
                                        it().second.displayPercentage + "%"
                                    }
                                }
                            }
                        }
                        centered - text {
                            ::content{
                                "Sessions: ${it().second.amount}"
                            }
                        }
                    }
                }
            }
        }
    }
}

data class PlatformUsage(
    val percent:Float,
    val displayPercentage: String,
    val amount: Int
)

@Serializable
data class ExportUser(
    val firstName: String,
    val lastName: String,

    val email: String,
    val active: Boolean = true,
    val role: UserRole = UserRole.Customer,
    val account: String,
    val emailsForNewOrderStatus: Boolean? = null,
    val emailsForNewUsers: Boolean? = null,

    )

@Serializable
data class ExportOrder(
    val date: String,
    val orderId: String? = null,
    val status: String,
    val account: String,
//    val contactErpId: Double? = null,
//    val contactEmail: String,
    val warehouse: String,
    val shipTo: String,
    val shipASAP: Boolean = false,
    val fulfillmentExpectation: Instant? = null,
    val comment: String,
    val poNum: String,
    val total: String,
)

@Serializable
data class ExportOrderItem(
    val orderId: String?,
    val status: String,
    val company: String,
    val productNum: String,
    val productTitle: String,
    val description: String,
    val manufacturer: String,
    val SKU: String,
    val quantity: Int?,
    val unit: String,
    val price: String,


    )