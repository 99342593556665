package com.vandenbussche.admin

import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.serialization.*
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession


@Routable("/settings")
class AdminAdminLoginsScreen : Screen {

    @QueryParameter("search")
    val search = Property("")

    @QueryParameter("showRemoved")
    val showRemoved = Property(false)

    @QueryParameter("editUser")
    val currentEditingUser = Property<String?>(null)

    override val title: Readable<String> = Constant<String>("Admin Logins")
    val users = shared {
        val session = currentSession()
        session.users.query(
            Query(
                condition {
                    Condition.And(
                        listOfNotNull(
                            condition { it.role inside UserRole.values().filter { it >= UserRole.Representative } },
                            search.debounce(500)().let { s ->
                                if (s.isBlank()) condition(true)
                                else Condition.And(s.split(' ').map { part ->
                                    Condition.Or(
                                        listOfNotNull(
                                            it.firstName.contains(part, true),
                                            it.lastName.contains(part, true),
                                            it.email.contains(part, true),
                                            it.phoneNumber.contains(part, true),
                                        )
                                    )
                                })
                            },
                            if (showRemoved()) null else it.active.neq(false),
                        )
                    )
                },
                limit = 25,
                orderBy = sort {
                    it.account.notNull.descending()
                    it.active.descending()
                    it.lastName.ascending()
                }
            )
        )
    }

    override fun ViewWriter.render() = col {
        row {
            expanding - fieldTheme - row {
                icon(Icon.search, "Search")
                expanding - textInput {
                    content bind search
                    hint = "Search by name, email, or phone"
                }
            }
            centered - row {
                centered - checkbox { checked bind showRemoved }
                centered - text("Show Removed")
            }
            buttonTheme - button {
                centered - row {
                    centered - icon(Icon.add, "")
                    centered - text("Add User")
                }
                onClick {
                    dialogScreenNavigator.navigate(AdminUserScreenDialog(null,{
                        currentEditingUser.set(null)
                    }))
                }
            }
        }
        padded - row {
            weight(2f) - col { text("Email") }
            weight(1f) - col { text("First Name") }
            weight(1f) - col { text("Last Name") }
            weight(1f) - col { text("Phone Number") }
            weight(1f) - col { text("Role") }
            weight(0.5f) - col { text("Notify Signup") }
            weight(0.5f) - col { text("Notify Order") }
            weight(0.5f) - col { text("Active") }
            weight(2f) - col { text("Actions") }
        }
        expanding - recyclerView {
            reactiveScope {
                if (lastVisibleIndex() > users().limit - 20)
                    users().limit = lastVisibleIndex() + 100
            }
            val items = shared { users()() }
            children(items) { user ->
                card - row {
                    weight(2f) - centered - text { ::content { user().email } }
                    weight(1f) - centered - text { ::content { user().firstName } }
                    weight(1f) - centered - text { ::content { user().lastName } }
                    weight(1f) - centered - text { ::content { user().phoneNumber } }
                    weight(1f) - centered - text { ::content { user().role.display } }
                    weight(0.5f) - centered - icon {
                        ::opacity { if (user().emailOnNewUsers) 1.0 else 0.5 }
                        ::source { if (user().emailOnNewUsers) Icon.done else Icon.close }
                    }
                    weight(0.5f) - centered - icon {
                        ::opacity { if (user().emailOnNewOrderStatus) 1.0 else 0.5 }
                        ::source { if (user().emailOnNewOrderStatus) Icon.done else Icon.close }
                    }
                    weight(0.5f) - centered - icon {
                        ::opacity { if (user().active) 1.0 else 0.5 }
                        ::source { if (user().active) Icon.done else Icon.close }
                    }
                    weight(2f) - row {
                        compact - link {
                            centered - text("Orders")
                            ::to {
                                user().account?.let {
                                    currentSession().customerAccounts[it]()?.let {
                                        {
                                            AdminOrdersScreen().apply {
                                                accounts.value = setOf(it._id)
                                            }
                                        }
                                    }
                                } ?: { Screen.Empty }
                            }
                        }

                        compact - button {
                            centered - text("Edit")
                            onClick {
                                dialogScreenNavigator.navigate(
                                    AdminUserScreenDialog(user(),{
                                        currentEditingUser.set(null)
                                    })
                                )
                            }
                        }

                        danger - compact - button {
                            ::exists { user().active }
                            centered - text("Remove")
                            onClick {
                                confirmDanger("Remove User", "This action will disable the account.") {
                                    currentSession().users[user()._id].modify(
                                        modification<User> {
                                            it.active assign false
                                        })
                                }
                            }
                        }
                    }
                }

            }
        }
    }
}