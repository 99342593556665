package com.vandenbussche.admin

import com.ilussobsa.views.multiselect
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.now
import com.lightningkite.serialization.*

import com.vandenbussche.mappings.toLocalDateTime
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.sdk.utils.pushChanges
import com.vandenbussche.setName
import com.vandenbussche.theming.*
import com.vandenbussche.validation.Validator
import com.vandenbussche.views.components.productCard
import com.vandenbussche.views.components.productCardNoLink
import com.vandenbussche.views.emptyView
import com.vandenbussche.views.textFormatting.format
import kotlin.text.contains
import kotlin.time.Duration.Companion.days

@Routable("/admin/price-increases")
class PriceIncreasesScreen : Screen {
    override val title: Readable<String> = Constant("Price Increases")

    private val showPastIncreases = Property(true)

    private val now = now()
    private val priceIncreases = shared {
        currentSession().priceIncreases.query(
            Query(
                condition = if (showPastIncreases()) Condition.Always() else condition { it.increasesAt gt now },
                orderBy = sort { it.increasesAt.ascending() }
            )
        )()
    }
    private val upcoming = shared {
        if (showPastIncreases()) priceIncreases().filter { it.increasesAt > now } else priceIncreases()
    }

    override fun ViewWriter.render() {
        col {
            row {
                expanding - centered - row {
                    centered - switch {
                        checked bind showPastIncreases
                    }
                    centered - text("Show Past Increases")
                }

                buttonTheme - button {
                    row {
                        centered - icon { source = Icon.add }
                        centered - text("New Price Increase")
                    }

                    onClick { mainScreenNavigator.navigate(PriceIncreaseForm(UUID.random())) }
                }
            }

            separator()

            expanding - recyclerView {
                ::exists { priceIncreases().isNotEmpty() }

                children(priceIncreases) { increase ->
                    card - button {
                        dynamicTheme {
                            if (increase().increasesAt <= now) DisabledSemantic else null
                        }

                        col {
                            bold - row {
                                expanding - text { ::content { increase().increasesAt.format() } }
                                text { ::content { "${increase().products.size} products, ${increase().categories.size} categories, ${increase().manufacturers.size} manufacturers" } }
                            }

                            text {
                                ::exists { increase().message.isNotBlank() }
                                ::content { increase().message }
                            }
                        }

                        onClick { mainScreenNavigator.navigate(PriceIncreaseForm(increase()._id)) }
                    }
                }
            }

            expanding - emptyView {
                exists = false
                ::exists { upcoming().isEmpty() }

                centered - h3("Nothing Scheduled")
            }
        }
    }


}

@Routable("/admin/price-increases/{id}")
class PriceIncreaseForm(val id: UUID) : Screen, Validator() {
    val existing = shared { currentSession().priceIncreases[id]() }
    val starting = shared {
        existing() ?: PriceIncrease(
            increasesAt = now() + 1.days,
            message = "",
            products = emptySet()
        )
    }
    private val draft = Draft { starting() }

    private val products = draft.lensPath { it.products }
    private val selectedProducts = shared {
        val ids = products()
        currentSession().products.query(
            Query { it._id inside ids }
        )
    }

    private val allowedFields = Product.path.run {
        setOf(
            title.setName("Title"),
            erpId.setName("Product #"),
            manufacturer.setName("Manufacturer"),
            manufacturerDescription.setName("Description")
        )
    }
    private val searchFields = Property(allowedFields)

    private val query = Property("")
    private val debounced = query.debounce(600)
    val allCategories =
        shared { currentSession().productCategories.query(Query(condition { condition(true) }, limit = 1000))() }
    val allManufacturer =
        shared { currentSession().manufacturers.query(Query(condition { condition(true) }, limit = 1000))() }

    val now = now()
    private val otherFutureIncreases = shared {
        currentSession().priceIncreases.query(
            Query(
                condition = condition { it.increasesAt.gt(now) and it._id.neq(id) },
                orderBy = sort { it.increasesAt.ascending() }
            )
        )()
    }
    private val searched = shared {
        val q = debounced()
        currentSession().products.query(
            Query(
                Condition.Or(
                    searchFields().map { it.mapCondition(Condition.StringContains(q, true)) }
                ),
                orderBy = sort { it.title.ascending() },
                limit = 200,
            )
        )
    }

    fun ViewWriter.icon(icon: Icon) {
        icon { source = icon }
    }

    override fun ViewWriter.render() {
        expanding - col {
            reactive { draft() }

            field("When"){
                localDateTimeField {
                    content bind draft.lensPath { it.increasesAt }.toLocalDateTime().nullable()
                }
            }

            field("Message"){
                textArea {
                    content bind draft.lensPath { it.message }
                }
            }

            expanding - row {
                expanding - scrolls - col {
                    spacing = 1.5.rem

                    field("Manufacturers") {
                        multiselect<Manufacturer, String>(
                            query = { input ->
                                input.takeUnless { it.isBlank() }?.let { s ->
                                    allManufacturer().filter { it._id.contains(s, true) }
                                } ?: allManufacturer()
                            },
                            pull = { Manufacturer(it) },
                            getId = { it._id },
                            toString = { it._id },
                            items = draft.lensPath { it.manufacturers },
                        )
                    }
                    field("Categories") {
                        multiselect<ProductCategory, UUID>(
                            query = { input ->
                                input.takeUnless { it.isBlank() }?.let { s ->
                                    allCategories().filter { it.path.contains(s, true) }
                                } ?: allCategories()
                            },
                            pull = {
                                currentSession().productCategories[it]() ?: ProductCategory.EMPTY
                            },
                            getId = { it._id },
                            toString = { it.path },
                            items = draft.lensPath { it.categories },
                        )
                    }
                    field("Individual Products") {
                        multiselect<Product, UUID>(
                            query = { search ->
                                val c = condition<Product> {
                                    Condition.And(
                                        listOfNotNull(
                                            search.let { s ->
                                                if (s.isBlank()) condition(true)
                                                else Condition.And(s.split(' ').map { part ->
                                                    Condition.Or(
                                                        listOfNotNull(
                                                            it.title.contains(part, true),
                                                            it.erpId.contains(part, true),
                                                            it.manufacturer.contains(part, true),
                                                            it.sku.contains(part, true),
                                                        )
                                                    )
                                                })
                                            }, //                        categories?
                                        )
                                    )
                                }
                                currentSession().products.query(Query(c, limit = 100))()
                            },
                            pull = {
                                currentSession().products[it]() ?: Product.EMPTY
                            },
                            getId = { it._id },
                            toString = { it.title },
                            render = { productCardNoLink(it, 5.rem) },
                            items = draft.lensPath { it.products },
                        )
                    }
                    field("Exclude Manufacturers") {
                        multiselect<Manufacturer, String>(
                            query = { input ->
                                input.takeUnless { it.isBlank() }?.let { s ->
                                    allManufacturer().filter { it._id.contains(s, true) }
                                } ?: allManufacturer()
                            },
                            pull = { Manufacturer(it) },
                            getId = { it._id },
                            toString = { it._id },
                            items = draft.lensPath { it.excludeManufacturers },
                        )
                    }
                    field("Exclude Categories") {
                        multiselect<ProductCategory, UUID>(
                            query = { input ->
                                input.takeUnless { it.isBlank() }?.let { s ->
                                    allCategories().filter { it.path.contains(s, true) }
                                } ?: allCategories()
                            },
                            pull = {
                                currentSession().productCategories[it]() ?: ProductCategory.EMPTY
                            },
                            getId = { it._id },
                            toString = { it.path },
                            items = draft.lensPath { it.excludeCategories },
                        )
                    }
                    field("Exclude Products") {
                        val categories = draft.lensPath { it.categories }
                        val manufacturers = draft.lensPath { it.manufacturers }
                        val excludeCategories = draft.lensPath { it.excludeCategories }
                        val excludeManufacturers = draft.lensPath { it.excludeManufacturers }
                        multiselect<Product, UUID>(
                            query = { search ->
                                val c = condition<Product> {
                                    Condition.And(
                                        listOfNotNull(
                                            search.let { s ->
                                                if (s.isBlank()) condition(true)
                                                else Condition.And(s.split(' ').map { part ->
                                                    Condition.Or(
                                                        listOfNotNull(
                                                            it.title.contains(part, true),
                                                            it.erpId.contains(part, true),
                                                            it.manufacturer.contains(part, true),
                                                            it.sku.contains(part, true),
                                                        )
                                                    )
                                                })
                                            }, //                        categories?
                                        ) + listOf(
                                            Condition.Or(
                                                listOf(
                                                    it.categories.any { it inside categories() },
                                                    it.manufacturer inside manufacturers(),
                                                )
                                            ),
                                            it.categories.all { it notInside excludeCategories() },
                                            it.manufacturer notInside excludeManufacturers(),
                                        ),
                                    )
                                }
                                currentSession().products.query(Query(c, limit = 100))()
                            },
                            pull = {
                                currentSession().products[it]() ?: Product.EMPTY
                            },
                            getId = { it._id },
                            toString = { it.title },
                            render = { productCardNoLink(it, 5.rem) },
                            items = draft.lensPath { it.excludeProducts },
                        )
                    }
                }
                expanding - card - col {
                    h3("Items affected")
                    expanding - recyclerView {
                        val filter = shared { draft().condition }
                        val products = shared { currentSession.awaitNotNull().products.query(Query(filter())) }
                        reactiveScope {
                            if (lastVisibleIndex() > products().limit - 20)
                                products().limit = lastVisibleIndex() + 100
                        }
                        val items = shared { products()() }
                        children(items) {
                            productCard(it, 5.rem)
                        }
                    }
                }
            }

            row {
                expanding - row {
                    onlyWhen { existing() != null } - sizeConstraints(width = 10.rem) - danger - button {
                        spacing = 0.5.rem

                        centered - row {
                            icon(Icon.delete, "Remove Flag")
                            centered - text("Delete")
                        }

                        onClick {
                            confirmDanger(
                                "Remove Price Increase",
                                "Are you sure you want to remove this price increase? This cannot be undone.",
                                "Confirm"
                            ) {
                                currentSession().priceIncreases[this@PriceIncreaseForm.id].delete()
                                dialogScreenNavigator.clear()
                            }
                        }
                    }
                }

                onlyWhen { existing() != null } - important - button {
                    ::enabled { draft.changesMade() }
                    icon(Icon.close, "Discard Changes")
                    onClick { draft.cancel() }
                }

                sizeConstraints(width = 8.5.rem) - important - button {
                    ::enabled { allValid() and draft.changesMade() }

                    centered - row {
                        icon {
                            ::source { if (existing() == null) Icon.send else Icon.save }
                        }
                        centered - text {
                            ::content { if (existing() == null) "Publish" else "Save" }
                        }
                    }

                    onClick {
                        draft.pushChanges { currentSession().priceIncreases }
                        screenNavigator.dismiss()
                    }
                }
            }
        }
    }

}