package com.vandenbussche.models

import kotlin.jvm.JvmInline
import kotlin.math.ceil
import kotlinx.serialization.Serializable

@JvmInline
@Serializable
value class PriceInCents(val cents: Int): Comparable<PriceInCents> {
    val inWholeDollars: Int get() = cents/100
    val change: Int get() = cents%100

    override fun toString(): String {
        // Returns in the format $1,234,567.89
        fun Int.toCommaDelimitedString(): String {
            val str = this.toString()
            return if (str.length <= 3) str
            else (this/1000).toCommaDelimitedString() + ',' + str.takeLast(3)
        }

        return '$' + inWholeDollars.toCommaDelimitedString() + '.' + change.toString().padStart(2, '0')
    }

    operator fun plus(other: PriceInCents): PriceInCents = PriceInCents(this.cents + other.cents)
    operator fun minus(other: PriceInCents): PriceInCents = PriceInCents(this.cents - other.cents)

    operator fun times(other: Int): PriceInCents = PriceInCents(cents * other)
    operator fun times(other: Double): PriceInCents = PriceInCents( ceil(cents * other).toInt() )

    operator fun div(other: Double): PriceInCents = PriceInCents((cents / other).toInt())
    operator fun div(other: Int): PriceInCents = PriceInCents(cents/other)

    override fun compareTo(other: PriceInCents): Int = cents.compareTo(other.cents)
}

// These are only here because the erp reports prices as doubles, please don't use them
val Double.dollars: PriceInCents get() = PriceInCents((this * 100).toInt())
val Double.cents: PriceInCents get() = PriceInCents(toInt()) // especially don't use this one because the erp reports prices in dollars

val Int.dollars: PriceInCents get() = PriceInCents(this * 100)
val Int.cents: PriceInCents get() = PriceInCents(this)

operator fun Double.times(other: PriceInCents): PriceInCents = other * this
operator fun Int.times(other: PriceInCents): PriceInCents = other * this

fun PriceInCents?.isNullOrZero(): Boolean = (this == null || this == 0.cents)