package com.vandenbussche.sdk.utils

import com.lightningkite.kiteui.reactive.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import kotlinx.coroutines.launch

fun ReactiveContext.activeWarehouses(): Readable<List<Warehouse>> {
    val session = currentSession()
    return session.warehouses.query(
        Query(
            condition { condition { (it.public eq true) or (it.permittedAccounts.any { it eqNn session.accountId }) } },
            orderBy = sort { it.public.ascending(); it.name.ascending() }
        )
    )
}

val preferredWarehouse by lazy { shared {
    val session = currentSession()
    fun default() = activeWarehouses().once().firstOrNull() ?: throw NoSuchElementException("There are no active warehouses")

    if (session.accountId == null) return@shared default()

    val account = session.customerAccounts[session.accountId]
    val preferredPickup = account.once()?.preferredPickupLocation
        ?: return@shared default()

    session.warehouses[preferredPickup]().let { preferred ->
        if (preferred == null) return@shared default()

        if (preferred._id !in activeWarehouses()().map { it._id }) {
            launch { account.modify { it.preferredPickupLocation assign null } }
            return@shared default()
        }

        return@shared preferred
    }
} }