package com.vandenbussche.views.screens.auth

import com.lightningkite.kiteui.Platform
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.current
import com.lightningkite.kiteui.forms.AuthComponent
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningserver.auth.AuthClientEndpoints
import com.lightningkite.lightningserver.auth.proof.IdentificationAndPassword
import com.lightningkite.lightningserver.auth.subject.LogInRequest
import com.vandenbussche.Resources
import com.vandenbussche.models.guestEmail
import com.vandenbussche.models.guestPassword
import com.vandenbussche.sdk.AbstractAnonymousSession
import com.vandenbussche.sdk.ApiOption
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.sdk.selectedApi
import com.vandenbussche.sdk.sessionToken
import com.vandenbussche.theming.emphasized
import com.vandenbussche.views.screens.products.CatalogScreen
import kotlinx.coroutines.delay

sealed interface AuthScreen : Screen

@Routable("/auth/login")
class LoginScreen : AuthScreen {
    val permitPassword = Property(false)
    var prepopulateEmail = ""
    val authComponent = shared {
        val api = selectedApi().api
        val unauth = object : AbstractAnonymousSession(api) {}
        AuthComponent(
            AuthClientEndpoints(
                subjects = mapOf(
                    "User" to unauth.userAuth
                ),
                authenticatedSubjects = mapOf(),
                emailProof = unauth.emailProof,
                passwordProof = if(permitPassword()) unauth.passwordProof else null
            ),
            "user",
            unauth.userAuth
        ) { token ->
            println("Got token $token, setting to sessionToken")
            sessionToken set token
        }.apply {
            primaryIdentifier.value = prepopulateEmail
        }
    }
    val email = Property("")
    private val unregistered = Property<String?>(null)

    override fun ViewWriter.render() {
        stack {
            reactive {
                if(currentSession.invoke().also { println("Current session is now $it") } != null) mainScreenNavigator.reset(CatalogScreen)
            }
            reactiveSuspending {
                val appStoreTesterUser = "trax+appstoretester@lightningkite.com"
                if(authComponent().primaryIdentifier() == appStoreTesterUser) {
                    prepopulateEmail = appStoreTesterUser
                    delay(100)
                    permitPassword.value = true
                }
            }
            atTopEnd - button {
                centered - h6 {
                    // change to hide prod when we get a prod
                    ::exists {selectedApi() != ApiOption.Prod}
                    ::content { selectedApi().apiName } }

                onClick {
                    selectedApi.value = selectedApi.awaitOnce().next()
                }
            }

            centered - padded - card - sizeConstraints(width = 28.rem) - col {
                spacing = 1.5.rem

                sizeConstraints(height = 10.rem, width = 30.rem) -  centered - image {
                    source = Resources.imagesLogoFull
                }

               stack {
                    reactive {
                        clearChildren()
                        with(this@LoginScreen.authComponent()) {
                            render()
                        }
                    }
                }

                row {
                    spacing = 0.5.rem
                    centered - h6("Don't have an account? ")

                    centered - emphasized - link {
                        spacing = 0.5.rem
                        to = { UserRegistration().RegistrationScreen() }
                        centered - h6("Register Here")
                    }
                }

                // adding this because apple requires users to be able to view the products without logging in
                button {
                    ::exists {
                       Platform.current == Platform.iOS
                    }
                    centered - text("Skip, do not log in")
                    onClick {
                        confirmDanger("Are you sure you want to skip log in or registration?","Without an account you will have limited access to features without an account. \n You can view the products but cannot make any purchases without an account.") {
                            val unauth = object : AbstractAnonymousSession(selectedApi.value.api) {}
                            val proof = unauth.passwordProof.provePasswordOwnership(IdentificationAndPassword(
                                type = "User",
                                property = "email",
                                value = guestEmail,
                                password = guestPassword
                            ))
                            val result = unauth.userAuth.logInV2(LogInRequest(
                                proofs = listOf(proof),
                            ))
                            result.session?.let {
                                sessionToken.set(it)
                            }
                        }
                    }
                }
                externalLink {
                    centered  - subtext("Privacy Policy")
                    to = "https://vandenbussche.com/privacy-terms-of-use"
                }
            }
        }
    }
}