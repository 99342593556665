package com.vandenbussche.views.screens.account

import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.LsErrorException
import com.lightningkite.lightningserver.db.WritableModel
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import com.vandenbussche.Regexes

import com.vandenbussche.mappings.draftOf
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.sdk.sessionToken
import com.vandenbussche.sdk.utils.modify
import com.vandenbussche.sdk.utils.notFoundError
import com.vandenbussche.theming.*
import com.vandenbussche.tooltip
import com.vandenbussche.validation.Validator
import com.vandenbussche.validation.validate
import com.vandenbussche.validation.validating
import com.vandenbussche.views.components.detail
import com.vandenbussche.views.screens.auth.LoginScreen
import com.vandenbussche.views.screens.common.ContentDeterminedByAccount
import com.vandenbussche.views.screens.common.userIsAdmin
import com.vandenbussche.views.textFormatting.formatName
import kotlinx.serialization.KSerializer

@Routable("/Account")
class AccountInfoScreen: ContentDeterminedByAccount {
    override val noAccountMessage = "You don't have an account"

    // This is a patch-fix to attach error data to a Draft. It's used to verify unique information and show an error if not unique
    private class ErrorHandlingDraft<T>(
        private val draft: Draft<T>,
        val rootPath: DataClassPath<T, T>,
        val errors: Property<Map<String, Error>> = Property(emptyMap())
    ): Writable<T> by draft {
        data class Error(val data: String, val message: String)

        val published = draft.published
        val changesMade = draft.changesMade
        suspend fun publish() = draft.publish()
        fun cancel() {
            errors.value = emptyMap()
            draft.cancel()
        }
    }
    private inline fun <reified T> Draft<T>.errorHandling() = ErrorHandlingDraft(this, path())
    private inline fun <reified O: Any, T> ErrorHandlingDraft<O?>.lens(getPath: (DataClassPath<O?, O>) -> DataClassPath<O?, T>): Writable<T> {
        val path = getPath(rootPath.notNull)
        return lens<O?, T>(
            get = {
                @Suppress("UNCHECKED_CAST")
                path.get(it) as T
            },
            modify = { o, t ->
                o?.let { path.set(it, t) }
            }
        )
    }
    private fun <T> ViewWriter.errorHandlingTextField(
        draft: ErrorHandlingDraft<T>,
        key: String,
        setup: TextField.() -> Unit
    ) {
        val error = shared { draft.errors()[key] }
        padded - textInput {
            setup()
            dynamicTheme { error()?.let { if (it.data == content()) InvalidSemantic else null } }
        }

        onlyWhen { error() != null } - InvalidMessage.onNext - text {
            ::content { error()?.message ?: "" }
        }
    }
    private suspend fun <T> handleErrors(draft: ErrorHandlingDraft<T>, block: suspend () -> Unit) {
        try {
            block()
            draft.errors.value = emptyMap()
        } catch (e: LsErrorException) {
            if (e.status == 400.toShort() && e.error.detail == "unique") {
                val key = e.message?.let { Regexes.isolateUniqueKey(it) } ?: throw e

                val published = draft.published()
                val error: ErrorHandlingDraft.Error = when(key) {
                    "email" -> (published as HasEmail).let {
                        ErrorHandlingDraft.Error(it.email, "This email already exists")
                    }
                    "phoneNumber" -> (published as HasPhoneNumber).let {
                        ErrorHandlingDraft.Error(it.phoneNumber, "This phone number already exists")
                    }
                    else -> null
                } ?: throw e

                draft.errors.value = mapOf(key to error)
            }
        }
    }

    private val account = draftOf {
        val session = currentSession()
        session.accountId?.let { session.customerAccounts[it] }
            ?: object : WritableModel<CustomerAccount> {  // If null return a do-nothing object
                override val serializer: KSerializer<CustomerAccount> get() = CustomerAccount.path.serializer
                override val state: ReadableState<CustomerAccount?> get() = ReadableState.notReady

                override fun addListener(listener: () -> Unit): () -> Unit = {}
                override suspend fun delete() {}
                override fun invalidate() {}
                override suspend fun set(value: CustomerAccount?) {}
                override suspend fun modify(modification: Modification<CustomerAccount>): CustomerAccount? = null
            }
    }.errorHandling()
    private val self = draftOf { currentSession().editSelf }.errorHandling()

    val users = shared {
        val accountID = account.published()?._id ?: return@shared emptyList()
        currentSession().users.query(
            Query(
                condition { (it.account eq accountID) and (it.active eq true) },
                orderBy = sort {
                    it.account.notNull.ascending()
                    it.active.descending()
                    it.lastName.ascending()
                },
            )
        )()
    }

    private fun UserRole.format(): String = display

    private fun <T> ViewWriter.saveChanges(draft: ErrorHandlingDraft<T>, validator: Validator, enabled: ReactiveContext.() -> Boolean = { true }) {
        row {
            important - button {
                ::enabled { enabled() }

                icon(Icon.close, "Discard Changes")
                onClick { draft.cancel() }
            } in tooltip("Discard Changes")

            important - button {
                ::enabled { validator.allValid() and enabled() }

                row {
                    icon { source = Icon.save }
                    centered - h6("Save Changes")
                }

                onClick {
                    handleErrors(draft) { draft.publish() }
                }
            }
        }
    }

    private fun ViewWriter.userSettings(user: ErrorHandlingDraft<User?>, validator: Validator) = with(validator) {
        col {
            spacing = 1.5.rem

            label {
                content = "Name"

                val first = user.lens { it.firstName }.validate { it.isNotBlank() }
                val last = user.lens { it.lastName }.validate { it.isNotBlank() }

                row {
                    expanding - fieldTheme - validate(first) - textInput {
                        hint = "First"
                        content bind first
                    }

                    expanding - fieldTheme - validate(last) - textInput {
                        hint = "Last"
                        content bind last
                    }
                }
            }

            label {
                content = "Email"

                val email = user.lens { it.email }.validate { it.matches(Regexes.email) }
                fieldTheme - validate(email) - textInput {
                    hint = "example@email.com"
                    keyboardHints = KeyboardHints.email
                    content bind email
                }
            }

            label {
                content = "Phone Number"

                val phoneNumber = user.lens { it.phoneNumber }.validate { it.matches(Regexes.phoneNumber) }
                fieldTheme - validate(phoneNumber) - errorHandlingTextField(user, "phoneNumber") {
                    hint = "123-456-7890"
                    keyboardHints = KeyboardHints.phone
                    content bind phoneNumber
                }
            }


//            detail("Email") { user.published()?.email ?: "" }
        }
    }

    private fun ViewWriter.roleSelect(draft: ErrorHandlingDraft<User?>, setup: RView.() -> Unit = {}) {
        label {
            container.setup()
            content = "Role"

            val equalOrLessAuthority = shared {
                val role = self.published()?.role ?: UserRole.Customer
                UserRole.entries.filter { it <= role && it < UserRole.Representative }
            }

            lightOutline - col {
                val changingRoles = shared { (draft()?.role ?: UserRole.Customer).compareTo(draft.published()?.role ?: UserRole.Customer) }

                dynamicTheme {
                    if (changingRoles() != 0) WarningSemantic
                    else null
                }

                fieldTheme - select {
                    bind(
                        edits = draft.lens { it.role },
                        data = equalOrLessAuthority,
                        render = { it.format() }
                    )
                }
                subtext {
                    ::content {
                        when (draft()?.role) {
                            UserRole.AccountReadOnly -> "Users can only view the account."
                            UserRole.Customer -> "Users can modify favorites, preferred locations, carts for the account. They can also submit purchases."
                            UserRole.AccountAdmin -> "Account Admins can edit all account information, purchase items, and can manage and edit users of the account."
                            UserRole.Representative -> "VB Representatives edit all site information, and can view the information of all customer accounts. They can also edit non-personal account information for customers."
                            UserRole.Admin -> "Site Admins edit all site information, and can view the information of all customer accounts. They can also edit non-personal account information for customers."
                            UserRole.Root -> "The app is your oyster, but beware, with great power comes great responsibility."
                            null -> "You don't exist."
                        }
                    }
                }
                bold - text {
                    ::exists { changingRoles() != 0 }
                    ::content {
                        val changing = changingRoles()
                        if (changing < 0) "Demoting a user can't be undone, except by another user with higher authority. Make sure you want to do this."
                        else if (changing > 0) "Promoting a user can only be undone by a user with higher authority. Make sure you want to do this."
                        else ""
                    }
                }
            }
        }
    }

    private fun ViewWriter.selfSettings() = validating {
        card - col {
            spacing = 1.5.rem
            h2("My Details")

//            text { ::content { self()?.name ?: "" } }
            rowCollapsingToColumn(60.rem) {
                expanding - row {
                    checkboxTheme - switch {
                        checked bind self.lens { it.showPricing }
                    }

                    centered - text("Show Pricing")
                }
                space()
                expanding - row {
                    checkboxTheme - switch {
                        checked bind self.lens { it.showInventory }
                    }

                    centered - text("Show Inventory")
                }
            }
            userSettings(self, this@validating)
            atEnd - onlyWhen { self.changesMade() } - saveChanges(self, this@validating)
            danger - button {
                centered - text("Delete User")
                onClick {
                    confirmDanger("Delete User", "Are you sure you want to delete your user? This action cannot be undone") {
                        self.await()?._id?.let {
                            currentSession().nonCached.user.delete(it)
                        }
                        sessionToken.set(null)
                        screenNavigator.navigate(LoginScreen())
                    }
                }
            }
        }
    }


    override fun ViewWriter.noAccount() {
        col {
            centered - selfSettings()
        }
    }

    override fun ViewWriter.hasAccount() {
        scrolls - col {
            spacing = 1.5.rem

            selfSettings()

            card - col {
                spacing = 1.5.rem

                h2("Account Details")

                detail("Account ID:") { account()?.erpId?.toString() ?: "No Account ID" }

                detail("Preferred Shipping Location:") {
                    val preferred = account()?.preferredShippingAddress?.let {
                        currentSession().shippingAddresses[it]()
                    } ?: return@detail "None"
                    preferred.name ?: preferred.address.businessName
                }

                detail("Preferred Pickup Location:") {
                    val preferred = account()?.preferredPickupLocation?.let {
                        currentSession().warehouses[it]()
                    } ?: return@detail "None"
                    preferred.formatName()
                }
                detail("Account Email:") { account()?.email ?: "" }
                detail("Phone Number:") { account()?.phoneNumber ?: "" }
                detail("Address:") { account()?.address?.toString() ?: "" }
            }

            card - col {
                spacing = 1.5.rem
                ::exists { userIsAdmin() }
                h2("Admin")

                col {
                    row {
                        centered - expanding - h3("Users")

                        buttonTheme - button {
                            spacing = 0.25.rem

                            centered - row {
                                spacing = 0.5.rem
                                icon { source = Icon.add }
                                sizeConstraints(width = 5.rem) - centered - text("Add User")
                            }

                            onClick {
                                dialogScreenNavigator.navigate(NewUserDialog())
                            }
                        }
                    }

                    col {
                        forEachUpdating(users) { user ->
                            lightOutline - row {
                                spacing = 0.3.rem
                                padded - centered - expanding - rowCollapsingToColumn(40.rem) {
                                    bold - text {
                                        ::content {
                                            user().let {
                                                if (it._id == self()?._id) "${it.name} (Me)" else it.name
                                            }
                                        }
                                    }
                                    centered - italic - text {
                                        ::exists { user().role >= UserRole.AccountAdmin }
                                        ::content { user().role.format() }
                                    }
                                }

                                sizeConstraints(width = 3.rem) - button {
                                    spacing = 0.rem
                                    ::exists { user().role < (self.published()?.role ?: UserRole.Customer) }
                                    centered - emphasized - icon(Icon.edit, "Edit User")

                                    onClick {
                                        dialogScreenNavigator.navigate(EditUserDialog(user()._id))
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private inner class NewUserDialog : Screen {
        val draft = Draft<User?> {
            User(
                firstName = "",
                lastName = "",
                email = "",
                phoneNumber = "",
                account = currentSession().accountId,
            )
        }.errorHandling()

        override fun ViewWriter.render() = validating {
            dismissBackground {
                spacing = 2.rem

                sizeConstraints(width = 40.rem) - centered - card - col {
                    spacing = 1.5.rem
                    row {
                        expanding - centered - h2("New User")

                        button {
                            spacing = 0.2.rem
                            centered - icon(Icon.close, "Close dialog")
                            onClick { dialogScreenNavigator.dismiss() }
                        }
                    }

                    userSettings(draft, this@validating)

                    roleSelect(draft)

                    important - button {
                        ::enabled { allValid() }

                        centered - row {
                            icon { source = Icon.add }
                            centered - text("Add User")
                        }

                        onClick {
                            handleErrors(draft) {
                                val session = currentSession()

                                session.users.insert(draft.publish()!!)
                                dialogScreenNavigator.dismiss()
                            }
                        }
                    }
                }
            }
        }
    }

    private inner class EditUserDialog(user: UUID): Screen {
        val draft = draftOf { currentSession().users[user] }.errorHandling()

        override fun ViewWriter.render() = validating {
            dismissBackground {
                spacing = 2.rem

                sizeConstraints(width = 40.rem) - centered - card - col {
                    spacing = 1.5.rem
                    row {
                        expanding - centered - h2 { ::content { "Details for ${draft()?.name}" } }

                        button {
                            spacing = 0.2.rem
                            centered - icon(Icon.close, "Close dialog")
                            onClick { dialogScreenNavigator.dismiss() }
                        }
                    }

                    userSettings(draft, this@validating)

                    roleSelect(draft)

                    row {
                        danger - button {
                            centered - text("Remove User")

                            onClick {
                                confirmDanger(
                                    title = "Remove User",
                                    body = "Removing this user strips their ability to purchase and act on behalf of this account.",
                                    actionName = "Confirm",
                                    action = {
                                        val id = draft.published()?._id ?: notFoundError<User>()
                                        currentSession().users[id].modify {
                                            it.active assign false
                                        }
                                        dialogScreenNavigator.dismiss()
                                    }
                                )
                            }
                        }

                        expanding - space()

                        important - button {
                            ::enabled { allValid() and draft.changesMade() }

                            row {
                                icon { source = Icon.save }
                                centered - text("Save Changes")
                            }

                            onClick {
                                handleErrors(draft) {
                                    draft.publish()
                                    dialogScreenNavigator.dismiss()
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}

// Useful for testing users
//                            important - button {
//                                spacing = 0.5.rem
//                                centered - icon(Icon.add, "Add new")
//
//                                onClick {
//                                    val fake = User(
//                                        firstName = "Fake",
//                                        lastName = "User",
//                                        email = "fake@email.com",
//                                        role = UserRole.PendingCustomer
//                                    )
//                                    val users = currentSession().users
//
//                                    selectedTab().let {
//                                        if (it === pendingTab) {
//                                            users.insert(
//                                                fake.copy(
//                                                    account = account()._id
//                                                )
//                                            )
//                                        } else if (it === usersTab) {
//                                            users.insert(
//                                                fake.copy(
//                                                    role = UserRole.Customer,
//                                                    account = account()._id
//                                                )
//                                            )
//                                        } else if (it === deniedUsersTab) {
//                                            users.insert(fake)
//                                            val denied = account.map { it.deniedUsers }
//                                            denied.set(denied() + fake._id)
//                                            account.publish()
//                                        }
//                                    }
//                                }
//                            }