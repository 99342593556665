// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.vandenbussche.models

import com.lightningkite.lightningdb.*
import com.lightningkite.UUID
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun preparePriceIncreaseFields() {
    val props: Array<SerializableProperty<PriceIncrease, *>> = arrayOf(PriceIncrease__id, PriceIncrease_increasesAt, PriceIncrease_message, PriceIncrease_products, PriceIncrease_excludeProducts, PriceIncrease_categories, PriceIncrease_excludeCategories, PriceIncrease_manufacturers, PriceIncrease_excludeManufacturers)
    PriceIncrease.serializer().properties { props }
}
val <K> DataClassPath<K, PriceIncrease>._id: DataClassPath<K, UUID> get() = this[PriceIncrease__id]
val <K> DataClassPath<K, PriceIncrease>.increasesAt: DataClassPath<K, Instant> get() = this[PriceIncrease_increasesAt]
val <K> DataClassPath<K, PriceIncrease>.message: DataClassPath<K, String> get() = this[PriceIncrease_message]
val <K> DataClassPath<K, PriceIncrease>.products: DataClassPath<K, Set<UUID>> get() = this[PriceIncrease_products]
val <K> DataClassPath<K, PriceIncrease>.excludeProducts: DataClassPath<K, Set<UUID>> get() = this[PriceIncrease_excludeProducts]
val <K> DataClassPath<K, PriceIncrease>.categories: DataClassPath<K, Set<UUID>> get() = this[PriceIncrease_categories]
val <K> DataClassPath<K, PriceIncrease>.excludeCategories: DataClassPath<K, Set<UUID>> get() = this[PriceIncrease_excludeCategories]
val <K> DataClassPath<K, PriceIncrease>.manufacturers: DataClassPath<K, Set<String>> get() = this[PriceIncrease_manufacturers]
val <K> DataClassPath<K, PriceIncrease>.excludeManufacturers: DataClassPath<K, Set<String>> get() = this[PriceIncrease_excludeManufacturers]
inline val PriceIncrease.Companion.path: DataClassPath<PriceIncrease, PriceIncrease> get() = path<PriceIncrease>()


object PriceIncrease__id: SerializableProperty<PriceIncrease, UUID> {
    override val name: String by lazy { PriceIncrease.serializer().descriptor.getElementName(0) }
    override fun get(receiver: PriceIncrease): UUID = receiver._id
    override fun setCopy(receiver: PriceIncrease, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (PriceIncrease.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { PriceIncrease.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object PriceIncrease_increasesAt: SerializableProperty<PriceIncrease, Instant> {
    override val name: String by lazy { PriceIncrease.serializer().descriptor.getElementName(1) }
    override fun get(receiver: PriceIncrease): Instant = receiver.increasesAt
    override fun setCopy(receiver: PriceIncrease, value: Instant) = receiver.copy(increasesAt = value)
    override val serializer: KSerializer<Instant> by lazy { (PriceIncrease.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { PriceIncrease.serializer().descriptor.getElementAnnotations(1) }
}
object PriceIncrease_message: SerializableProperty<PriceIncrease, String> {
    override val name: String by lazy { PriceIncrease.serializer().descriptor.getElementName(2) }
    override fun get(receiver: PriceIncrease): String = receiver.message
    override fun setCopy(receiver: PriceIncrease, value: String) = receiver.copy(message = value)
    override val serializer: KSerializer<String> by lazy { (PriceIncrease.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { PriceIncrease.serializer().descriptor.getElementAnnotations(2) }
}
object PriceIncrease_products: SerializableProperty<PriceIncrease, Set<UUID>> {
    override val name: String by lazy { PriceIncrease.serializer().descriptor.getElementName(3) }
    override fun get(receiver: PriceIncrease): Set<UUID> = receiver.products
    override fun setCopy(receiver: PriceIncrease, value: Set<UUID>) = receiver.copy(products = value)
    override val serializer: KSerializer<Set<UUID>> by lazy { (PriceIncrease.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<Set<UUID>> }
    override val annotations: List<Annotation> by lazy { PriceIncrease.serializer().descriptor.getElementAnnotations(3) }
    override val default: Set<UUID> = setOf()
    override val defaultCode: String? = "setOf()" 
}
object PriceIncrease_excludeProducts: SerializableProperty<PriceIncrease, Set<UUID>> {
    override val name: String by lazy { PriceIncrease.serializer().descriptor.getElementName(4) }
    override fun get(receiver: PriceIncrease): Set<UUID> = receiver.excludeProducts
    override fun setCopy(receiver: PriceIncrease, value: Set<UUID>) = receiver.copy(excludeProducts = value)
    override val serializer: KSerializer<Set<UUID>> by lazy { (PriceIncrease.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<Set<UUID>> }
    override val annotations: List<Annotation> by lazy { PriceIncrease.serializer().descriptor.getElementAnnotations(4) }
    override val default: Set<UUID> = setOf()
    override val defaultCode: String? = "setOf()" 
}
object PriceIncrease_categories: SerializableProperty<PriceIncrease, Set<UUID>> {
    override val name: String by lazy { PriceIncrease.serializer().descriptor.getElementName(5) }
    override fun get(receiver: PriceIncrease): Set<UUID> = receiver.categories
    override fun setCopy(receiver: PriceIncrease, value: Set<UUID>) = receiver.copy(categories = value)
    override val serializer: KSerializer<Set<UUID>> by lazy { (PriceIncrease.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<Set<UUID>> }
    override val annotations: List<Annotation> by lazy { PriceIncrease.serializer().descriptor.getElementAnnotations(5) }
    override val default: Set<UUID> = setOf()
    override val defaultCode: String? = "setOf()" 
}
object PriceIncrease_excludeCategories: SerializableProperty<PriceIncrease, Set<UUID>> {
    override val name: String by lazy { PriceIncrease.serializer().descriptor.getElementName(6) }
    override fun get(receiver: PriceIncrease): Set<UUID> = receiver.excludeCategories
    override fun setCopy(receiver: PriceIncrease, value: Set<UUID>) = receiver.copy(excludeCategories = value)
    override val serializer: KSerializer<Set<UUID>> by lazy { (PriceIncrease.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<Set<UUID>> }
    override val annotations: List<Annotation> by lazy { PriceIncrease.serializer().descriptor.getElementAnnotations(6) }
    override val default: Set<UUID> = setOf()
    override val defaultCode: String? = "setOf()" 
}
object PriceIncrease_manufacturers: SerializableProperty<PriceIncrease, Set<String>> {
    override val name: String by lazy { PriceIncrease.serializer().descriptor.getElementName(7) }
    override fun get(receiver: PriceIncrease): Set<String> = receiver.manufacturers
    override fun setCopy(receiver: PriceIncrease, value: Set<String>) = receiver.copy(manufacturers = value)
    override val serializer: KSerializer<Set<String>> by lazy { (PriceIncrease.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<Set<String>> }
    override val annotations: List<Annotation> by lazy { PriceIncrease.serializer().descriptor.getElementAnnotations(7) }
    override val default: Set<String> = setOf()
    override val defaultCode: String? = "setOf()" 
}
object PriceIncrease_excludeManufacturers: SerializableProperty<PriceIncrease, Set<String>> {
    override val name: String by lazy { PriceIncrease.serializer().descriptor.getElementName(8) }
    override fun get(receiver: PriceIncrease): Set<String> = receiver.excludeManufacturers
    override fun setCopy(receiver: PriceIncrease, value: Set<String>) = receiver.copy(excludeManufacturers = value)
    override val serializer: KSerializer<Set<String>> by lazy { (PriceIncrease.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<Set<String>> }
    override val annotations: List<Annotation> by lazy { PriceIncrease.serializer().descriptor.getElementAnnotations(8) }
    override val default: Set<String> = setOf()
    override val defaultCode: String? = "setOf()" 
}
