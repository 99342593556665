package com.vandenbussche.views.textFormatting

import com.vandenbussche.models.Address
import com.vandenbussche.models.Warehouse

fun Address.format(): String = listOfNotNull(
    street.allCapsToTitle() + if (!unitOrSuite.isNullOrEmpty()) " $unitOrSuite" else "",
    city.allCapsToTitle(),
    subcountry,
    postalCode,
    country,
).joinToString(", ")

fun Warehouse.formatName(): String = name.substringAfter("VANDENBUSSCHE ").allCapsToTitle()