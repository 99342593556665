package com.vandenbussche.sdk

import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.navigation.DefaultJson
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.LsErrorException
import com.lightningkite.lightningserver.db.ModelCache
import com.lightningkite.lightningserver.db.WritableModel
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.monitoring.FunnelInstance
import com.lightningkite.lightningserver.networking.BulkFetcher
import com.lightningkite.lightningserver.networking.Fetcher
import com.lightningkite.serialization.*
import com.vandenbussche.models.*
import com.vandenbussche.sdk.erp.ContactsCache
import com.vandenbussche.sdk.erp.PricingCache
import kotlin.js.JsName
import kotlin.time.Duration.Companion.minutes
import kotlinx.coroutines.*
import kotlinx.coroutines.GlobalScope
import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.Instant
import kotlinx.serialization.KSerializer

class UserSession(
    private val api: LiveApi,
    private val userToken: String,
    private val userAccessToken: suspend () -> String,
    val userId: UUID,
    val role: UserRole,
    val accountId: UUID?,
    val accountErpId: Long?
) {
    val nonCached = object : AbstractUserSession(api, userToken, userAccessToken) {
        override val api: Api = this@UserSession.api
        override val authToken: String
            get() =  this@UserSession.userToken
        override val accessToken: suspend () -> String
            get() =this@UserSession.userAccessToken
//        override val userToken: String = this@UserSession.userToken
//        override val userAccessToken: suspend () -> String = this@UserSession.userAccessToken
    }

    val carts = ModelCache(nonCached.cart, Cart.serializer())
    val customerAccounts = ModelCache(nonCached.customerAccount, CustomerAccount.serializer())

    val favorites = ModelCache(nonCached.favorite, Favorite.serializer())  // TODO: Make model cache update changed favorites

    val inventories = ModelCache(nonCached.inventory, Inventory.serializer())
    val orders = ModelCache(nonCached.order, Order.serializer())
    val productCategories = ModelCache(nonCached.productCategory, ProductCategory.serializer())
    val manufacturers = ModelCache(nonCached.manufacturer, Manufacturer.serializer())
    val products = ModelCache(nonCached.product, Product.serializer())
    val shippingAddresses = ModelCache(nonCached.shippingAddress, ShippingAddress.serializer())
    val users = ModelCache(nonCached.user, User.serializer())
    val warehouses = ModelCache(nonCached.warehouse, Warehouse.serializer())
    val popupMessages = ModelCache(nonCached.popupMessage, PopupMessage.serializer())
    val syncTaskStatus = ModelCache(nonCached.syncTaskStatus, SyncTaskStatus.serializer())
    val priceIncreases = ModelCache(nonCached.priceIncrease, PriceIncrease.serializer())

    val pricing = PricingCache(this, cacheTime = 15.minutes)

    val contacts = ContactsCache(this, cacheTime = 15.minutes)

    val mayOrder get() = role >= UserRole.Customer && accountId != null
    val editSelf = GlobalScope.asyncReadable { users[nonCached.userAuth.getSelf()._id] }.flatten()
    val self = editSelf.waitForNotNull
    val account = shared { self().account?.let { customerAccounts[it] }?.invoke() }

    val funnelInstance = ModelCache(nonCached.funnelInstance, FunnelInstance.serializer())

    fun refresh() {
        carts.totallyInvalidate()
        customerAccounts.totallyInvalidate()
        favorites.totallyInvalidate()
        inventories.totallyInvalidate()
        orders.totallyInvalidate()
        productCategories.totallyInvalidate()
        products.totallyInvalidate()
        shippingAddresses.totallyInvalidate()
        users.totallyInvalidate()
        warehouses.totallyInvalidate()
        popupMessages.totallyInvalidate()
        syncTaskStatus.totallyInvalidate()
        priceIncreases.totallyInvalidate()
//        pricing.totallyInvalidate()
//        contacts.totallyInvalidate()
    }
}

val sessionToken = PersistentProperty<String?>("sessionToken", null).apply {
    addListener { println("sessionToken is now $value") }
}

val currentSession = sharedSuspending<UserSession?> {
    try {
        println("Starting currentSession")
        val refresh = sessionToken() ?: return@sharedSuspending null
        val api = selectedApi().api

        println("Recalculating user token")

        var lastRefresh: Instant = now()
        var token: Async<String> = AppScope.async {
            println("Fetching user token")
            try {
                println("Getting new access token...")
                val r = api.userAuth.getTokenSimple(refresh)
                println("Got new access token. ${r.take(10)}")
                r
            } catch (e: LsErrorException) {
                if (e.status == 401.toShort() || e.status == 400.toShort()) sessionToken.set(null)
                currentSessionFailed.invokeAll()
                throw e
            }
        }

        val access = suspend {
            if (now() - lastRefresh > 4.minutes) {
                lastRefresh = now()
                token = asyncGlobal {
                    println("Getting new access token...")
                    val r = api.userAuth.getTokenSimple(refresh)
                    println("Got new access token. ${r.take(10)}")
                    r
                }
            }
            token.await()
        }
        println("Calculating session")
        val self = try {
            api.userAuth.getSelf(access, null)
        } catch (e: Exception) {
            println("Couldn't get self...")
            e.report("currentSession")
            sessionToken set null
            return@sharedSuspending null
        }

        println("Got self $self")

        val account = try {
            self.account?.let { account ->
                api.customerAccount.query(
                    Query(
                        condition { it._id eq account },
                        limit = 1
                    ),
                    access,
                    null
                ).firstOrNull()
            }
        } catch (e: Exception) {
            println("Couldn't get account...")
            e.report("currentSession")
            sessionToken set null
            return@sharedSuspending null
        }

        println("Got account")

        UserSession(
            api = api,
            userToken = refresh,
            userAccessToken = access,
            userId = self._id,
            role = self.role,
            accountId = account?._id,
            accountErpId = account?.erpId
        ).also { println("currentSession result") }
    } finally {
        println("Session calculation complete")
    }
}

val currentSessionFailed = BasicListenable()
@JsName("currentSessionNotNull")
suspend inline fun currentSession(): UserSession {
    val result = currentSession.await()
    if (result == null) {
        currentSessionFailed.invokeAll()
        throw CancellationException("Not here")
    }
    return result
}
inline fun ReactiveContext.currentSession(): UserSession {
    val result = currentSession.invoke()
    if (result == null) {
        currentSessionFailed.invokeAll()
        throw CancellationException("Not here")
    }
    return result
}

suspend fun ViewWriter.clearSession() {
    try {
        currentSession.await()?.nonCached?.userAuth?.terminateSession()
    } catch (e: Exception) {
        /*squish*/
    }
    finally {
        sessionToken set null
    }
}


inline fun <reified T> Readable<WritableModel<T>>.flatten(): WritableModel<T> {
    return object : WritableModel<T>, Readable<T?> by shared(action = { this@flatten()() }) {
        override suspend fun set(value: T?) {
            this@flatten.await().set(value)
        }

        override val serializer: KSerializer<T> = serializerOrContextual()

        override suspend fun delete() {
            this@flatten.await().delete()
        }

        override suspend fun modify(modification: Modification<T>): T? {
            return this@flatten.await().modify(modification)
        }

        override fun invalidate() {
            if(this@flatten.state.ready)
                this@flatten.state.get().invalidate()
        }
    }
}
inline fun <reified T> Readable<WritableModel<T>?>.flattenNullable(): WritableModel<T> {
    return object : WritableModel<T>, Readable<T?> by shared(action = { this@flattenNullable()?.invoke() }) {
        override suspend fun set(value: T?) {
            this@flattenNullable.await()?.set(value)
        }

        override val serializer: KSerializer<T> = serializerOrContextual()

        override suspend fun delete() {
            this@flattenNullable.await()?.delete()
        }

        override suspend fun modify(modification: Modification<T>): T? {
            return this@flattenNullable.await()?.modify(modification)
        }

        override fun invalidate() {
            if(this@flattenNullable.state.ready)
                this@flattenNullable.state.get()?.invalidate()
        }
    }
}

private val nullToken: suspend () -> String? = { null }
val fetcher:Readable<Fetcher> = shared {
    println("in here")
    val test =  BulkFetcher(selectedApi().api.httpUrl + "/", DefaultJson,
        currentSession().nonCached.accessToken?:nullToken
    )
    println("currentSession ${currentSession().nonCached.accessToken}")
    println("test ${test}")
    return@shared test
}