package com.vandenbussche.views.screens.account.orders

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.db.LimitReadable
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.sdk.utils.modify
import com.vandenbussche.views.components.detail
import com.vandenbussche.views.emptyView
import com.vandenbussche.views.screens.common.ContentDeterminedByAccount
import com.vandenbussche.views.screens.common.HasNarrowContent
import com.vandenbussche.views.textFormatting.format

@Routable("/OrderHistory")
class OrderHistoryScreen : ContentDeterminedByAccount, HasNarrowContent {

    override val noAccountMessage = "You don't have an account, so you have no order history"

    override fun ViewWriter.hasAccount() {
        val ordersMeta: Readable<LimitReadable<Order>> = shared {
            val session = currentSession()
            session.orders.query(
                Query(
                    condition { (it.account eq session.accountId!!) and (it.hidden eq false) },
                    orderBy = sort {
                        it.orderedAt.descending()
                        it.poNum.ascending()
                    },
                )
            )
        }
        val orders = shared { ordersMeta()() }

        stack {
            padded - recyclerView {
                ::exists { orders().isNotEmpty() }
                reactiveScope {
                    if (lastVisibleIndex() > ordersMeta().limit - 20)
                        ordersMeta().limit = lastVisibleIndex() + 100
                }

                children(orders) { order ->
                    card - rowCollapsingToColumn {
                        expanding - col {
                            detail("Order #", 15.rem) { order().poNum }
                            detail("Ordered") { order().orderedAt.format() }
                            detail("Status", 15.rem) { order().status.code.toString() }
                            detail("Total", 15.rem) { order().total.toString() }
                        }

                        expanding - space()

                        gravity(Align.Stretch, Align.Center) - rowCollapsingToColumn(5.rem, HasNarrowContent.breakpoint) {
                            expanding - important - link {
                                ::to {
                                    val read = order();
                                    { OrderScreen(read._id) }
                                }
                                centered - h6("View")
                            }
                            expanding - card - button {
                                ::enabled { order().status.code != OrderStatusCode.Cancelled }

                                centered - h6("Cancel")

                                onClick {
                                    confirmDanger("Cancel Order", "Are you sure you want to cancel this order?", "Confirm") {
                                        val o = order()
                                        currentSession().orders[o._id].modify {
                                            it.status assign OrderStatus(OrderStatusCode.Cancelled)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            emptyView {
                exists = false
                ::exists { orders().isEmpty() }

                centered - h3("No orders yet")
            }
        }
    }
}