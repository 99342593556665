package com.vandenbussche

import com.lightningkite.kiteui.navigation.*
import com.vandenbussche.views.screens.cart.CartScreen
import com.vandenbussche.views.screens.account.ShippingLocationsScreen
import com.vandenbussche.views.screens.account.AccountInfoScreen
import com.vandenbussche.views.screens.account.orders.OrderHistoryScreen
import com.vandenbussche.views.screens.account.orders.OrderScreen
import com.vandenbussche.views.screens.account.PickupLocationsScreen
import com.vandenbussche.views.screens.products.CatalogScreen
import com.vandenbussche.views.screens.products.Favorites
import com.vandenbussche.views.screens.products.SearchScreen
import com.vandenbussche.views.screens.products.ProductScreen
import com.vandenbussche.views.screens.products.CategoryScreen
import com.vandenbussche.views.screens.auth.Landing
import com.vandenbussche.views.screens.auth.LoginScreen
import com.vandenbussche.admin.AdminSpecialsScreen
import com.vandenbussche.admin.AdminOrderDetailScreen
import com.vandenbussche.admin.AdminWarehousesScreen
import com.vandenbussche.admin.AdminUsersScreen
import com.vandenbussche.admin.AdminAdminLoginsScreen
import com.vandenbussche.admin.AdminCatalogScreen
import com.vandenbussche.admin.AdminOrdersScreen
import com.vandenbussche.admin.AdminReportsScreen
import com.vandenbussche.admin.PriceIncreasesScreen
import com.vandenbussche.admin.PriceIncreaseForm
import com.vandenbussche.views.screens.products.AdminCategoryScreen
import com.vandenbussche.admin.AdminProductsScreen
import com.vandenbussche.admin.AdminAccountsScreen
import kotlinx.serialization.ExperimentalSerializationApi


@OptIn(ExperimentalSerializationApi::class)
val AutoRoutes = Routes(
    parsers = listOf(
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "Carts") return@label null
            CartScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "Shipping-Locations") return@label null
            ShippingLocationsScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "Account") return@label null
            AccountInfoScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "OrderHistory") return@label null
            OrderHistoryScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "order") return@label null
            OrderScreen(
                orderID = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "Pickup-Locations") return@label null
            PickupLocationsScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "catalog") return@label null
            CatalogScreen
            .apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "Favorites") return@label null
            Favorites(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "Search") return@label null
            SearchScreen(
            ).apply {
                UrlProperties.decodeFromStringMap("query", it.parameters, this.query)
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "Product") return@label null
            ProductScreen(
                productID = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "Category") return@label null
            CategoryScreen(
                categoryID = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 0) return@label null
            Landing(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "auth") return@label null
            if (it.segments[1] != "login") return@label null
            LoginScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "specials") return@label null
            AdminSpecialsScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "orders") return@label null
            AdminOrderDetailScreen(
                orderId = UrlProperties.decodeFromString(it.segments[1]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "warehouses") return@label null
            AdminWarehousesScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "users") return@label null
            AdminUsersScreen(
            ).apply {
                UrlProperties.decodeFromStringMap("search", it.parameters, this.search)
                UrlProperties.decodeFromStringMap("showRemoved", it.parameters, this.showRemoved)
                UrlProperties.decodeFromStringMap("editUser", it.parameters, this.editUser)
                UrlProperties.decodeFromStringMap("accounts", it.parameters, this.accounts)
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "settings") return@label null
            AdminAdminLoginsScreen(
            ).apply {
                UrlProperties.decodeFromStringMap("search", it.parameters, this.search)
                UrlProperties.decodeFromStringMap("currentEditingUser", it.parameters, this.currentEditingUser)
                UrlProperties.decodeFromStringMap("showRemoved", it.parameters, this.showRemoved)
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "categories") return@label null
            AdminCatalogScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "orders") return@label null
            AdminOrdersScreen(
            ).apply {
                UrlProperties.decodeFromStringMap("endDate", it.parameters, this.endDate)
                UrlProperties.decodeFromStringMap("orderNum", it.parameters, this.orderNum)
                UrlProperties.decodeFromStringMap("poNum", it.parameters, this.poNum)
                UrlProperties.decodeFromStringMap("includeRemoved", it.parameters, this.includeRemoved)
                UrlProperties.decodeFromStringMap("accounts", it.parameters, this.accounts)
                UrlProperties.decodeFromStringMap("startDate", it.parameters, this.startDate)
                UrlProperties.decodeFromStringMap("users", it.parameters, this.users)
                UrlProperties.decodeFromStringMap("status", it.parameters, this.status)
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "reports") return@label null
            AdminReportsScreen(
            ).apply {
                UrlProperties.decodeFromStringMap("endDate", it.parameters, this.endDate)
                UrlProperties.decodeFromStringMap("startDate", it.parameters, this.startDate)
            }
        },
        label@{ 
            if (it.segments.size != 2) return@label null
            if (it.segments[0] != "admin") return@label null
            if (it.segments[1] != "price-increases") return@label null
            PriceIncreasesScreen(
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "admin") return@label null
            if (it.segments[1] != "price-increases") return@label null
            PriceIncreaseForm(
                id = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 3) return@label null
            if (it.segments[0] != "admin") return@label null
            if (it.segments[1] != "category") return@label null
            AdminCategoryScreen(
                categoryID = UrlProperties.decodeFromString(it.segments[2]),
            ).apply {
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "products") return@label null
            AdminProductsScreen(
            ).apply {
                UrlProperties.decodeFromStringMap("showRemoved", it.parameters, this.showRemoved)
                UrlProperties.decodeFromStringMap("searchProducts", it.parameters, this.searchProducts)
                UrlProperties.decodeFromStringMap("limitToNoImageProducts", it.parameters, this.limitToNoImageProducts)
            }
        },
        label@{ 
            if (it.segments.size != 1) return@label null
            if (it.segments[0] != "customers") return@label null
            AdminAccountsScreen(
            ).apply {
                UrlProperties.decodeFromStringMap("search", it.parameters, this.search)
                UrlProperties.decodeFromStringMap("representatives", it.parameters, this.representatives)
            }
        },
    ),
    renderers = mapOf(
        CartScreen::class to label@{
            if (it !is CartScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("Carts"),
                parameters = p
            ), listOf())
        },
        ShippingLocationsScreen::class to label@{
            if (it !is ShippingLocationsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("Shipping-Locations"),
                parameters = p
            ), listOf())
        },
        AccountInfoScreen::class to label@{
            if (it !is AccountInfoScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("Account"),
                parameters = p
            ), listOf())
        },
        OrderHistoryScreen::class to label@{
            if (it !is OrderHistoryScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("OrderHistory"),
                parameters = p
            ), listOf())
        },
        OrderScreen::class to label@{
            if (it !is OrderScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("order", UrlProperties.encodeToString(it.orderID)),
                parameters = p
            ), listOf())
        },
        PickupLocationsScreen::class to label@{
            if (it !is PickupLocationsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("Pickup-Locations"),
                parameters = p
            ), listOf())
        },
        CatalogScreen::class to label@{
            if (it !is CatalogScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("catalog"),
                parameters = p
            ), listOf())
        },
        Favorites::class to label@{
            if (it !is Favorites) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("Favorites"),
                parameters = p
            ), listOf())
        },
        SearchScreen::class to label@{
            if (it !is SearchScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.query.value, "query", p)
            RouteRendered(UrlLikePath(
                segments = listOf("Search"),
                parameters = p
            ), listOf(it.query))
        },
        ProductScreen::class to label@{
            if (it !is ProductScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("Product", UrlProperties.encodeToString(it.productID)),
                parameters = p
            ), listOf())
        },
        CategoryScreen::class to label@{
            if (it !is CategoryScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("Category", UrlProperties.encodeToString(it.categoryID)),
                parameters = p
            ), listOf())
        },
        Landing::class to label@{
            if (it !is Landing) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf(),
                parameters = p
            ), listOf())
        },
        LoginScreen::class to label@{
            if (it !is LoginScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("auth", "login"),
                parameters = p
            ), listOf())
        },
        AdminSpecialsScreen::class to label@{
            if (it !is AdminSpecialsScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("specials"),
                parameters = p
            ), listOf())
        },
        AdminOrderDetailScreen::class to label@{
            if (it !is AdminOrderDetailScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("orders", UrlProperties.encodeToString(it.orderId)),
                parameters = p
            ), listOf())
        },
        AdminWarehousesScreen::class to label@{
            if (it !is AdminWarehousesScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("warehouses"),
                parameters = p
            ), listOf())
        },
        AdminUsersScreen::class to label@{
            if (it !is AdminUsersScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.search.value, "search", p)
            UrlProperties.encodeToStringMap(it.showRemoved.value, "showRemoved", p)
            UrlProperties.encodeToStringMap(it.editUser.value, "editUser", p)
            UrlProperties.encodeToStringMap(it.accounts.value, "accounts", p)
            RouteRendered(UrlLikePath(
                segments = listOf("users"),
                parameters = p
            ), listOf(it.search, it.showRemoved, it.editUser, it.accounts))
        },
        AdminAdminLoginsScreen::class to label@{
            if (it !is AdminAdminLoginsScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.search.value, "search", p)
            UrlProperties.encodeToStringMap(it.currentEditingUser.value, "currentEditingUser", p)
            UrlProperties.encodeToStringMap(it.showRemoved.value, "showRemoved", p)
            RouteRendered(UrlLikePath(
                segments = listOf("settings"),
                parameters = p
            ), listOf(it.search, it.currentEditingUser, it.showRemoved))
        },
        AdminCatalogScreen::class to label@{
            if (it !is AdminCatalogScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("categories"),
                parameters = p
            ), listOf())
        },
        AdminOrdersScreen::class to label@{
            if (it !is AdminOrdersScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.endDate.value, "endDate", p)
            UrlProperties.encodeToStringMap(it.orderNum.value, "orderNum", p)
            UrlProperties.encodeToStringMap(it.poNum.value, "poNum", p)
            UrlProperties.encodeToStringMap(it.includeRemoved.value, "includeRemoved", p)
            UrlProperties.encodeToStringMap(it.accounts.value, "accounts", p)
            UrlProperties.encodeToStringMap(it.startDate.value, "startDate", p)
            UrlProperties.encodeToStringMap(it.users.value, "users", p)
            UrlProperties.encodeToStringMap(it.status.value, "status", p)
            RouteRendered(UrlLikePath(
                segments = listOf("orders"),
                parameters = p
            ), listOf(it.endDate, it.orderNum, it.poNum, it.includeRemoved, it.accounts, it.startDate, it.users, it.status))
        },
        AdminReportsScreen::class to label@{
            if (it !is AdminReportsScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.endDate.value, "endDate", p)
            UrlProperties.encodeToStringMap(it.startDate.value, "startDate", p)
            RouteRendered(UrlLikePath(
                segments = listOf("reports"),
                parameters = p
            ), listOf(it.endDate, it.startDate))
        },
        PriceIncreasesScreen::class to label@{
            if (it !is PriceIncreasesScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("admin", "price-increases"),
                parameters = p
            ), listOf())
        },
        PriceIncreaseForm::class to label@{
            if (it !is PriceIncreaseForm) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("admin", "price-increases", UrlProperties.encodeToString(it.id)),
                parameters = p
            ), listOf())
        },
        AdminCategoryScreen::class to label@{
            if (it !is AdminCategoryScreen) return@label null
            val p = HashMap<String, String>()
            RouteRendered(UrlLikePath(
                segments = listOf("admin", "category", UrlProperties.encodeToString(it.categoryID)),
                parameters = p
            ), listOf())
        },
        AdminProductsScreen::class to label@{
            if (it !is AdminProductsScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.showRemoved.value, "showRemoved", p)
            UrlProperties.encodeToStringMap(it.searchProducts.value, "searchProducts", p)
            UrlProperties.encodeToStringMap(it.limitToNoImageProducts.value, "limitToNoImageProducts", p)
            RouteRendered(UrlLikePath(
                segments = listOf("products"),
                parameters = p
            ), listOf(it.showRemoved, it.searchProducts, it.limitToNoImageProducts))
        },
        AdminAccountsScreen::class to label@{
            if (it !is AdminAccountsScreen) return@label null
            val p = HashMap<String, String>()
            UrlProperties.encodeToStringMap(it.search.value, "search", p)
            UrlProperties.encodeToStringMap(it.representatives.value, "representatives", p)
            RouteRendered(UrlLikePath(
                segments = listOf("customers"),
                parameters = p
            ), listOf(it.search, it.representatives))
        },
    ),
)
