package com.vandenbussche.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class VBErpContact(
    @SerialName("contactid")
    val contactId: Double,
    @SerialName("firstnm")
    val firstName: String,
    @SerialName("middlenm")
    val middleName: String,
    @SerialName("lastnm")
    val lastName: String,
    @SerialName("cotitle")
    val title: String,
    @SerialName("phoneno")
    val phoneNumber: String,
    @SerialName("emailaddr")
    val email: String,
    @SerialName("priority")
    val priority: Int,
) {
    val fullName get() = listOf(
        firstName,
        middleName,
        lastName
    ).filter { it.isNotBlank() }.joinToString(" ")
}

// we may want to include all columns on the existing spreadsheet for formatting reasons
// but for now I have commented out all but the useful columns
@Serializable
data class VBSpreadsheetProduct(
    val Category1: String = "",
    val Category2: String = "",
    val Category3: String = "",
    val Category4: String = "",
    @SerialName("Product Title")
    val productTitle: String = "",
//    @SerialName("Improved Titles")
//    val improvedTitle: String? = null,
    val Manufacturer: String = "",
    @SerialName("Product Description")
    val productDescription: String = "",
    @SerialName("Product Number")
    val productNumber: String,
    val SKU: String = "",
//    val Unit: String? = null,
//    @SerialName("Style Price")
//    val stylePrice: String? = null,
//    val Meta1: String? = null,
//    val Meta2: String? = null,
//    @SerialName("Improved Part#")
//    val improvedPartNumber: String? = null,
//    val Xref: String? = null,
    @SerialName("Image Name")
    val Image: String = "",
    @SerialName("Image URL")
    val imageUrl: String = "",
)