// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.vandenbussche.models

import com.lightningkite.lightningdb.*
import com.lightningkite.UUID
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareOrderStatusFields() {
    val props: Array<SerializableProperty<OrderStatus, *>> = arrayOf(OrderStatus_code, OrderStatus_at)
    OrderStatus.serializer().properties { props }
}
val <K> DataClassPath<K, OrderStatus>.code: DataClassPath<K, OrderStatusCode> get() = this[OrderStatus_code]
val <K> DataClassPath<K, OrderStatus>.at: DataClassPath<K, Instant> get() = this[OrderStatus_at]
inline val OrderStatus.Companion.path: DataClassPath<OrderStatus, OrderStatus> get() = path<OrderStatus>()


object OrderStatus_code: SerializableProperty<OrderStatus, OrderStatusCode> {
    override val name: String by lazy { OrderStatus.serializer().descriptor.getElementName(0) }
    override fun get(receiver: OrderStatus): OrderStatusCode = receiver.code
    override fun setCopy(receiver: OrderStatus, value: OrderStatusCode) = receiver.copy(code = value)
    override val serializer: KSerializer<OrderStatusCode> by lazy { (OrderStatus.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<OrderStatusCode> }
    override val annotations: List<Annotation> by lazy { OrderStatus.serializer().descriptor.getElementAnnotations(0) }
}
object OrderStatus_at: SerializableProperty<OrderStatus, Instant> {
    override val name: String by lazy { OrderStatus.serializer().descriptor.getElementName(1) }
    override fun get(receiver: OrderStatus): Instant = receiver.at
    override fun setCopy(receiver: OrderStatus, value: Instant) = receiver.copy(at = value)
    override val serializer: KSerializer<Instant> by lazy { (OrderStatus.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { OrderStatus.serializer().descriptor.getElementAnnotations(1) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
