package com.vandenbussche.models

import com.lightningkite.serialization.*
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.builtins.NothingSerializer

fun prepareModelsShared() { SharedModelsObject }
object SharedModelsObject {
    init {
        SerializationRegistry.master.registerShared()
        com.vandenbussche.models.prepareDistributorFields()
        com.vandenbussche.models.prepareProductCategoryFields()
        com.vandenbussche.models.prepareManufacturerFields()
        com.vandenbussche.models.prepareProductFields()
        com.vandenbussche.models.preparePriceIncreaseFields()
        com.vandenbussche.models.prepareWarehouseFields()
        com.vandenbussche.models.prepareInventoryFields()
        com.vandenbussche.models.prepareAddressFields()
        SerializableAnnotation.parser<com.vandenbussche.models.GloballyUnique>("com.vandenbussche.models.GloballyUnique") { SerializableAnnotation("com.vandenbussche.models.GloballyUnique", values = mapOf("fieldName" to SerializableAnnotationValue(it.fieldName))) }
        com.vandenbussche.models.prepareCustomerAccountFields()
        com.vandenbussche.models.prepareUserFields()
        com.vandenbussche.models.prepareShippingAddressFields()
        com.vandenbussche.models.prepareOrderStatusFields()
        com.vandenbussche.models.prepareOrderFields()
        com.vandenbussche.models.prepareCartFields()
        com.vandenbussche.models.prepareCartItemFields()
        com.vandenbussche.models.prepareAccountProductFields()
        com.vandenbussche.models.prepareFavoriteFields()
        com.vandenbussche.models.preparePopupMessageFields()
        com.vandenbussche.models.prepareSyncTaskStatusFields()
    }
}

@OptIn(ExperimentalSerializationApi::class)
fun SerializationRegistry.registerShared() {
    register(com.vandenbussche.models.PriceInCents.serializer())
    register(com.vandenbussche.models.VBErpContact.serializer())
    register(com.vandenbussche.models.VBSpreadsheetProduct.serializer())
    register(com.vandenbussche.models.Distributor.serializer())
    register(com.vandenbussche.models.ProductCategory.serializer())
    register(com.vandenbussche.models.QuantityType.serializer())
    register(com.vandenbussche.models.Manufacturer.serializer())
    register(com.vandenbussche.models.Product.serializer())
    register(com.vandenbussche.models.PriceIncrease.serializer())
    register(com.vandenbussche.models.Warehouse.serializer())
    register(com.vandenbussche.models.Inventory.serializer())
    register(com.vandenbussche.models.ProductWarehouse.serializer())
    register(com.vandenbussche.models.Address.serializer())
    register(com.vandenbussche.models.CustomerAccount.serializer())
    register(com.vandenbussche.models.User.serializer())
    register(com.vandenbussche.models.UserRole.serializer())
    register(com.vandenbussche.models.CreateUser.serializer())
    register(com.vandenbussche.models.ShippingAddress.serializer())
    register(com.vandenbussche.models.OrderStatusCode.serializer())
    register(com.vandenbussche.models.OrderStatus.serializer())
    register(com.vandenbussche.models.OrderStatusRequestInfo.serializer())
    register(com.vandenbussche.models.Order.serializer())
    register(com.vandenbussche.models.PricingRequestInfo.serializer())
    register(com.vandenbussche.models.Cart.serializer())
    register(com.vandenbussche.models.CartItem.serializer())
    register(com.vandenbussche.models.AccountProduct.serializer())
    register(com.vandenbussche.models.Favorite.serializer())
    register(com.vandenbussche.models.PopupBehavior.serializer())
    register(com.vandenbussche.models.PopupMessage.serializer())
    register(com.vandenbussche.models.SyncTaskStatus.serializer())
    register(com.vandenbussche.models.OrderMarch2025.serializer())
}
