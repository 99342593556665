
package com.vandenbussche.sdk

import com.lightningkite.*
import com.lightningkite.kiteui.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.db.*
import com.lightningkite.lightningserver.auth.*
import com.lightningkite.serialization.*
import kotlinx.datetime.*
import com.lightningkite.lightningserver.serverhealth.ServerHealth
import com.lightningkite.lightningserver.typed.BulkRequest
import com.lightningkite.lightningserver.typed.BulkResponse
import com.lightningkite.lightningserver.files.UploadInformation
import kotlin.String
import com.vandenbussche.models.CreateUser
import com.vandenbussche.models.User
import kotlin.Boolean
import com.vandenbussche.models.ShippingAddress
import com.lightningkite.UUID
import com.lightningkite.lightningdb.ModelPermissions
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.QueryPartial
import com.lightningkite.serialization.Partial
import com.lightningkite.lightningdb.MassModification
import kotlin.Int
import com.lightningkite.lightningdb.Modification
import com.lightningkite.lightningdb.EntryChange
import com.lightningkite.lightningdb.Condition
import kotlin.Unit
import com.lightningkite.lightningdb.GroupCountQuery
import com.lightningkite.lightningdb.AggregateQuery
import kotlin.Double
import com.lightningkite.lightningdb.GroupAggregateQuery
import com.lightningkite.lightningserver.auth.proof.IdentificationAndPassword
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningkite.lightningserver.auth.subject.IdAndAuthMethods
import com.lightningkite.lightningserver.auth.subject.LogInRequest
import com.lightningkite.lightningserver.auth.subject.ProofsCheckResult
import com.lightningkite.lightningserver.auth.oauth.OauthTokenRequest
import com.lightningkite.lightningserver.auth.oauth.OauthResponse
import com.lightningkite.lightningserver.monitoring.FunnelStart
import com.vandenbussche.models.PricingRequestInfo
import com.vandenbussche.models.CartItem
import kotlin.Long
import com.vandenbussche.models.VBErpContact
import com.vandenbussche.models.Manufacturer
import com.vandenbussche.models.Warehouse
import com.vandenbussche.models.Inventory
import com.vandenbussche.models.ProductWarehouse
import com.vandenbussche.models.CustomerAccount
import com.vandenbussche.models.Order
import com.vandenbussche.models.Cart
import com.vandenbussche.models.Favorite
import com.vandenbussche.models.AccountProduct
import com.vandenbussche.models.PopupMessage
import com.vandenbussche.models.PriceIncrease
import com.vandenbussche.models.SyncTaskStatus
import com.lightningkite.lightningdb.CollectionUpdates
import com.lightningkite.lightningserver.auth.proof.PasswordSecret
import com.lightningkite.lightningserver.auth.subject.SubSessionRequest
import com.lightningkite.lightningserver.auth.subject.Session
import com.lightningkite.lightningserver.monitoring.FunnelSummary
import com.lightningkite.lightningserver.monitoring.FunnelInstance
import kotlinx.datetime.LocalDate
import com.vandenbussche.models.ProductCategory
import com.vandenbussche.models.Product
import com.lightningkite.lightningserver.files.ServerFile
import com.vandenbussche.models.VBSpreadsheetProduct

open class AbstractAnonymousSession(val api: Api) {
    val manufacturer: AbstractAnonymousSessionManufacturerApi = AbstractAnonymousSessionManufacturerApi(api.manufacturer)
    val warehouse: AbstractAnonymousSessionWarehouseApi = AbstractAnonymousSessionWarehouseApi(api.warehouse)
    val inventory: AbstractAnonymousSessionInventoryApi = AbstractAnonymousSessionInventoryApi(api.inventory)
    val customerAccount: AbstractAnonymousSessionCustomerAccountApi = AbstractAnonymousSessionCustomerAccountApi(api.customerAccount)
    val user: AbstractAnonymousSessionUserApi = AbstractAnonymousSessionUserApi(api.user)
    val shippingAddress: AbstractAnonymousSessionShippingAddressApi = AbstractAnonymousSessionShippingAddressApi(api.shippingAddress)
    val order: AbstractAnonymousSessionOrderApi = AbstractAnonymousSessionOrderApi(api.order)
    val cart: AbstractAnonymousSessionCartApi = AbstractAnonymousSessionCartApi(api.cart)
    val favorite: AbstractAnonymousSessionFavoriteApi = AbstractAnonymousSessionFavoriteApi(api.favorite)
    val popupMessage: AbstractAnonymousSessionPopupMessageApi = AbstractAnonymousSessionPopupMessageApi(api.popupMessage)
    val priceIncrease: AbstractAnonymousSessionPriceIncreaseApi = AbstractAnonymousSessionPriceIncreaseApi(api.priceIncrease)
    val syncTaskStatus: AbstractAnonymousSessionSyncTaskStatusApi = AbstractAnonymousSessionSyncTaskStatusApi(api.syncTaskStatus)
    val passwordSecret: AbstractAnonymousSessionPasswordSecretApi = AbstractAnonymousSessionPasswordSecretApi(api.passwordSecret)
    val passwordProof: AbstractAnonymousSessionPasswordProofApi = AbstractAnonymousSessionPasswordProofApi(api.passwordProof)
    val emailProof: AbstractAnonymousSessionEmailProofApi = AbstractAnonymousSessionEmailProofApi(api.emailProof)
    val userAuth: AbstractAnonymousSessionUserAuthApi = AbstractAnonymousSessionUserAuthApi(api.userAuth)
    val userSession: AbstractAnonymousSessionUserSessionApi = AbstractAnonymousSessionUserSessionApi(api.userSession)
    val funnelSummary: AbstractAnonymousSessionFunnelSummaryApi = AbstractAnonymousSessionFunnelSummaryApi(api.funnelSummary)
    val funnelInstance: AbstractAnonymousSessionFunnelInstanceApi = AbstractAnonymousSessionFunnelInstanceApi(api.funnelInstance)
    val productCategory: AbstractAnonymousSessionProductCategoryApi = AbstractAnonymousSessionProductCategoryApi(api.productCategory)
    val product: AbstractAnonymousSessionProductApi = AbstractAnonymousSessionProductApi(api.product)
    suspend fun getServerHealth(): ServerHealth = api.getServerHealth()
    suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = api.bulkRequest(input)
    suspend fun uploadFileForRequest(): UploadInformation = api.uploadFileForRequest()
    suspend fun verifyUploadedFile(input: String): String = api.verifyUploadedFile(input)
    open class AbstractAnonymousSessionManufacturerApi(val api: Api.ManufacturerApi) {
    }
    open class AbstractAnonymousSessionWarehouseApi(val api: Api.WarehouseApi) {
    }
    open class AbstractAnonymousSessionInventoryApi(val api: Api.InventoryApi) {
    }
    open class AbstractAnonymousSessionCustomerAccountApi(val api: Api.CustomerAccountApi) {
    }
    open class AbstractAnonymousSessionUserApi(val api: Api.UserApi) {
        suspend fun create(input: CreateUser): User = api.create(input)
        suspend fun emailRegistered(input: String): Boolean = api.emailRegistered(input)
    }
    open class AbstractAnonymousSessionShippingAddressApi(val api: Api.ShippingAddressApi): ClientModelRestEndpoints<ShippingAddress, UUID> {
        override suspend fun default(): ShippingAddress = api.default(null, null)
        override suspend fun permissions(): ModelPermissions<ShippingAddress> = api.permissions(null, null)
        override suspend fun query(input: Query<ShippingAddress>): List<ShippingAddress> = api.query(input, null, null)
        override suspend fun queryPartial(input: QueryPartial<ShippingAddress>): List<Partial<ShippingAddress>> = api.queryPartial(input, null, null)
        override suspend fun detail(id: UUID): ShippingAddress = api.detail(id, null, null)
        override suspend fun insertBulk(input: List<ShippingAddress>): List<ShippingAddress> = api.insertBulk(input, null, null)
        override suspend fun insert(input: ShippingAddress): ShippingAddress = api.insert(input, null, null)
        override suspend fun upsert(id: UUID, input: ShippingAddress): ShippingAddress = api.upsert(id, input, null, null)
        override suspend fun bulkReplace(input: List<ShippingAddress>): List<ShippingAddress> = api.bulkReplace(input, null, null)
        override suspend fun replace(id: UUID, input: ShippingAddress): ShippingAddress = api.replace(id, input, null, null)
        override suspend fun bulkModify(input: MassModification<ShippingAddress>): Int = api.bulkModify(input, null, null)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ShippingAddress>): EntryChange<ShippingAddress> = api.modifyWithDiff(id, input, null, null)
        override suspend fun modify(id: UUID, input: Modification<ShippingAddress>): ShippingAddress = api.modify(id, input, null, null)
        suspend fun simplifiedModify(id: UUID, input: Partial<ShippingAddress>): ShippingAddress = api.simplifiedModify(id, input, null, null)
        override suspend fun bulkDelete(input: Condition<ShippingAddress>): Int = api.bulkDelete(input, null, null)
        override suspend fun delete(id: UUID): Unit = api.delete(id, null, null)
        override suspend fun count(input: Condition<ShippingAddress>): Int = api.count(input, null, null)
        override suspend fun groupCount(input: GroupCountQuery<ShippingAddress>): Map<String, Int> = api.groupCount(input, null, null)
        override suspend fun aggregate(input: AggregateQuery<ShippingAddress>): Double? = api.aggregate(input, null, null)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ShippingAddress>): Map<String, Double?> = api.groupAggregate(input, null, null)
    }
    open class AbstractAnonymousSessionOrderApi(val api: Api.OrderApi) {
    }
    open class AbstractAnonymousSessionCartApi(val api: Api.CartApi) {
    }
    open class AbstractAnonymousSessionFavoriteApi(val api: Api.FavoriteApi) {
    }
    open class AbstractAnonymousSessionPopupMessageApi(val api: Api.PopupMessageApi) {
    }
    open class AbstractAnonymousSessionPriceIncreaseApi(val api: Api.PriceIncreaseApi) {
    }
    open class AbstractAnonymousSessionSyncTaskStatusApi(val api: Api.SyncTaskStatusApi) {
    }
    open class AbstractAnonymousSessionPasswordSecretApi(val api: Api.PasswordSecretApi) {
    }
    open class AbstractAnonymousSessionPasswordProofApi(val api: Api.PasswordProofApi): PasswordProofClientEndpoints {
        override suspend fun provePasswordOwnership(input: IdentificationAndPassword): Proof = api.provePasswordOwnership(input)
    }
    open class AbstractAnonymousSessionEmailProofApi(val api: Api.EmailProofApi): EmailProofClientEndpoints {
        override suspend fun beginEmailOwnershipProof(input: String): String = api.beginEmailOwnershipProof(input)
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = api.proveEmailOwnership(input)
    }
    open class AbstractAnonymousSessionUserAuthApi(val api: Api.UserAuthApi): UserAuthClientEndpoints<UUID> {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = api.logIn(input)
        override suspend fun logInV2(input: LogInRequest): IdAndAuthMethods<UUID> = api.logInV2(input)
        override suspend fun checkProofs(input: List<Proof>): ProofsCheckResult<UUID> = api.checkProofs(input)
        override suspend fun openSession(input: String): String = api.openSession(input)
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = api.getToken(input)
        override suspend fun getTokenSimple(input: String): String = api.getTokenSimple(input)
    }
    open class AbstractAnonymousSessionUserSessionApi(val api: Api.UserSessionApi) {
    }
    open class AbstractAnonymousSessionFunnelSummaryApi(val api: Api.FunnelSummaryApi) {
    }
    open class AbstractAnonymousSessionFunnelInstanceApi(val api: Api.FunnelInstanceApi) {
        suspend fun startFunnelInstance(input: FunnelStart): UUID = api.startFunnelInstance(input, null, null)
        suspend fun errorFunnelInstance(id: UUID, input: String): Unit = api.errorFunnelInstance(id, input)
        suspend fun setStepFunnelInstance(id: UUID, input: Int): Unit = api.setStepFunnelInstance(id, input)
        suspend fun successFunnelInstance(id: UUID): Unit = api.successFunnelInstance(id)
    }
    open class AbstractAnonymousSessionProductCategoryApi(val api: Api.ProductCategoryApi) {
    }
    open class AbstractAnonymousSessionProductApi(val api: Api.ProductApi) {
    }
}

abstract class AbstractUserSession(api: Api, authToken: String, accessToken: suspend () -> String, masquerade: String? = null) {
    abstract val api: Api
    abstract val authToken: String
    abstract val accessToken: suspend () -> String
    open val masquerade: String? = null
    val manufacturer: UserSessionManufacturerApi = UserSessionManufacturerApi(api.manufacturer, authToken, accessToken, masquerade)
    val warehouse: UserSessionWarehouseApi = UserSessionWarehouseApi(api.warehouse, authToken, accessToken, masquerade)
    val inventory: UserSessionInventoryApi = UserSessionInventoryApi(api.inventory, authToken, accessToken, masquerade)
    val customerAccount: UserSessionCustomerAccountApi = UserSessionCustomerAccountApi(api.customerAccount, authToken, accessToken, masquerade)
    val user: UserSessionUserApi = UserSessionUserApi(api.user, authToken, accessToken, masquerade)
    val shippingAddress: UserSessionShippingAddressApi = UserSessionShippingAddressApi(api.shippingAddress, authToken, accessToken, masquerade)
    val order: UserSessionOrderApi = UserSessionOrderApi(api.order, authToken, accessToken, masquerade)
    val cart: UserSessionCartApi = UserSessionCartApi(api.cart, authToken, accessToken, masquerade)
    val favorite: UserSessionFavoriteApi = UserSessionFavoriteApi(api.favorite, authToken, accessToken, masquerade)
    val popupMessage: UserSessionPopupMessageApi = UserSessionPopupMessageApi(api.popupMessage, authToken, accessToken, masquerade)
    val priceIncrease: UserSessionPriceIncreaseApi = UserSessionPriceIncreaseApi(api.priceIncrease, authToken, accessToken, masquerade)
    val syncTaskStatus: UserSessionSyncTaskStatusApi = UserSessionSyncTaskStatusApi(api.syncTaskStatus, authToken, accessToken, masquerade)
    val passwordSecret: UserSessionPasswordSecretApi = UserSessionPasswordSecretApi(api.passwordSecret, authToken, accessToken, masquerade)
    val passwordProof: UserSessionPasswordProofApi = UserSessionPasswordProofApi(api.passwordProof, authToken, accessToken, masquerade)
    val emailProof: UserSessionEmailProofApi = UserSessionEmailProofApi(api.emailProof, authToken, accessToken, masquerade)
    val userAuth: UserSessionUserAuthApi = UserSessionUserAuthApi(api.userAuth, authToken, accessToken, masquerade)
    val userSession: UserSessionUserSessionApi = UserSessionUserSessionApi(api.userSession, authToken, accessToken, masquerade)
    val funnelSummary: UserSessionFunnelSummaryApi = UserSessionFunnelSummaryApi(api.funnelSummary, authToken, accessToken, masquerade)
    val funnelInstance: UserSessionFunnelInstanceApi = UserSessionFunnelInstanceApi(api.funnelInstance, authToken, accessToken, masquerade)
    val productCategory: UserSessionProductCategoryApi = UserSessionProductCategoryApi(api.productCategory, authToken, accessToken, masquerade)
    val product: UserSessionProductApi = UserSessionProductApi(api.product, authToken, accessToken, masquerade)
    suspend fun getERPPricing(input: PricingRequestInfo): List<CartItem> = api.getERPPricing(input, accessToken, masquerade)
    suspend fun getContactsForAccount(input: Long): List<VBErpContact> = api.getContactsForAccount(input, accessToken, masquerade)
    suspend fun syncNow(): String = api.syncNow(accessToken, masquerade)
    suspend fun getSyncTasks(): List<String> = api.getSyncTasks(accessToken, masquerade)
    suspend fun syncNowPartial(id: String): String = api.syncNowPartial(id, accessToken, masquerade)
    class UserSessionManufacturerApi(val api: Api.ManufacturerApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Manufacturer, String> {
        override suspend fun default(): Manufacturer = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<Manufacturer> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<Manufacturer>): List<Manufacturer> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Manufacturer>): List<Partial<Manufacturer>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: String): Manufacturer = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<Manufacturer>): List<Manufacturer> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: Manufacturer): Manufacturer = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: String, input: Manufacturer): Manufacturer = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<Manufacturer>): List<Manufacturer> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: String, input: Manufacturer): Manufacturer = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Manufacturer>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: String, input: Modification<Manufacturer>): EntryChange<Manufacturer> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: String, input: Modification<Manufacturer>): Manufacturer = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: String, input: Partial<Manufacturer>): Manufacturer = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Manufacturer>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: String): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<Manufacturer>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Manufacturer>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Manufacturer>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Manufacturer>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionWarehouseApi(val api: Api.WarehouseApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Warehouse, UUID> {
        override suspend fun default(): Warehouse = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<Warehouse> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<Warehouse>): List<Warehouse> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Warehouse>): List<Partial<Warehouse>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): Warehouse = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<Warehouse>): List<Warehouse> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: Warehouse): Warehouse = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: Warehouse): Warehouse = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<Warehouse>): List<Warehouse> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: Warehouse): Warehouse = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Warehouse>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Warehouse>): EntryChange<Warehouse> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<Warehouse>): Warehouse = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<Warehouse>): Warehouse = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Warehouse>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<Warehouse>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Warehouse>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Warehouse>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Warehouse>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionInventoryApi(val api: Api.InventoryApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Inventory, ProductWarehouse> {
        override suspend fun default(): Inventory = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<Inventory> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<Inventory>): List<Inventory> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Inventory>): List<Partial<Inventory>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: ProductWarehouse): Inventory = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<Inventory>): List<Inventory> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: Inventory): Inventory = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: ProductWarehouse, input: Inventory): Inventory = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<Inventory>): List<Inventory> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: ProductWarehouse, input: Inventory): Inventory = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Inventory>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: ProductWarehouse, input: Modification<Inventory>): EntryChange<Inventory> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: ProductWarehouse, input: Modification<Inventory>): Inventory = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: ProductWarehouse, input: Partial<Inventory>): Inventory = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Inventory>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: ProductWarehouse): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<Inventory>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Inventory>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Inventory>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Inventory>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionCustomerAccountApi(val api: Api.CustomerAccountApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<CustomerAccount, UUID> {
        override suspend fun default(): CustomerAccount = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<CustomerAccount> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<CustomerAccount>): List<CustomerAccount> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<CustomerAccount>): List<Partial<CustomerAccount>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): CustomerAccount = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<CustomerAccount>): List<CustomerAccount> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: CustomerAccount): CustomerAccount = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: CustomerAccount): CustomerAccount = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<CustomerAccount>): List<CustomerAccount> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: CustomerAccount): CustomerAccount = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<CustomerAccount>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<CustomerAccount>): EntryChange<CustomerAccount> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<CustomerAccount>): CustomerAccount = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<CustomerAccount>): CustomerAccount = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<CustomerAccount>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<CustomerAccount>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<CustomerAccount>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<CustomerAccount>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<CustomerAccount>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionUserApi(val api: Api.UserApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<User, UUID> {
        override suspend fun default(): User = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<User> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<User>): List<User> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<User>): List<Partial<User>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): User = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<User>): List<User> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: User): User = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: User): User = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<User>): List<User> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: User): User = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<User>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<User>): EntryChange<User> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<User>): User = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<User>): User = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<User>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<User>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<User>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<User>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<User>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionShippingAddressApi(val api: Api.ShippingAddressApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<ShippingAddress, UUID> {
        override suspend fun default(): ShippingAddress = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<ShippingAddress> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<ShippingAddress>): List<ShippingAddress> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<ShippingAddress>): List<Partial<ShippingAddress>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): ShippingAddress = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<ShippingAddress>): List<ShippingAddress> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: ShippingAddress): ShippingAddress = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: ShippingAddress): ShippingAddress = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<ShippingAddress>): List<ShippingAddress> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: ShippingAddress): ShippingAddress = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<ShippingAddress>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ShippingAddress>): EntryChange<ShippingAddress> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<ShippingAddress>): ShippingAddress = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<ShippingAddress>): ShippingAddress = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<ShippingAddress>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<ShippingAddress>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<ShippingAddress>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<ShippingAddress>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ShippingAddress>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionOrderApi(val api: Api.OrderApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Order, UUID> {
        override suspend fun default(): Order = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<Order> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<Order>): List<Order> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Order>): List<Partial<Order>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): Order = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<Order>): List<Order> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: Order): Order = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: Order): Order = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<Order>): List<Order> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: Order): Order = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Order>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Order>): EntryChange<Order> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<Order>): Order = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<Order>): Order = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Order>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<Order>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Order>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Order>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Order>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionCartApi(val api: Api.CartApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Cart, UUID> {
        override suspend fun default(): Cart = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<Cart> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<Cart>): List<Cart> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Cart>): List<Partial<Cart>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): Cart = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<Cart>): List<Cart> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: Cart): Cart = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: Cart): Cart = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<Cart>): List<Cart> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: Cart): Cart = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Cart>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Cart>): EntryChange<Cart> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<Cart>): Cart = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<Cart>): Cart = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Cart>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<Cart>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Cart>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Cart>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Cart>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionFavoriteApi(val api: Api.FavoriteApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Favorite, AccountProduct> {
        override suspend fun default(): Favorite = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<Favorite> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<Favorite>): List<Favorite> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Favorite>): List<Partial<Favorite>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: AccountProduct): Favorite = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<Favorite>): List<Favorite> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: Favorite): Favorite = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: AccountProduct, input: Favorite): Favorite = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<Favorite>): List<Favorite> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: AccountProduct, input: Favorite): Favorite = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Favorite>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: AccountProduct, input: Modification<Favorite>): EntryChange<Favorite> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: AccountProduct, input: Modification<Favorite>): Favorite = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: AccountProduct, input: Partial<Favorite>): Favorite = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Favorite>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: AccountProduct): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<Favorite>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Favorite>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Favorite>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Favorite>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionPopupMessageApi(val api: Api.PopupMessageApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<PopupMessage, UUID> {
        override suspend fun default(): PopupMessage = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<PopupMessage> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<PopupMessage>): List<PopupMessage> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<PopupMessage>): List<Partial<PopupMessage>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): PopupMessage = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<PopupMessage>): List<PopupMessage> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: PopupMessage): PopupMessage = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: PopupMessage): PopupMessage = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<PopupMessage>): List<PopupMessage> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: PopupMessage): PopupMessage = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<PopupMessage>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PopupMessage>): EntryChange<PopupMessage> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<PopupMessage>): PopupMessage = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<PopupMessage>): PopupMessage = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<PopupMessage>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<PopupMessage>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<PopupMessage>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<PopupMessage>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<PopupMessage>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionPriceIncreaseApi(val api: Api.PriceIncreaseApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<PriceIncrease, UUID> {
        override suspend fun default(): PriceIncrease = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<PriceIncrease> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<PriceIncrease>): List<PriceIncrease> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<PriceIncrease>): List<Partial<PriceIncrease>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): PriceIncrease = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<PriceIncrease>): List<PriceIncrease> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: PriceIncrease): PriceIncrease = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: PriceIncrease): PriceIncrease = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<PriceIncrease>): List<PriceIncrease> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: PriceIncrease): PriceIncrease = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<PriceIncrease>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PriceIncrease>): EntryChange<PriceIncrease> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<PriceIncrease>): PriceIncrease = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<PriceIncrease>): PriceIncrease = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<PriceIncrease>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<PriceIncrease>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<PriceIncrease>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<PriceIncrease>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<PriceIncrease>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionSyncTaskStatusApi(val api: Api.SyncTaskStatusApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<SyncTaskStatus, String>, ClientModelRestEndpointsPlusUpdatesWebsocket<SyncTaskStatus, String> {
        override suspend fun default(): SyncTaskStatus = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<SyncTaskStatus> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<SyncTaskStatus>): List<SyncTaskStatus> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<SyncTaskStatus>): List<Partial<SyncTaskStatus>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: String): SyncTaskStatus = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<SyncTaskStatus>): List<SyncTaskStatus> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: SyncTaskStatus): SyncTaskStatus = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: String, input: SyncTaskStatus): SyncTaskStatus = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<SyncTaskStatus>): List<SyncTaskStatus> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: String, input: SyncTaskStatus): SyncTaskStatus = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<SyncTaskStatus>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: String, input: Modification<SyncTaskStatus>): EntryChange<SyncTaskStatus> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: String, input: Modification<SyncTaskStatus>): SyncTaskStatus = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: String, input: Partial<SyncTaskStatus>): SyncTaskStatus = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<SyncTaskStatus>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: String): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<SyncTaskStatus>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<SyncTaskStatus>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<SyncTaskStatus>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<SyncTaskStatus>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        override fun updates(): TypedWebSocket<Condition<SyncTaskStatus>, CollectionUpdates<SyncTaskStatus, String>> = api.updates(authToken)
    }
    class UserSessionPasswordSecretApi(val api: Api.PasswordSecretApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<PasswordSecret, UUID> {
        override suspend fun default(): PasswordSecret = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<PasswordSecret> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<PasswordSecret>): List<PasswordSecret> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<PasswordSecret>): List<Partial<PasswordSecret>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): PasswordSecret = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<PasswordSecret>): List<PasswordSecret> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: PasswordSecret): PasswordSecret = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: PasswordSecret): PasswordSecret = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<PasswordSecret>): List<PasswordSecret> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: PasswordSecret): PasswordSecret = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<PasswordSecret>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PasswordSecret>): EntryChange<PasswordSecret> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<PasswordSecret>): PasswordSecret = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<PasswordSecret>): PasswordSecret = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<PasswordSecret>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<PasswordSecret>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<PasswordSecret>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<PasswordSecret>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<PasswordSecret>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionPasswordProofApi(val api: Api.PasswordProofApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?) {
    }
    class UserSessionEmailProofApi(val api: Api.EmailProofApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?) {
    }
    class UserSessionUserAuthApi(val api: Api.UserAuthApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): AuthenticatedUserAuthClientEndpoints<User, UUID> {
        override suspend fun createSubSession(input: SubSessionRequest): String = api.createSubSession(input, accessToken, masquerade)
        override suspend fun getSelf(): User = api.getSelf(accessToken, masquerade)
        override suspend fun terminateSession(): Unit = api.terminateSession(accessToken, masquerade)
        override suspend fun terminateOtherSession(sessionId: UUID): Unit = api.terminateOtherSession(sessionId, accessToken, masquerade)
    }
    class UserSessionUserSessionApi(val api: Api.UserSessionApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Session<User, UUID>, UUID> {
        override suspend fun default(): Session<User, UUID> = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<Session<User, UUID>> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<Session<User, UUID>>): List<Session<User, UUID>> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Session<User, UUID>>): List<Partial<Session<User, UUID>>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): Session<User, UUID> = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<Session<User, UUID>>): List<Session<User, UUID>> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: Session<User, UUID>): Session<User, UUID> = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: Session<User, UUID>): Session<User, UUID> = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<Session<User, UUID>>): List<Session<User, UUID>> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: Session<User, UUID>): Session<User, UUID> = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Session<User, UUID>>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Session<User, UUID>>): EntryChange<Session<User, UUID>> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<Session<User, UUID>>): Session<User, UUID> = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<Session<User, UUID>>): Session<User, UUID> = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Session<User, UUID>>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<Session<User, UUID>>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Session<User, UUID>>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Session<User, UUID>>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Session<User, UUID>>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionFunnelSummaryApi(val api: Api.FunnelSummaryApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<FunnelSummary, UUID> {
        override suspend fun default(): FunnelSummary = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<FunnelSummary> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<FunnelSummary>): List<FunnelSummary> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<FunnelSummary>): List<Partial<FunnelSummary>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): FunnelSummary = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<FunnelSummary>): List<FunnelSummary> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: FunnelSummary): FunnelSummary = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: FunnelSummary): FunnelSummary = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<FunnelSummary>): List<FunnelSummary> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: FunnelSummary): FunnelSummary = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<FunnelSummary>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<FunnelSummary>): EntryChange<FunnelSummary> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<FunnelSummary>): FunnelSummary = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<FunnelSummary>): FunnelSummary = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<FunnelSummary>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<FunnelSummary>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<FunnelSummary>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<FunnelSummary>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<FunnelSummary>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionFunnelInstanceApi(val api: Api.FunnelInstanceApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<FunnelInstance, UUID> {
        override suspend fun default(): FunnelInstance = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<FunnelInstance> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<FunnelInstance>): List<FunnelInstance> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<FunnelInstance>): List<Partial<FunnelInstance>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): FunnelInstance = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<FunnelInstance>): List<FunnelInstance> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: FunnelInstance): FunnelInstance = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: FunnelInstance): FunnelInstance = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<FunnelInstance>): List<FunnelInstance> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: FunnelInstance): FunnelInstance = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<FunnelInstance>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<FunnelInstance>): EntryChange<FunnelInstance> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<FunnelInstance>): FunnelInstance = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<FunnelInstance>): FunnelInstance = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<FunnelInstance>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<FunnelInstance>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<FunnelInstance>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<FunnelInstance>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<FunnelInstance>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun getFunnelHealth(date: LocalDate): List<FunnelSummary> = api.getFunnelHealth(date, accessToken, masquerade)
        suspend fun summarizeFunnelsNow(input: LocalDate): Unit = api.summarizeFunnelsNow(input, accessToken, masquerade)
    }
    class UserSessionProductCategoryApi(val api: Api.ProductCategoryApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<ProductCategory, UUID> {
        override suspend fun default(): ProductCategory = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<ProductCategory> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<ProductCategory>): List<ProductCategory> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<ProductCategory>): List<Partial<ProductCategory>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): ProductCategory = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<ProductCategory>): List<ProductCategory> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: ProductCategory): ProductCategory = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: ProductCategory): ProductCategory = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<ProductCategory>): List<ProductCategory> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: ProductCategory): ProductCategory = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<ProductCategory>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ProductCategory>): EntryChange<ProductCategory> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<ProductCategory>): ProductCategory = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<ProductCategory>): ProductCategory = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<ProductCategory>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<ProductCategory>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<ProductCategory>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<ProductCategory>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<ProductCategory>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
    }
    class UserSessionProductApi(val api: Api.ProductApi,val authToken:String, val accessToken: suspend () -> String, val masquerade: String?): ClientModelRestEndpoints<Product, UUID> {
        override suspend fun default(): Product = api.default(accessToken, masquerade)
        override suspend fun permissions(): ModelPermissions<Product> = api.permissions(accessToken, masquerade)
        override suspend fun query(input: Query<Product>): List<Product> = api.query(input, accessToken, masquerade)
        override suspend fun queryPartial(input: QueryPartial<Product>): List<Partial<Product>> = api.queryPartial(input, accessToken, masquerade)
        override suspend fun detail(id: UUID): Product = api.detail(id, accessToken, masquerade)
        override suspend fun insertBulk(input: List<Product>): List<Product> = api.insertBulk(input, accessToken, masquerade)
        override suspend fun insert(input: Product): Product = api.insert(input, accessToken, masquerade)
        override suspend fun upsert(id: UUID, input: Product): Product = api.upsert(id, input, accessToken, masquerade)
        override suspend fun bulkReplace(input: List<Product>): List<Product> = api.bulkReplace(input, accessToken, masquerade)
        override suspend fun replace(id: UUID, input: Product): Product = api.replace(id, input, accessToken, masquerade)
        override suspend fun bulkModify(input: MassModification<Product>): Int = api.bulkModify(input, accessToken, masquerade)
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Product>): EntryChange<Product> = api.modifyWithDiff(id, input, accessToken, masquerade)
        override suspend fun modify(id: UUID, input: Modification<Product>): Product = api.modify(id, input, accessToken, masquerade)
        suspend fun simplifiedModify(id: UUID, input: Partial<Product>): Product = api.simplifiedModify(id, input, accessToken, masquerade)
        override suspend fun bulkDelete(input: Condition<Product>): Int = api.bulkDelete(input, accessToken, masquerade)
        override suspend fun delete(id: UUID): Unit = api.delete(id, accessToken, masquerade)
        override suspend fun count(input: Condition<Product>): Int = api.count(input, accessToken, masquerade)
        override suspend fun groupCount(input: GroupCountQuery<Product>): Map<String, Int> = api.groupCount(input, accessToken, masquerade)
        override suspend fun aggregate(input: AggregateQuery<Product>): Double? = api.aggregate(input, accessToken, masquerade)
        override suspend fun groupAggregate(input: GroupAggregateQuery<Product>): Map<String, Double?> = api.groupAggregate(input, accessToken, masquerade)
        suspend fun uploadProductSpreadsheet(input: ServerFile): Unit = api.uploadProductSpreadsheet(input, accessToken, masquerade)
        suspend fun exportProductSpreadsheet(): List<VBSpreadsheetProduct> = api.exportProductSpreadsheet(accessToken, masquerade)
    }
}

