package com.virtualrain.sdk

import com.lightningkite.kiteui.reactive.PersistentProperty
import kotlinx.serialization.Serializable

@Serializable
enum class ApiOption(val apiName: String, val http: String, val ws: String) {
    Staging("Staging", "https://staging.backend.app.vandenbussche.com", "https://staging.backend.app.vandenbussche.com"),
    Dev("Dev", "https://vanden-bussche-api.cs.lightningkite.com", "https://ws.vanden-bussche-api.cs.lightningkite.com"),
    Local("Local", "http://localhost:8080", "ws://localhost:8080"),
    LocalTestERP("Local (TestERP)", "http://localhost:8081", "ws://localhost:8081"),
    LocalMobile("Local (Mobile)", "http://10.0.2.2:8081", "ws://10.0.2.2:8081"),
    Jivie("JIvie", "https://jivie.lightningkite.com", "wss://jivie.lightningkite.com"),
    ;

    val api get() = LiveApi(http, ws)
    fun next(): ApiOption = entries[(ordinal + 1) % entries.size]
}

val selectedApi = PersistentProperty<ApiOption>("apiOption", ApiOption.Dev)