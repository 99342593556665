@file:UseContextualSerialization(UUID::class, Instant::class, ServerFile::class)

package com.vandenbussche.models

import com.lightningkite.UUID
import com.lightningkite.lightningdb.HasId
import com.lightningkite.lightningdb.References
import com.lightningkite.lightningserver.files.ServerFile
import com.lightningkite.now
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization

@Serializable
data class OrderMarch2025(
    override val _id: UUID = UUID.random(),
    @References(CustomerAccount::class) override val account: UUID,
    @References(User::class) val orderedBy: UUID? = null,

    val erpId: String? = null,
    @References(Cart::class) val fromCart: UUID? = null,

    @References(Warehouse::class) override val warehouse: UUID,
    @References(ShippingAddress::class) override val shipTo: UUID? = null,

    override val items: Set<CartItem>,

    val listPriceTotal: PriceInCents = 0.cents,
    val total: PriceInCents,

//    @References(VBErpContact::class) val contactErpID: Double? = null,
//    val contactEmail: String? = null,
    override val shipASAP: Boolean = false,

    val fulfillmentExpectation: Instant? = null,
    val orderedAt: Instant = now(),
    val status: OrderStatus = OrderStatus(OrderStatusCode.Ordered),
    val uploadedToErp: Boolean = false,
    val comment: String = "",
    val poNum: String = "",

    val hidden: Boolean = false,

    val requestJson: String = "",
    val responseJson: String = ""
) : HasId<UUID>, BelongsToAccount, Priceable