package com.vandenbussche

import com.lightningkite.kiteui.PlatformStorage
import com.lightningkite.kiteui.Throwable_report
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.navigation.ScreenNavigator
import com.lightningkite.kiteui.printStackTrace2
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.RContext
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.ViewWriter
import com.vandenbussche.sdk.ApiOption
import com.vandenbussche.sdk.selectedApi
import com.vandenbussche.theming.appTheme
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlin.coroutines.CoroutineContext
import kotlin.js.json

fun main() {
    val host = window.location.hostname
    val defaultOption = when {
        host.contains("lightningkite") -> ApiOption.Dev
        host.contains("stag") -> ApiOption.Staging
        host.contains("localhost") -> ApiOption.Local
//        host.contains("jivie") -> ApiOption.Jivie
        else -> null
    }.also { println("Host is $host, default is $it") }?.let {
        if(PlatformStorage.get("apiOption") == null) {
            selectedApi.value = it
        }
    }

    val writer = object : ViewWriter() {
        override val context: RContext = RContext("/")
        override val coroutineContext: CoroutineContext
            get() = GlobalScope.coroutineContext

        override fun addChild(view: RView) {
            document.body?.append(view.native.create())
        }
    }

    writer.beforeNextElementSetup {
        ::themeChoice { ThemeDerivation(appTheme.await()) }
    }

    writer.app(ScreenNavigator { AutoRoutes }, ScreenNavigator { AutoRoutes })

    com.vandenbussche.sentry.init(
        json(
            "dsn" to "https://37d7d3af6af77d63f1d4038e4d290f71@sentry24.lightningkite.com/16"
        )
    )
    Throwable_report = {ex, ctx ->
        ex.printStackTrace2()
        com.vandenbussche.sentry.captureException(ex)
    }
}