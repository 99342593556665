
package com.vandenbussche.sdk

import com.lightningkite.*
import com.lightningkite.lightningdb.*
import com.lightningkite.kiteui.*
import kotlinx.datetime.*
import com.lightningkite.serialization.*
import com.lightningkite.lightningserver.db.*
import com.lightningkite.lightningserver.auth.*
import com.vandenbussche.models.PricingRequestInfo
import com.vandenbussche.models.CartItem
import kotlin.Long
import com.vandenbussche.models.VBErpContact
import com.lightningkite.lightningserver.serverhealth.ServerHealth
import com.lightningkite.lightningserver.typed.BulkRequest
import com.lightningkite.lightningserver.typed.BulkResponse
import com.lightningkite.lightningserver.files.UploadInformation
import kotlin.String
import com.vandenbussche.models.Manufacturer
import com.lightningkite.lightningdb.ModelPermissions
import com.lightningkite.lightningdb.Query
import com.lightningkite.lightningdb.QueryPartial
import com.lightningkite.serialization.Partial
import com.lightningkite.lightningdb.MassModification
import kotlin.Int
import com.lightningkite.lightningdb.Modification
import com.lightningkite.lightningdb.EntryChange
import com.lightningkite.lightningdb.Condition
import kotlin.Unit
import com.lightningkite.lightningdb.GroupCountQuery
import com.lightningkite.lightningdb.AggregateQuery
import kotlin.Double
import com.lightningkite.lightningdb.GroupAggregateQuery
import com.vandenbussche.models.Warehouse
import com.lightningkite.UUID
import com.vandenbussche.models.Inventory
import com.vandenbussche.models.ProductWarehouse
import com.vandenbussche.models.CustomerAccount
import com.vandenbussche.models.User
import com.vandenbussche.models.CreateUser
import kotlin.Boolean
import com.vandenbussche.models.ShippingAddress
import com.vandenbussche.models.Order
import com.vandenbussche.models.Cart
import com.vandenbussche.models.Favorite
import com.vandenbussche.models.AccountProduct
import com.vandenbussche.models.PopupMessage
import com.vandenbussche.models.PriceIncrease
import com.vandenbussche.models.SyncTaskStatus
import com.lightningkite.lightningdb.CollectionUpdates
import com.lightningkite.lightningserver.auth.proof.PasswordSecret
import com.lightningkite.lightningserver.auth.proof.EstablishPassword
import com.lightningkite.lightningserver.auth.proof.IdentificationAndPassword
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningkite.lightningserver.auth.subject.IdAndAuthMethods
import com.lightningkite.lightningserver.auth.subject.LogInRequest
import com.lightningkite.lightningserver.auth.subject.ProofsCheckResult
import com.lightningkite.lightningserver.auth.subject.SubSessionRequest
import com.lightningkite.lightningserver.auth.oauth.OauthTokenRequest
import com.lightningkite.lightningserver.auth.oauth.OauthResponse
import com.lightningkite.lightningserver.auth.subject.Session
import com.lightningkite.lightningserver.monitoring.FunnelSummary
import com.lightningkite.lightningserver.monitoring.FunnelInstance
import kotlinx.datetime.LocalDate
import com.lightningkite.lightningserver.monitoring.FunnelStart
import com.vandenbussche.models.ProductCategory
import com.vandenbussche.models.Product
import com.lightningkite.lightningserver.files.ServerFile
import com.vandenbussche.models.VBSpreadsheetProduct

class LiveApi(val httpUrl: String, val socketUrl: String): Api {
    override val manufacturer: Api.ManufacturerApi = LiveManufacturerApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val warehouse: Api.WarehouseApi = LiveWarehouseApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val inventory: Api.InventoryApi = LiveInventoryApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val customerAccount: Api.CustomerAccountApi = LiveCustomerAccountApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val user: Api.UserApi = LiveUserApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val shippingAddress: Api.ShippingAddressApi = LiveShippingAddressApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val order: Api.OrderApi = LiveOrderApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val cart: Api.CartApi = LiveCartApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val favorite: Api.FavoriteApi = LiveFavoriteApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val popupMessage: Api.PopupMessageApi = LivePopupMessageApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val priceIncrease: Api.PriceIncreaseApi = LivePriceIncreaseApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val syncTaskStatus: Api.SyncTaskStatusApi = LiveSyncTaskStatusApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val passwordSecret: Api.PasswordSecretApi = LivePasswordSecretApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val passwordProof: Api.PasswordProofApi = LivePasswordProofApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val emailProof: Api.EmailProofApi = LiveEmailProofApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val userAuth: Api.UserAuthApi = LiveUserAuthApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val userSession: Api.UserSessionApi = LiveUserSessionApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val funnelSummary: Api.FunnelSummaryApi = LiveFunnelSummaryApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val funnelInstance: Api.FunnelInstanceApi = LiveFunnelInstanceApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val productCategory: Api.ProductCategoryApi = LiveProductCategoryApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override val product: Api.ProductApi = LiveProductApi(httpUrl = httpUrl, socketUrl = socketUrl)
    override suspend fun getERPPricing(input: PricingRequestInfo, accessToken: suspend () -> String, masquerade: String?): List<CartItem> = fetch(
        url = "$httpUrl/pricing",
        method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        body = input
    )
    override suspend fun getContactsForAccount(input: Long, accessToken: suspend () -> String, masquerade: String?): List<VBErpContact> = fetch(
        url = "$httpUrl/contacts",
        method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        body = input
    )
    override suspend fun getServerHealth(): ServerHealth = fetch(
        url = "$httpUrl/meta/health",
        method = HttpMethod.GET,
    )
    override suspend fun bulkRequest(input: Map<String, BulkRequest>): Map<String, BulkResponse> = fetch(
        url = "$httpUrl/meta/bulk",
        method = HttpMethod.POST,
        body = input
    )
    override suspend fun uploadFileForRequest(): UploadInformation = fetch(
        url = "$httpUrl/upload-early",
        method = HttpMethod.GET,
    )
    override suspend fun verifyUploadedFile(input: String): String = fetch(
        url = "$httpUrl/upload-early/verify",
        method = HttpMethod.POST,
        body = input
    )
    override suspend fun syncNow(accessToken: suspend () -> String, masquerade: String?): String = fetch(
        url = "$httpUrl/sync-now",
        method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
    )
    override suspend fun getSyncTasks(accessToken: suspend () -> String, masquerade: String?): List<String> = fetch(
        url = "$httpUrl/sync-now",
        method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
    )
    override suspend fun syncNowPartial(id: String, accessToken: suspend () -> String, masquerade: String?): String = fetch(
        url = "$httpUrl/sync-now/${id.urlify()}",
        method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
    )
    class LiveManufacturerApi(val httpUrl: String, val socketUrl: String): Api.ManufacturerApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): Manufacturer = fetch(
            url = "$httpUrl/manufacturers/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<Manufacturer> = fetch(
            url = "$httpUrl/manufacturers/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Manufacturer>, accessToken: suspend () -> String, masquerade: String?): List<Manufacturer> = fetch(
            url = "$httpUrl/manufacturers/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Manufacturer>, accessToken: suspend () -> String, masquerade: String?): List<Partial<Manufacturer>> = fetch(
            url = "$httpUrl/manufacturers/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: String, accessToken: suspend () -> String, masquerade: String?): Manufacturer = fetch(
            url = "$httpUrl/manufacturers/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Manufacturer>, accessToken: suspend () -> String, masquerade: String?): List<Manufacturer> = fetch(
            url = "$httpUrl/manufacturers/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Manufacturer, accessToken: suspend () -> String, masquerade: String?): Manufacturer = fetch(
            url = "$httpUrl/manufacturers",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: String, input: Manufacturer, accessToken: suspend () -> String, masquerade: String?): Manufacturer = fetch(
            url = "$httpUrl/manufacturers/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Manufacturer>, accessToken: suspend () -> String, masquerade: String?): List<Manufacturer> = fetch(
            url = "$httpUrl/manufacturers",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: String, input: Manufacturer, accessToken: suspend () -> String, masquerade: String?): Manufacturer = fetch(
            url = "$httpUrl/manufacturers/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Manufacturer>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/manufacturers/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: String, input: Modification<Manufacturer>, accessToken: suspend () -> String, masquerade: String?): EntryChange<Manufacturer> = fetch(
            url = "$httpUrl/manufacturers/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: String, input: Modification<Manufacturer>, accessToken: suspend () -> String, masquerade: String?): Manufacturer = fetch(
            url = "$httpUrl/manufacturers/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: String, input: Partial<Manufacturer>, accessToken: suspend () -> String, masquerade: String?): Manufacturer = fetch(
            url = "$httpUrl/manufacturers/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Manufacturer>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/manufacturers/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: String, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/manufacturers/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Manufacturer>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/manufacturers/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Manufacturer>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/manufacturers/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Manufacturer>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/manufacturers/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Manufacturer>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/manufacturers/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveWarehouseApi(val httpUrl: String, val socketUrl: String): Api.WarehouseApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): Warehouse = fetch(
            url = "$httpUrl/warehouses/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<Warehouse> = fetch(
            url = "$httpUrl/warehouses/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Warehouse>, accessToken: suspend () -> String, masquerade: String?): List<Warehouse> = fetch(
            url = "$httpUrl/warehouses/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Warehouse>, accessToken: suspend () -> String, masquerade: String?): List<Partial<Warehouse>> = fetch(
            url = "$httpUrl/warehouses/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): Warehouse = fetch(
            url = "$httpUrl/warehouses/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Warehouse>, accessToken: suspend () -> String, masquerade: String?): List<Warehouse> = fetch(
            url = "$httpUrl/warehouses/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Warehouse, accessToken: suspend () -> String, masquerade: String?): Warehouse = fetch(
            url = "$httpUrl/warehouses",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Warehouse, accessToken: suspend () -> String, masquerade: String?): Warehouse = fetch(
            url = "$httpUrl/warehouses/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Warehouse>, accessToken: suspend () -> String, masquerade: String?): List<Warehouse> = fetch(
            url = "$httpUrl/warehouses",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: Warehouse, accessToken: suspend () -> String, masquerade: String?): Warehouse = fetch(
            url = "$httpUrl/warehouses/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Warehouse>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/warehouses/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Warehouse>, accessToken: suspend () -> String, masquerade: String?): EntryChange<Warehouse> = fetch(
            url = "$httpUrl/warehouses/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Warehouse>, accessToken: suspend () -> String, masquerade: String?): Warehouse = fetch(
            url = "$httpUrl/warehouses/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<Warehouse>, accessToken: suspend () -> String, masquerade: String?): Warehouse = fetch(
            url = "$httpUrl/warehouses/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Warehouse>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/warehouses/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/warehouses/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Warehouse>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/warehouses/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Warehouse>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/warehouses/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Warehouse>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/warehouses/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Warehouse>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/warehouses/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveInventoryApi(val httpUrl: String, val socketUrl: String): Api.InventoryApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): Inventory = fetch(
            url = "$httpUrl/inventories/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<Inventory> = fetch(
            url = "$httpUrl/inventories/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Inventory>, accessToken: suspend () -> String, masquerade: String?): List<Inventory> = fetch(
            url = "$httpUrl/inventories/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Inventory>, accessToken: suspend () -> String, masquerade: String?): List<Partial<Inventory>> = fetch(
            url = "$httpUrl/inventories/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: ProductWarehouse, accessToken: suspend () -> String, masquerade: String?): Inventory = fetch(
            url = "$httpUrl/inventories/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Inventory>, accessToken: suspend () -> String, masquerade: String?): List<Inventory> = fetch(
            url = "$httpUrl/inventories/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Inventory, accessToken: suspend () -> String, masquerade: String?): Inventory = fetch(
            url = "$httpUrl/inventories",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: ProductWarehouse, input: Inventory, accessToken: suspend () -> String, masquerade: String?): Inventory = fetch(
            url = "$httpUrl/inventories/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Inventory>, accessToken: suspend () -> String, masquerade: String?): List<Inventory> = fetch(
            url = "$httpUrl/inventories",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: ProductWarehouse, input: Inventory, accessToken: suspend () -> String, masquerade: String?): Inventory = fetch(
            url = "$httpUrl/inventories/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Inventory>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/inventories/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: ProductWarehouse, input: Modification<Inventory>, accessToken: suspend () -> String, masquerade: String?): EntryChange<Inventory> = fetch(
            url = "$httpUrl/inventories/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: ProductWarehouse, input: Modification<Inventory>, accessToken: suspend () -> String, masquerade: String?): Inventory = fetch(
            url = "$httpUrl/inventories/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: ProductWarehouse, input: Partial<Inventory>, accessToken: suspend () -> String, masquerade: String?): Inventory = fetch(
            url = "$httpUrl/inventories/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Inventory>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/inventories/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: ProductWarehouse, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/inventories/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Inventory>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/inventories/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Inventory>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/inventories/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Inventory>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/inventories/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Inventory>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/inventories/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveCustomerAccountApi(val httpUrl: String, val socketUrl: String): Api.CustomerAccountApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): CustomerAccount = fetch(
            url = "$httpUrl/customer-accounts/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<CustomerAccount> = fetch(
            url = "$httpUrl/customer-accounts/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<CustomerAccount>, accessToken: suspend () -> String, masquerade: String?): List<CustomerAccount> = fetch(
            url = "$httpUrl/customer-accounts/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<CustomerAccount>, accessToken: suspend () -> String, masquerade: String?): List<Partial<CustomerAccount>> = fetch(
            url = "$httpUrl/customer-accounts/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): CustomerAccount = fetch(
            url = "$httpUrl/customer-accounts/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<CustomerAccount>, accessToken: suspend () -> String, masquerade: String?): List<CustomerAccount> = fetch(
            url = "$httpUrl/customer-accounts/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: CustomerAccount, accessToken: suspend () -> String, masquerade: String?): CustomerAccount = fetch(
            url = "$httpUrl/customer-accounts",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: CustomerAccount, accessToken: suspend () -> String, masquerade: String?): CustomerAccount = fetch(
            url = "$httpUrl/customer-accounts/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<CustomerAccount>, accessToken: suspend () -> String, masquerade: String?): List<CustomerAccount> = fetch(
            url = "$httpUrl/customer-accounts",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: CustomerAccount, accessToken: suspend () -> String, masquerade: String?): CustomerAccount = fetch(
            url = "$httpUrl/customer-accounts/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<CustomerAccount>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/customer-accounts/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<CustomerAccount>, accessToken: suspend () -> String, masquerade: String?): EntryChange<CustomerAccount> = fetch(
            url = "$httpUrl/customer-accounts/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<CustomerAccount>, accessToken: suspend () -> String, masquerade: String?): CustomerAccount = fetch(
            url = "$httpUrl/customer-accounts/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<CustomerAccount>, accessToken: suspend () -> String, masquerade: String?): CustomerAccount = fetch(
            url = "$httpUrl/customer-accounts/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<CustomerAccount>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/customer-accounts/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/customer-accounts/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<CustomerAccount>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/customer-accounts/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<CustomerAccount>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/customer-accounts/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<CustomerAccount>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/customer-accounts/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<CustomerAccount>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/customer-accounts/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveUserApi(val httpUrl: String, val socketUrl: String): Api.UserApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/customer-accounts/users/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<User> = fetch(
            url = "$httpUrl/customer-accounts/users/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<User>, accessToken: suspend () -> String, masquerade: String?): List<User> = fetch(
            url = "$httpUrl/customer-accounts/users/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<User>, accessToken: suspend () -> String, masquerade: String?): List<Partial<User>> = fetch(
            url = "$httpUrl/customer-accounts/users/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/customer-accounts/users/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<User>, accessToken: suspend () -> String, masquerade: String?): List<User> = fetch(
            url = "$httpUrl/customer-accounts/users/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: User, accessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/customer-accounts/users",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: User, accessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/customer-accounts/users/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<User>, accessToken: suspend () -> String, masquerade: String?): List<User> = fetch(
            url = "$httpUrl/customer-accounts/users",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: User, accessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/customer-accounts/users/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<User>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/customer-accounts/users/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<User>, accessToken: suspend () -> String, masquerade: String?): EntryChange<User> = fetch(
            url = "$httpUrl/customer-accounts/users/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<User>, accessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/customer-accounts/users/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<User>, accessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/customer-accounts/users/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<User>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/customer-accounts/users/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/customer-accounts/users/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<User>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/customer-accounts/users/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<User>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/customer-accounts/users/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<User>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/customer-accounts/users/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<User>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/customer-accounts/users/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun create(input: CreateUser): User = fetch(
            url = "$httpUrl/customer-accounts/users/new",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun emailRegistered(input: String): Boolean = fetch(
            url = "$httpUrl/customer-accounts/users/registered",
            method = HttpMethod.POST,
            body = input
        )
    }
    class LiveShippingAddressApi(val httpUrl: String, val socketUrl: String): Api.ShippingAddressApi {
        override suspend fun default(accessToken: (suspend () -> String)?, masquerade: String?): ShippingAddress = fetch(
            url = "$httpUrl/shipping-addresses/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: (suspend () -> String)?, masquerade: String?): ModelPermissions<ShippingAddress> = fetch(
            url = "$httpUrl/shipping-addresses/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<ShippingAddress>, accessToken: (suspend () -> String)?, masquerade: String?): List<ShippingAddress> = fetch(
            url = "$httpUrl/shipping-addresses/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<ShippingAddress>, accessToken: (suspend () -> String)?, masquerade: String?): List<Partial<ShippingAddress>> = fetch(
            url = "$httpUrl/shipping-addresses/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: (suspend () -> String)?, masquerade: String?): ShippingAddress = fetch(
            url = "$httpUrl/shipping-addresses/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<ShippingAddress>, accessToken: (suspend () -> String)?, masquerade: String?): List<ShippingAddress> = fetch(
            url = "$httpUrl/shipping-addresses/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: ShippingAddress, accessToken: (suspend () -> String)?, masquerade: String?): ShippingAddress = fetch(
            url = "$httpUrl/shipping-addresses",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: ShippingAddress, accessToken: (suspend () -> String)?, masquerade: String?): ShippingAddress = fetch(
            url = "$httpUrl/shipping-addresses/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<ShippingAddress>, accessToken: (suspend () -> String)?, masquerade: String?): List<ShippingAddress> = fetch(
            url = "$httpUrl/shipping-addresses",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: ShippingAddress, accessToken: (suspend () -> String)?, masquerade: String?): ShippingAddress = fetch(
            url = "$httpUrl/shipping-addresses/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<ShippingAddress>, accessToken: (suspend () -> String)?, masquerade: String?): Int = fetch(
            url = "$httpUrl/shipping-addresses/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ShippingAddress>, accessToken: (suspend () -> String)?, masquerade: String?): EntryChange<ShippingAddress> = fetch(
            url = "$httpUrl/shipping-addresses/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<ShippingAddress>, accessToken: (suspend () -> String)?, masquerade: String?): ShippingAddress = fetch(
            url = "$httpUrl/shipping-addresses/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<ShippingAddress>, accessToken: (suspend () -> String)?, masquerade: String?): ShippingAddress = fetch(
            url = "$httpUrl/shipping-addresses/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<ShippingAddress>, accessToken: (suspend () -> String)?, masquerade: String?): Int = fetch(
            url = "$httpUrl/shipping-addresses/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: (suspend () -> String)?, masquerade: String?): Unit = fetch(
            url = "$httpUrl/shipping-addresses/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<ShippingAddress>, accessToken: (suspend () -> String)?, masquerade: String?): Int = fetch(
            url = "$httpUrl/shipping-addresses/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<ShippingAddress>, accessToken: (suspend () -> String)?, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/shipping-addresses/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<ShippingAddress>, accessToken: (suspend () -> String)?, masquerade: String?): Double? = fetch(
            url = "$httpUrl/shipping-addresses/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<ShippingAddress>, accessToken: (suspend () -> String)?, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/shipping-addresses/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveOrderApi(val httpUrl: String, val socketUrl: String): Api.OrderApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): Order = fetch(
            url = "$httpUrl/orders/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<Order> = fetch(
            url = "$httpUrl/orders/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Order>, accessToken: suspend () -> String, masquerade: String?): List<Order> = fetch(
            url = "$httpUrl/orders/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Order>, accessToken: suspend () -> String, masquerade: String?): List<Partial<Order>> = fetch(
            url = "$httpUrl/orders/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): Order = fetch(
            url = "$httpUrl/orders/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Order>, accessToken: suspend () -> String, masquerade: String?): List<Order> = fetch(
            url = "$httpUrl/orders/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Order, accessToken: suspend () -> String, masquerade: String?): Order = fetch(
            url = "$httpUrl/orders",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Order, accessToken: suspend () -> String, masquerade: String?): Order = fetch(
            url = "$httpUrl/orders/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Order>, accessToken: suspend () -> String, masquerade: String?): List<Order> = fetch(
            url = "$httpUrl/orders",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: Order, accessToken: suspend () -> String, masquerade: String?): Order = fetch(
            url = "$httpUrl/orders/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Order>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/orders/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Order>, accessToken: suspend () -> String, masquerade: String?): EntryChange<Order> = fetch(
            url = "$httpUrl/orders/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Order>, accessToken: suspend () -> String, masquerade: String?): Order = fetch(
            url = "$httpUrl/orders/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<Order>, accessToken: suspend () -> String, masquerade: String?): Order = fetch(
            url = "$httpUrl/orders/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Order>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/orders/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/orders/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Order>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/orders/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Order>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/orders/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Order>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/orders/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Order>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/orders/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveCartApi(val httpUrl: String, val socketUrl: String): Api.CartApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): Cart = fetch(
            url = "$httpUrl/carts/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<Cart> = fetch(
            url = "$httpUrl/carts/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Cart>, accessToken: suspend () -> String, masquerade: String?): List<Cart> = fetch(
            url = "$httpUrl/carts/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Cart>, accessToken: suspend () -> String, masquerade: String?): List<Partial<Cart>> = fetch(
            url = "$httpUrl/carts/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): Cart = fetch(
            url = "$httpUrl/carts/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Cart>, accessToken: suspend () -> String, masquerade: String?): List<Cart> = fetch(
            url = "$httpUrl/carts/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Cart, accessToken: suspend () -> String, masquerade: String?): Cart = fetch(
            url = "$httpUrl/carts",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Cart, accessToken: suspend () -> String, masquerade: String?): Cart = fetch(
            url = "$httpUrl/carts/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Cart>, accessToken: suspend () -> String, masquerade: String?): List<Cart> = fetch(
            url = "$httpUrl/carts",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: Cart, accessToken: suspend () -> String, masquerade: String?): Cart = fetch(
            url = "$httpUrl/carts/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Cart>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/carts/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Cart>, accessToken: suspend () -> String, masquerade: String?): EntryChange<Cart> = fetch(
            url = "$httpUrl/carts/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Cart>, accessToken: suspend () -> String, masquerade: String?): Cart = fetch(
            url = "$httpUrl/carts/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<Cart>, accessToken: suspend () -> String, masquerade: String?): Cart = fetch(
            url = "$httpUrl/carts/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Cart>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/carts/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/carts/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Cart>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/carts/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Cart>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/carts/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Cart>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/carts/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Cart>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/carts/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveFavoriteApi(val httpUrl: String, val socketUrl: String): Api.FavoriteApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): Favorite = fetch(
            url = "$httpUrl/favorites/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<Favorite> = fetch(
            url = "$httpUrl/favorites/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Favorite>, accessToken: suspend () -> String, masquerade: String?): List<Favorite> = fetch(
            url = "$httpUrl/favorites/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Favorite>, accessToken: suspend () -> String, masquerade: String?): List<Partial<Favorite>> = fetch(
            url = "$httpUrl/favorites/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: AccountProduct, accessToken: suspend () -> String, masquerade: String?): Favorite = fetch(
            url = "$httpUrl/favorites/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Favorite>, accessToken: suspend () -> String, masquerade: String?): List<Favorite> = fetch(
            url = "$httpUrl/favorites/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Favorite, accessToken: suspend () -> String, masquerade: String?): Favorite = fetch(
            url = "$httpUrl/favorites",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: AccountProduct, input: Favorite, accessToken: suspend () -> String, masquerade: String?): Favorite = fetch(
            url = "$httpUrl/favorites/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Favorite>, accessToken: suspend () -> String, masquerade: String?): List<Favorite> = fetch(
            url = "$httpUrl/favorites",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: AccountProduct, input: Favorite, accessToken: suspend () -> String, masquerade: String?): Favorite = fetch(
            url = "$httpUrl/favorites/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Favorite>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/favorites/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: AccountProduct, input: Modification<Favorite>, accessToken: suspend () -> String, masquerade: String?): EntryChange<Favorite> = fetch(
            url = "$httpUrl/favorites/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: AccountProduct, input: Modification<Favorite>, accessToken: suspend () -> String, masquerade: String?): Favorite = fetch(
            url = "$httpUrl/favorites/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: AccountProduct, input: Partial<Favorite>, accessToken: suspend () -> String, masquerade: String?): Favorite = fetch(
            url = "$httpUrl/favorites/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Favorite>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/favorites/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: AccountProduct, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/favorites/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Favorite>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/favorites/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Favorite>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/favorites/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Favorite>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/favorites/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Favorite>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/favorites/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LivePopupMessageApi(val httpUrl: String, val socketUrl: String): Api.PopupMessageApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): PopupMessage = fetch(
            url = "$httpUrl/popup-messages/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<PopupMessage> = fetch(
            url = "$httpUrl/popup-messages/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<PopupMessage>, accessToken: suspend () -> String, masquerade: String?): List<PopupMessage> = fetch(
            url = "$httpUrl/popup-messages/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<PopupMessage>, accessToken: suspend () -> String, masquerade: String?): List<Partial<PopupMessage>> = fetch(
            url = "$httpUrl/popup-messages/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): PopupMessage = fetch(
            url = "$httpUrl/popup-messages/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<PopupMessage>, accessToken: suspend () -> String, masquerade: String?): List<PopupMessage> = fetch(
            url = "$httpUrl/popup-messages/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: PopupMessage, accessToken: suspend () -> String, masquerade: String?): PopupMessage = fetch(
            url = "$httpUrl/popup-messages",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: PopupMessage, accessToken: suspend () -> String, masquerade: String?): PopupMessage = fetch(
            url = "$httpUrl/popup-messages/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<PopupMessage>, accessToken: suspend () -> String, masquerade: String?): List<PopupMessage> = fetch(
            url = "$httpUrl/popup-messages",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: PopupMessage, accessToken: suspend () -> String, masquerade: String?): PopupMessage = fetch(
            url = "$httpUrl/popup-messages/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<PopupMessage>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/popup-messages/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PopupMessage>, accessToken: suspend () -> String, masquerade: String?): EntryChange<PopupMessage> = fetch(
            url = "$httpUrl/popup-messages/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<PopupMessage>, accessToken: suspend () -> String, masquerade: String?): PopupMessage = fetch(
            url = "$httpUrl/popup-messages/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<PopupMessage>, accessToken: suspend () -> String, masquerade: String?): PopupMessage = fetch(
            url = "$httpUrl/popup-messages/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<PopupMessage>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/popup-messages/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/popup-messages/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<PopupMessage>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/popup-messages/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<PopupMessage>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/popup-messages/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<PopupMessage>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/popup-messages/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<PopupMessage>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/popup-messages/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LivePriceIncreaseApi(val httpUrl: String, val socketUrl: String): Api.PriceIncreaseApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): PriceIncrease = fetch(
            url = "$httpUrl/price-increases/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<PriceIncrease> = fetch(
            url = "$httpUrl/price-increases/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<PriceIncrease>, accessToken: suspend () -> String, masquerade: String?): List<PriceIncrease> = fetch(
            url = "$httpUrl/price-increases/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<PriceIncrease>, accessToken: suspend () -> String, masquerade: String?): List<Partial<PriceIncrease>> = fetch(
            url = "$httpUrl/price-increases/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): PriceIncrease = fetch(
            url = "$httpUrl/price-increases/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<PriceIncrease>, accessToken: suspend () -> String, masquerade: String?): List<PriceIncrease> = fetch(
            url = "$httpUrl/price-increases/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: PriceIncrease, accessToken: suspend () -> String, masquerade: String?): PriceIncrease = fetch(
            url = "$httpUrl/price-increases",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: PriceIncrease, accessToken: suspend () -> String, masquerade: String?): PriceIncrease = fetch(
            url = "$httpUrl/price-increases/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<PriceIncrease>, accessToken: suspend () -> String, masquerade: String?): List<PriceIncrease> = fetch(
            url = "$httpUrl/price-increases",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: PriceIncrease, accessToken: suspend () -> String, masquerade: String?): PriceIncrease = fetch(
            url = "$httpUrl/price-increases/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<PriceIncrease>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/price-increases/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PriceIncrease>, accessToken: suspend () -> String, masquerade: String?): EntryChange<PriceIncrease> = fetch(
            url = "$httpUrl/price-increases/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<PriceIncrease>, accessToken: suspend () -> String, masquerade: String?): PriceIncrease = fetch(
            url = "$httpUrl/price-increases/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<PriceIncrease>, accessToken: suspend () -> String, masquerade: String?): PriceIncrease = fetch(
            url = "$httpUrl/price-increases/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<PriceIncrease>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/price-increases/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/price-increases/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<PriceIncrease>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/price-increases/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<PriceIncrease>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/price-increases/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<PriceIncrease>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/price-increases/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<PriceIncrease>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/price-increases/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveSyncTaskStatusApi(val httpUrl: String, val socketUrl: String): Api.SyncTaskStatusApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): SyncTaskStatus = fetch(
            url = "$httpUrl/sync-task-status/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<SyncTaskStatus> = fetch(
            url = "$httpUrl/sync-task-status/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<SyncTaskStatus>, accessToken: suspend () -> String, masquerade: String?): List<SyncTaskStatus> = fetch(
            url = "$httpUrl/sync-task-status/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<SyncTaskStatus>, accessToken: suspend () -> String, masquerade: String?): List<Partial<SyncTaskStatus>> = fetch(
            url = "$httpUrl/sync-task-status/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: String, accessToken: suspend () -> String, masquerade: String?): SyncTaskStatus = fetch(
            url = "$httpUrl/sync-task-status/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<SyncTaskStatus>, accessToken: suspend () -> String, masquerade: String?): List<SyncTaskStatus> = fetch(
            url = "$httpUrl/sync-task-status/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: SyncTaskStatus, accessToken: suspend () -> String, masquerade: String?): SyncTaskStatus = fetch(
            url = "$httpUrl/sync-task-status",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: String, input: SyncTaskStatus, accessToken: suspend () -> String, masquerade: String?): SyncTaskStatus = fetch(
            url = "$httpUrl/sync-task-status/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<SyncTaskStatus>, accessToken: suspend () -> String, masquerade: String?): List<SyncTaskStatus> = fetch(
            url = "$httpUrl/sync-task-status",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: String, input: SyncTaskStatus, accessToken: suspend () -> String, masquerade: String?): SyncTaskStatus = fetch(
            url = "$httpUrl/sync-task-status/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<SyncTaskStatus>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/sync-task-status/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: String, input: Modification<SyncTaskStatus>, accessToken: suspend () -> String, masquerade: String?): EntryChange<SyncTaskStatus> = fetch(
            url = "$httpUrl/sync-task-status/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: String, input: Modification<SyncTaskStatus>, accessToken: suspend () -> String, masquerade: String?): SyncTaskStatus = fetch(
            url = "$httpUrl/sync-task-status/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: String, input: Partial<SyncTaskStatus>, accessToken: suspend () -> String, masquerade: String?): SyncTaskStatus = fetch(
            url = "$httpUrl/sync-task-status/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<SyncTaskStatus>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/sync-task-status/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: String, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/sync-task-status/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<SyncTaskStatus>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/sync-task-status/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<SyncTaskStatus>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/sync-task-status/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<SyncTaskStatus>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/sync-task-status/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<SyncTaskStatus>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/sync-task-status/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override fun updates(authToken: String): TypedWebSocket<Condition<SyncTaskStatus>, CollectionUpdates<SyncTaskStatus, String>> = multiplexedSocket(
            socketUrl = socketUrl, 
            path = "/sync-task-status", 
            token = authToken,
        )
    }
    class LivePasswordSecretApi(val httpUrl: String, val socketUrl: String): Api.PasswordSecretApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): PasswordSecret = fetch(
            url = "$httpUrl/auth/proof/password/secrets/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<PasswordSecret> = fetch(
            url = "$httpUrl/auth/proof/password/secrets/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<PasswordSecret>, accessToken: suspend () -> String, masquerade: String?): List<PasswordSecret> = fetch(
            url = "$httpUrl/auth/proof/password/secrets/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<PasswordSecret>, accessToken: suspend () -> String, masquerade: String?): List<Partial<PasswordSecret>> = fetch(
            url = "$httpUrl/auth/proof/password/secrets/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): PasswordSecret = fetch(
            url = "$httpUrl/auth/proof/password/secrets/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<PasswordSecret>, accessToken: suspend () -> String, masquerade: String?): List<PasswordSecret> = fetch(
            url = "$httpUrl/auth/proof/password/secrets/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: PasswordSecret, accessToken: suspend () -> String, masquerade: String?): PasswordSecret = fetch(
            url = "$httpUrl/auth/proof/password/secrets",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: PasswordSecret, accessToken: suspend () -> String, masquerade: String?): PasswordSecret = fetch(
            url = "$httpUrl/auth/proof/password/secrets/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<PasswordSecret>, accessToken: suspend () -> String, masquerade: String?): List<PasswordSecret> = fetch(
            url = "$httpUrl/auth/proof/password/secrets",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: PasswordSecret, accessToken: suspend () -> String, masquerade: String?): PasswordSecret = fetch(
            url = "$httpUrl/auth/proof/password/secrets/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<PasswordSecret>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/auth/proof/password/secrets/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<PasswordSecret>, accessToken: suspend () -> String, masquerade: String?): EntryChange<PasswordSecret> = fetch(
            url = "$httpUrl/auth/proof/password/secrets/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<PasswordSecret>, accessToken: suspend () -> String, masquerade: String?): PasswordSecret = fetch(
            url = "$httpUrl/auth/proof/password/secrets/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<PasswordSecret>, accessToken: suspend () -> String, masquerade: String?): PasswordSecret = fetch(
            url = "$httpUrl/auth/proof/password/secrets/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<PasswordSecret>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/auth/proof/password/secrets/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/auth/proof/password/secrets/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<PasswordSecret>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/auth/proof/password/secrets/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<PasswordSecret>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/auth/proof/password/secrets/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<PasswordSecret>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/auth/proof/password/secrets/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<PasswordSecret>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/auth/proof/password/secrets/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LivePasswordProofApi(val httpUrl: String, val socketUrl: String): Api.PasswordProofApi {
        override suspend fun establishPassword(input: EstablishPassword, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/auth/proof/password/establish",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun provePasswordOwnership(input: IdentificationAndPassword): Proof = fetch(
            url = "$httpUrl/auth/proof/password/prove",
            method = HttpMethod.POST,
            body = input
        )
    }
    class LiveEmailProofApi(val httpUrl: String, val socketUrl: String): Api.EmailProofApi {
        override suspend fun beginEmailOwnershipProof(input: String): String = fetch(
            url = "$httpUrl/auth/email/start",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun proveEmailOwnership(input: FinishProof): Proof = fetch(
            url = "$httpUrl/auth/email/prove",
            method = HttpMethod.POST,
            body = input
        )
    }
    class LiveUserAuthApi(val httpUrl: String, val socketUrl: String): Api.UserAuthApi {
        override suspend fun logIn(input: List<Proof>): IdAndAuthMethods<UUID> = fetch(
            url = "$httpUrl/auth/users/login",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun logInV2(input: LogInRequest): IdAndAuthMethods<UUID> = fetch(
            url = "$httpUrl/auth/users/login2",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun checkProofs(input: List<Proof>): ProofsCheckResult<UUID> = fetch(
            url = "$httpUrl/auth/users/proofs-check",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun openSession(input: String): String = fetch(
            url = "$httpUrl/auth/users/open-session",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun createSubSession(input: SubSessionRequest, accessToken: suspend () -> String, masquerade: String?): String = fetch(
            url = "$httpUrl/auth/users/sub-session",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun getToken(input: OauthTokenRequest): OauthResponse = fetch(
            url = "$httpUrl/auth/users/token",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun getTokenSimple(input: String): String = fetch(
            url = "$httpUrl/auth/users/token/simple",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun getSelf(accessToken: suspend () -> String, masquerade: String?): User = fetch(
            url = "$httpUrl/auth/users/self",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun terminateSession(accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/auth/users/terminate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun terminateOtherSession(sessionId: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/auth/users/${sessionId.urlify()}/terminate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
        )
    }
    class LiveUserSessionApi(val httpUrl: String, val socketUrl: String): Api.UserSessionApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/users/sessions/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/users/sessions/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): List<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/users/sessions/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): List<Partial<Session<User, UUID>>> = fetch(
            url = "$httpUrl/auth/users/sessions/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/users/sessions/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): List<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/users/sessions/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Session<User, UUID>, accessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/users/sessions",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Session<User, UUID>, accessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/users/sessions/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): List<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/users/sessions",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: Session<User, UUID>, accessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/users/sessions/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/auth/users/sessions/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): EntryChange<Session<User, UUID>> = fetch(
            url = "$httpUrl/auth/users/sessions/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/users/sessions/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): Session<User, UUID> = fetch(
            url = "$httpUrl/auth/users/sessions/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/auth/users/sessions/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/auth/users/sessions/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/auth/users/sessions/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/auth/users/sessions/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/auth/users/sessions/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Session<User, UUID>>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/auth/users/sessions/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveFunnelSummaryApi(val httpUrl: String, val socketUrl: String): Api.FunnelSummaryApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): FunnelSummary = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<FunnelSummary> = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): List<FunnelSummary> = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): List<Partial<FunnelSummary>> = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): FunnelSummary = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): List<FunnelSummary> = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: FunnelSummary, accessToken: suspend () -> String, masquerade: String?): FunnelSummary = fetch(
            url = "$httpUrl/meta/funnels/summary/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: FunnelSummary, accessToken: suspend () -> String, masquerade: String?): FunnelSummary = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): List<FunnelSummary> = fetch(
            url = "$httpUrl/meta/funnels/summary/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: FunnelSummary, accessToken: suspend () -> String, masquerade: String?): FunnelSummary = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): EntryChange<FunnelSummary> = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): FunnelSummary = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): FunnelSummary = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<FunnelSummary>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/meta/funnels/summary/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveFunnelInstanceApi(val httpUrl: String, val socketUrl: String): Api.FunnelInstanceApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): FunnelInstance = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<FunnelInstance> = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): List<FunnelInstance> = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): List<Partial<FunnelInstance>> = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): FunnelInstance = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): List<FunnelInstance> = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: FunnelInstance, accessToken: suspend () -> String, masquerade: String?): FunnelInstance = fetch(
            url = "$httpUrl/meta/funnels/instance/rest",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: FunnelInstance, accessToken: suspend () -> String, masquerade: String?): FunnelInstance = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): List<FunnelInstance> = fetch(
            url = "$httpUrl/meta/funnels/instance/rest",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: FunnelInstance, accessToken: suspend () -> String, masquerade: String?): FunnelInstance = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): EntryChange<FunnelInstance> = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): FunnelInstance = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): FunnelInstance = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<FunnelInstance>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/meta/funnels/instance/rest/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun getFunnelHealth(date: LocalDate, accessToken: suspend () -> String, masquerade: String?): List<FunnelSummary> = fetch(
            url = "$httpUrl/meta/funnels/summaries/${date.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun summarizeFunnelsNow(input: LocalDate, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/meta/funnels/summarize-now",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun startFunnelInstance(input: FunnelStart, accessToken: (suspend () -> String)?, masquerade: String?): UUID = fetch(
            url = "$httpUrl/meta/funnels/start",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun errorFunnelInstance(id: UUID, input: String): Unit = fetch(
            url = "$httpUrl/meta/funnels/error/${id.urlify()}",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun setStepFunnelInstance(id: UUID, input: Int): Unit = fetch(
            url = "$httpUrl/meta/funnels/step/${id.urlify()}",
            method = HttpMethod.POST,
            body = input
        )
        override suspend fun successFunnelInstance(id: UUID): Unit = fetch(
            url = "$httpUrl/meta/funnels/success/${id.urlify()}",
            method = HttpMethod.POST,
        )
    }
    class LiveProductCategoryApi(val httpUrl: String, val socketUrl: String): Api.ProductCategoryApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): ProductCategory = fetch(
            url = "$httpUrl/product-categories/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<ProductCategory> = fetch(
            url = "$httpUrl/product-categories/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<ProductCategory>, accessToken: suspend () -> String, masquerade: String?): List<ProductCategory> = fetch(
            url = "$httpUrl/product-categories/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<ProductCategory>, accessToken: suspend () -> String, masquerade: String?): List<Partial<ProductCategory>> = fetch(
            url = "$httpUrl/product-categories/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): ProductCategory = fetch(
            url = "$httpUrl/product-categories/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<ProductCategory>, accessToken: suspend () -> String, masquerade: String?): List<ProductCategory> = fetch(
            url = "$httpUrl/product-categories/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: ProductCategory, accessToken: suspend () -> String, masquerade: String?): ProductCategory = fetch(
            url = "$httpUrl/product-categories",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: ProductCategory, accessToken: suspend () -> String, masquerade: String?): ProductCategory = fetch(
            url = "$httpUrl/product-categories/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<ProductCategory>, accessToken: suspend () -> String, masquerade: String?): List<ProductCategory> = fetch(
            url = "$httpUrl/product-categories",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: ProductCategory, accessToken: suspend () -> String, masquerade: String?): ProductCategory = fetch(
            url = "$httpUrl/product-categories/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<ProductCategory>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/product-categories/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<ProductCategory>, accessToken: suspend () -> String, masquerade: String?): EntryChange<ProductCategory> = fetch(
            url = "$httpUrl/product-categories/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<ProductCategory>, accessToken: suspend () -> String, masquerade: String?): ProductCategory = fetch(
            url = "$httpUrl/product-categories/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<ProductCategory>, accessToken: suspend () -> String, masquerade: String?): ProductCategory = fetch(
            url = "$httpUrl/product-categories/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<ProductCategory>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/product-categories/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/product-categories/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<ProductCategory>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/product-categories/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<ProductCategory>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/product-categories/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<ProductCategory>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/product-categories/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<ProductCategory>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/product-categories/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
    }
    class LiveProductApi(val httpUrl: String, val socketUrl: String): Api.ProductApi {
        override suspend fun default(accessToken: suspend () -> String, masquerade: String?): Product = fetch(
            url = "$httpUrl/products/_default_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun permissions(accessToken: suspend () -> String, masquerade: String?): ModelPermissions<Product> = fetch(
            url = "$httpUrl/products/_permissions_",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun query(input: Query<Product>, accessToken: suspend () -> String, masquerade: String?): List<Product> = fetch(
            url = "$httpUrl/products/query",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun queryPartial(input: QueryPartial<Product>, accessToken: suspend () -> String, masquerade: String?): List<Partial<Product>> = fetch(
            url = "$httpUrl/products/query-partial",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun detail(id: UUID, accessToken: suspend () -> String, masquerade: String?): Product = fetch(
            url = "$httpUrl/products/${id.urlify()}",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun insertBulk(input: List<Product>, accessToken: suspend () -> String, masquerade: String?): List<Product> = fetch(
            url = "$httpUrl/products/bulk",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun insert(input: Product, accessToken: suspend () -> String, masquerade: String?): Product = fetch(
            url = "$httpUrl/products",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun upsert(id: UUID, input: Product, accessToken: suspend () -> String, masquerade: String?): Product = fetch(
            url = "$httpUrl/products/${id.urlify()}",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkReplace(input: List<Product>, accessToken: suspend () -> String, masquerade: String?): List<Product> = fetch(
            url = "$httpUrl/products",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun replace(id: UUID, input: Product, accessToken: suspend () -> String, masquerade: String?): Product = fetch(
            url = "$httpUrl/products/${id.urlify()}",
            method = HttpMethod.PUT,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkModify(input: MassModification<Product>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/products/bulk",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modifyWithDiff(id: UUID, input: Modification<Product>, accessToken: suspend () -> String, masquerade: String?): EntryChange<Product> = fetch(
            url = "$httpUrl/products/${id.urlify()}/delta",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun modify(id: UUID, input: Modification<Product>, accessToken: suspend () -> String, masquerade: String?): Product = fetch(
            url = "$httpUrl/products/${id.urlify()}",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun simplifiedModify(id: UUID, input: Partial<Product>, accessToken: suspend () -> String, masquerade: String?): Product = fetch(
            url = "$httpUrl/products/${id.urlify()}/simplified",
            method = HttpMethod.PATCH,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun bulkDelete(input: Condition<Product>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/products/bulk-delete",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun delete(id: UUID, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/products/${id.urlify()}",
            method = HttpMethod.DELETE,
            token = accessToken,
            masquerade = masquerade,
        )
        override suspend fun count(input: Condition<Product>, accessToken: suspend () -> String, masquerade: String?): Int = fetch(
            url = "$httpUrl/products/count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupCount(input: GroupCountQuery<Product>, accessToken: suspend () -> String, masquerade: String?): Map<String, Int> = fetch(
            url = "$httpUrl/products/group-count",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun aggregate(input: AggregateQuery<Product>, accessToken: suspend () -> String, masquerade: String?): Double? = fetch(
            url = "$httpUrl/products/aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun groupAggregate(input: GroupAggregateQuery<Product>, accessToken: suspend () -> String, masquerade: String?): Map<String, Double?> = fetch(
            url = "$httpUrl/products/group-aggregate",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun uploadProductSpreadsheet(input: ServerFile, accessToken: suspend () -> String, masquerade: String?): Unit = fetch(
            url = "$httpUrl/products/spreadsheet/upload",
            method = HttpMethod.POST,
            token = accessToken,
            masquerade = masquerade,
            body = input
        )
        override suspend fun exportProductSpreadsheet(accessToken: suspend () -> String, masquerade: String?): List<VBSpreadsheetProduct> = fetch(
            url = "$httpUrl/products/spreadsheet/export",
            method = HttpMethod.GET,
            token = accessToken,
            masquerade = masquerade,
        )
    }
}

