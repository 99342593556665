package com.vandenbussche.views.components

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.serialization.lensPath
import com.lightningkite.serialization.map

import com.vandenbussche.mappings.nullToBlank
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.sdk.utils.pushChanges
import com.vandenbussche.theming.embedded
import com.vandenbussche.theming.save
import com.vandenbussche.tooltip
import com.vandenbussche.validation.ValidatedWritable
import com.vandenbussche.validation.Validator
import com.vandenbussche.validation.validate
import com.vandenbussche.validation.validating
import com.vandenbussche.views.screens.common.HasNarrowContent

fun ViewWriter.formTextField(
    name: String,
    writable: ValidatedWritable<String>,
    setup: TextField.() -> Unit = {}
) {
    label {
        content = name

        embedded - fieldTheme - validate(writable) - textInput {
            content bind writable
            setup()
        }
    }
}

fun ViewWriter.formTextField(
    name: String,
    writable: Writable<String>,
    setup: TextField.() -> Unit = {}
) {
    label {
        content = name

        embedded - fieldTheme - textInput {
            content bind writable
            setup()
        }
    }
}

private fun ViewWriter.addressForm(address: Writable<Address>, validator: Validator) = with(validator) {
    col {
        spacing = 1.5.rem

        formTextField(
            "Business Name",
            address.map(Address.path.businessName).validate { it.isNotBlank() }
        ) {
            hint = "The name of the business at this address."
        }

        formTextField(
            "Street Address",
            address.map(Address.path.street).validate { it.isNotBlank() }
        ) {
            hint = "Ex: 123 Fake St."
        }

        formTextField(
            "Unit or Suite (Optional)",
            address.map(Address.path.unitOrSuite).nullToBlank()
        ) {
            hint = "Ex: Apt. 4"
        }

        formTextField(
            "City",
            address.map(Address.path.city).validate { it.isNotBlank() }
        ) {
            hint = "Ex: Los Angeles"
        }

        fun Writable<String>.uppercase(): Writable<String> = lens(
            get = { it },
            set = { it.uppercase() },
        )

        formTextField(
            "State/Province",
            address.map(Address.path.subcountry).uppercase().validate { it.length == 2 }
        ) {
            hint = "Only 2-letter abbreviations. Ex: CA"
        }

        formTextField(
            "Country",
            address.map(Address.path.country).uppercase().validate { it.length == 2 }
        ) {
            hint = "Only 2-letter abbreviations. Ex: US"
        }

        formTextField(
            "Zip/Postal Code",
            address.map(Address.path.postalCode).validate { it.isNotBlank() }
        )
    }
}

fun ViewWriter.editAddressDialog(
    address: Writable<Address>,
    isNewLocation: Boolean = false,
    onSubmit: suspend (Draft<Address>) -> Unit = {}
) {
    val draft = Draft(address)

    dialogScreenNavigator.navigate(object: Screen, HasNarrowContent {
        override fun ViewWriter.render() = validating {
            dismissBackground {
                spacing = 4.rem
                ::spacing {
                    if (narrow()) 0.5.rem
                    else 4.rem
                }

                card - col {
                    spacing = 1.25.rem

                    row {
                        centered - expanding - h2 {
                            content = if (isNewLocation) "New Address" else "Edit Address"
                        }

                        button {
                            icon(Icon.close, "Close Dialog")
                            onClick { screenNavigator.dismiss() }
                        }
                    }

                    scrolls - expanding - addressForm(draft, this@validating)

                    atEnd - row {
                        card - button {
                            ::enabled { draft.changesMade() }

                            icon(Icon.close, "Discard Changes")
                            onClick { draft.cancel() }
                        } in tooltip("Discard Changes")

                        important - button {
                            ::enabled { draft.changesMade() && allValid() }

                            row {
                                if (!isNewLocation) icon { source = Icon.save }
                                centered - h6 {
                                    content = if (isNewLocation) "Save Location"
                                    else "Save Changes"
                                }
                            }

                            onClick {
                                draft.publish()
                                onSubmit(draft)
                                dialogScreenNavigator.dismiss()
                            }
                        }
                    }
                }
            }
        }
    })
}


fun ViewWriter.editLocationDialog(
    shipTo: Writable<ShippingAddress>,
    isNewLocation: Boolean = false,
) {
    val draft = Draft(shipTo)

    dialogScreenNavigator.navigate(object: Screen, HasNarrowContent {
        override fun ViewWriter.render() = validating {
            dismissBackground {
                ::spacing {
                    if (narrow()) 1.rem
                    else 4.rem
                }

                card - col {
                    spacing = 1.25.rem

                    row {
                        centered - expanding - h2 {
                            content = if (isNewLocation) "New Location" else "Edit Location"
                        }

                        button {
                            icon(Icon.close, "Close Dialog")
                            onClick { dialogScreenNavigator.dismiss() }
                        }
                    }

                    expanding - scrolls - col {
                        formTextField(
                            "Name",
                            draft.lens(
                                get = { it.name ?: "" },
                                modify = { o, it -> o.copy(name = it) }
                            ).validate { it.isNotBlank() }

                        ) {
                            hint = "Your name for this location, only you see this."
//                            requestFocus()
                        }

                        addressForm(draft.lensPath { it.address }, this@validating)
                    }

                    atEnd - row {
                        card - button {
                            exists = !isNewLocation
                            ::enabled { draft.changesMade() }

                            icon(Icon.close, "Discard Changes")
                            onClick { draft.cancel() }
                        }

                        important - button {
                            ::enabled { draft.changesMade() && allValid() }

                            row {
                                icon { source = Icon.save }
                                centered - h6 {
                                    content = if (isNewLocation) "Save Location"
                                    else "Save Changes"
                                }
                            }

                            onClick {
                                draft.pushChanges { currentSession().shippingAddresses }
                                dialogScreenNavigator.dismiss()
                            }
                        }
                    }
                }
            }
        }
    })
}