package com.vandenbussche.views

import com.lightningkite.kiteui.models.Color
import com.lightningkite.kiteui.models.ThemeDerivation
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.ContainingView
import com.lightningkite.kiteui.views.direct.stack

@ViewDsl
fun ViewWriter.emptyView(foregroundAverageRatio: Float = 0.6f, contents: ContainingView.() -> Unit) {
    stack {
        themeChoice += ThemeDerivation {
            it.copy(
                foreground = Color.interpolate(it.background.closestColor(), it.foreground.closestColor(), foregroundAverageRatio)
            ).withoutBack
        }
        contents()
    }
}