package com.vandenbussche.views.components

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.condition
import com.lightningkite.lightningdb.eq
import com.lightningkite.lightningdb.modification
import com.lightningkite.serialization.lensPath
import com.vandenbussche.mappings.*
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.sdk.utils.activeWarehouses
import com.vandenbussche.sdk.utils.preferredWarehouse
import com.vandenbussche.views.screens.cart.Carts
import com.vandenbussche.views.textFormatting.formatName
import kotlinx.coroutines.launch


fun RView.quantityField(product: Readable<Product>, pricing:LateInitProperty<Readable<CartItem?>>? = null) {
    val currentQuantity = shared {
        val pid = product()._id
        Carts.Selected().items.find { it.product == pid }?.quantity

    }
    val quantity = Property<Double?>(null)
    launch {
        val currentCart = Carts.selected.awaitNotNull()
        val pricingRequestInfo = PricingRequestInfo(
            account = currentCart.account,
            items = setOf(CartItem(
                product = product()._id,
                productErpId = product().erpId,
                quantity = quantity()?.toInt()?.takeIf { it > 0 } ?: 1
            )),
            warehouse = currentCart.warehouse,
            shipTo = currentCart.shipTo,
            shipASAP = currentCart.shipASAP
        )
        pricing?.value =shared {
            currentSession().pricing.run {
                request(pricingRequestInfo )().also { println("Submission result is $it") }.find { it.product == product()._id }
            }
        }
    }
    reactive {
        quantity.value = currentQuantity()?.toDouble()
    }
    val tempPricing = shared {
        val currentCart = Carts.selected.awaitNotNull()
        val productId = product()._id
        val pricingRequestInfo = PricingRequestInfo(
            account = currentCart.account,
            items = setOf(CartItem(
                product = product()._id,
                productErpId = product().erpId,
                quantity = quantity()?.toInt()?.takeIf { it > 0 } ?: 1
            )),
            warehouse = currentCart.warehouse,
            shipTo = currentCart.shipTo,
            shipASAP = currentCart.shipASAP
        )

        println("Submission is ${pricingRequestInfo.items.joinToString("\n") { it.productErpId + ": " + it.quantity }}")

        currentSession().pricing.run {
            request(pricingRequestInfo)().also { println("Submission result is $it") }.find { it.product == productId }
        }
    }

    tempPricing.withWrite {
        pricing?.value = tempPricing
    }

    fieldTheme - row{
        val submitAction = Action("Submit", Icon.send) {
            val actionTempPricing = tempPricing()
            Carts.selected.modify(modification<Cart> {
                println("in Carts modify")
                val q = quantity()?.toInt() ?: 1
                if (q > 0 && currentQuantity()?.toInt() != null) {
                    println("UPDATE EXISTING")
                    it.items.forEachIf(
                        condition = { it.product eq product()._id },
                        modification = {
                            it.quantity assign (actionTempPricing?.quantity ?: q)
                            it.previousLineTotal assign actionTempPricing?.previousLineTotal
                            it.previousListPrice assign actionTempPricing?.previousListPrice
                            it.previousPrice assign actionTempPricing?.previousPrice
                            it.previousDiscountType assign actionTempPricing?.previousDiscountType
                        }
                    )
                } else if (q > 0 && currentQuantity() == null) {
                    println("INSERT")
                    it.items.addAll(setOf(actionTempPricing ?: CartItem(product()._id, product().erpId, q)))
                } else {
                    println("REMOVE")
                    it.items.removeAll { it.product eq product()._id }
                }
            })
            pricing?.set(tempPricing)
        }

        spacing = 0.5.rem
        ::exists {currentSession().mayOrder}
        padded
        expanding - numberInput {
            keyboardHints = KeyboardHints.integer
            hint = "Quantity"
            align = Align.Start
            content bind quantity
            action = submitAction
        }

        sizeConstraints(width = 7.5.rem) - buttonTheme -  button {
            spacing = 0.rem
            centered -padded - text {
                ::content {
                    if (quantity()?.toInt() == currentQuantity()) {
                        if (currentQuantity() == null) "Add to Cart"
                        else "In Cart"
                    } else {
                        if (quantity()?.toInt() == 0 || quantity() == null) "Remove from Cart"
                        else if (currentQuantity() != 0 || currentQuantity() == null) "Update Cart"
                        else "Add to Cart"
                    }
                }
            }
            action = submitAction
        }

    }
}

fun ViewWriter.selectSourceWarehouse(expand: Boolean = false) {

    val warehouses = shared { activeWarehouses()() }

    label {
        content = "Warehouse"

        if (expand) expanding
        fieldTheme - select {
            ::enabled { currentSession().mayOrder }
            bind(
                edits = Carts.Selected.lensPath { it.warehouse }.fromData(warehouses) { preferredWarehouse() },
                data = warehouses,
                render = { it.formatName() }
            )
        }
    }
}