package com.vandenbussche.views.screens.products

import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningtime.reactiveDivider
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.views.components.productCategoryCard
import com.vandenbussche.views.components.special
import com.vandenbussche.views.screens.common.HasNarrowContent

@Routable("/catalog")
object CatalogScreen : Screen, HasNarrowContent {
    override val title: Readable<String> = Constant("Categories")

    private val rootCategories = shared {
        currentSession().productCategories.query(
            Query(condition { it.parent eq null }, sort { it.order.ascending() })
        )()
    }

    override fun ViewWriter.render() {
        scrolls - col {
            with(MessageHandler) { startedViewing() }
            special(MessageHandler.message) {
                onTimeout { MessageHandler.close() }
            }
           reactiveDivider<ProductCategory> {
                ::columns { if (narrow()) 1 else 2 }
                children(rootCategories) { productCategoryCard(Constant(it)) }
            }
        }
    }
}