// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.vandenbussche.models

import com.lightningkite.lightningdb.*
import com.lightningkite.UUID
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareUserFields() {
    val props: Array<SerializableProperty<User, *>> = arrayOf(User__id, User_firstName, User_lastName, User_account, User_role, User_emailOnNewOrderStatus, User_emailOnNewUsers, User_showPricing, User_showInventory, User_active, User_createdAt, User_desiredCompany, User_email, User_phoneNumber)
    User.serializer().properties { props }
}
val <K> DataClassPath<K, User>._id: DataClassPath<K, UUID> get() = this[User__id]
val <K> DataClassPath<K, User>.firstName: DataClassPath<K, String> get() = this[User_firstName]
val <K> DataClassPath<K, User>.lastName: DataClassPath<K, String> get() = this[User_lastName]
val <K> DataClassPath<K, User>.account: DataClassPath<K, UUID?> get() = this[User_account]
val <K> DataClassPath<K, User>.role: DataClassPath<K, UserRole> get() = this[User_role]
val <K> DataClassPath<K, User>.emailOnNewOrderStatus: DataClassPath<K, Boolean> get() = this[User_emailOnNewOrderStatus]
val <K> DataClassPath<K, User>.emailOnNewUsers: DataClassPath<K, Boolean> get() = this[User_emailOnNewUsers]
val <K> DataClassPath<K, User>.showPricing: DataClassPath<K, Boolean> get() = this[User_showPricing]
val <K> DataClassPath<K, User>.showInventory: DataClassPath<K, Boolean> get() = this[User_showInventory]
val <K> DataClassPath<K, User>.active: DataClassPath<K, Boolean> get() = this[User_active]
val <K> DataClassPath<K, User>.createdAt: DataClassPath<K, Instant> get() = this[User_createdAt]
val <K> DataClassPath<K, User>.desiredCompany: DataClassPath<K, String?> get() = this[User_desiredCompany]
val <K> DataClassPath<K, User>.email: DataClassPath<K, String> get() = this[User_email]
val <K> DataClassPath<K, User>.phoneNumber: DataClassPath<K, String> get() = this[User_phoneNumber]
inline val User.Companion.path: DataClassPath<User, User> get() = path<User>()


object User__id: SerializableProperty<User, UUID> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(0) }
    override fun get(receiver: User): UUID = receiver._id
    override fun setCopy(receiver: User, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object User_firstName: SerializableProperty<User, String> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(1) }
    override fun get(receiver: User): String = receiver.firstName
    override fun setCopy(receiver: User, value: String) = receiver.copy(firstName = value)
    override val serializer: KSerializer<String> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(1) }
}
object User_lastName: SerializableProperty<User, String> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(2) }
    override fun get(receiver: User): String = receiver.lastName
    override fun setCopy(receiver: User, value: String) = receiver.copy(lastName = value)
    override val serializer: KSerializer<String> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(2) }
}
object User_account: SerializableProperty<User, UUID?> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(3) }
    override fun get(receiver: User): UUID? = receiver.account
    override fun setCopy(receiver: User, value: UUID?) = receiver.copy(account = value)
    override val serializer: KSerializer<UUID?> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<UUID?> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(3) }
    override val default: UUID? = null
    override val defaultCode: String? = "null" 
}
object User_role: SerializableProperty<User, UserRole> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(4) }
    override fun get(receiver: User): UserRole = receiver.role
    override fun setCopy(receiver: User, value: UserRole) = receiver.copy(role = value)
    override val serializer: KSerializer<UserRole> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<UserRole> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(4) }
    override val default: UserRole = UserRole.Customer
    override val defaultCode: String? = "UserRole.Customer" 
}
object User_emailOnNewOrderStatus: SerializableProperty<User, Boolean> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(5) }
    override fun get(receiver: User): Boolean = receiver.emailOnNewOrderStatus
    override fun setCopy(receiver: User, value: Boolean) = receiver.copy(emailOnNewOrderStatus = value)
    override val serializer: KSerializer<Boolean> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(5) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object User_emailOnNewUsers: SerializableProperty<User, Boolean> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(6) }
    override fun get(receiver: User): Boolean = receiver.emailOnNewUsers
    override fun setCopy(receiver: User, value: Boolean) = receiver.copy(emailOnNewUsers = value)
    override val serializer: KSerializer<Boolean> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(6) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object User_showPricing: SerializableProperty<User, Boolean> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(7) }
    override fun get(receiver: User): Boolean = receiver.showPricing
    override fun setCopy(receiver: User, value: Boolean) = receiver.copy(showPricing = value)
    override val serializer: KSerializer<Boolean> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(7) }
    override val default: Boolean = true
    override val defaultCode: String? = "true" 
}
object User_showInventory: SerializableProperty<User, Boolean> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(8) }
    override fun get(receiver: User): Boolean = receiver.showInventory
    override fun setCopy(receiver: User, value: Boolean) = receiver.copy(showInventory = value)
    override val serializer: KSerializer<Boolean> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(8) }
    override val default: Boolean = true
    override val defaultCode: String? = "true" 
}
object User_active: SerializableProperty<User, Boolean> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(9) }
    override fun get(receiver: User): Boolean = receiver.active
    override fun setCopy(receiver: User, value: Boolean) = receiver.copy(active = value)
    override val serializer: KSerializer<Boolean> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[9] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(9) }
    override val default: Boolean = true
    override val defaultCode: String? = "true" 
}
object User_createdAt: SerializableProperty<User, Instant> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(10) }
    override fun get(receiver: User): Instant = receiver.createdAt
    override fun setCopy(receiver: User, value: Instant) = receiver.copy(createdAt = value)
    override val serializer: KSerializer<Instant> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[10] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(10) }
    override val default: Instant = Instant.fromEpochMilliseconds(0)
    override val defaultCode: String? = "Instant.fromEpochMilliseconds(0)" 
}
object User_desiredCompany: SerializableProperty<User, String?> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(11) }
    override fun get(receiver: User): String? = receiver.desiredCompany
    override fun setCopy(receiver: User, value: String?) = receiver.copy(desiredCompany = value)
    override val serializer: KSerializer<String?> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[11] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(11) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object User_email: SerializableProperty<User, String> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(12) }
    override fun get(receiver: User): String = receiver.email
    override fun setCopy(receiver: User, value: String) = receiver.copy(email = value)
    override val serializer: KSerializer<String> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[12] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(12) }
}
object User_phoneNumber: SerializableProperty<User, String> {
    override val name: String by lazy { User.serializer().descriptor.getElementName(13) }
    override fun get(receiver: User): String = receiver.phoneNumber
    override fun setCopy(receiver: User, value: String) = receiver.copy(phoneNumber = value)
    override val serializer: KSerializer<String> by lazy { (User.serializer() as GeneratedSerializer<*>).childSerializers()[13] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { User.serializer().descriptor.getElementAnnotations(13) }
    override val default: String = ""
    override val defaultCode: String? = "\"\"" 
}
