package com.vandenbussche.views.screens.auth

import com.lightningkite.kiteui.models.Icon
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.ScreenNavigator
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.mainScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningserver.LsErrorException
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningkite.serialization.lensPath
import com.vandenbussche.*
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.sdk.selectedApi
import com.vandenbussche.sdk.sessionToken
import com.vandenbussche.validation.Validator
import com.vandenbussche.validation.validate
import com.vandenbussche.views.screens.products.CatalogScreen

class UserRegistration : Validator() {
    val user = Property(
        User(
            firstName = "",
            lastName = "",
            email = "",
        )
    )

    private var passcodeToken: String? = null
    private suspend fun beginProof(onSuccess: () -> Unit) {
        val api = selectedApi().api

        passcodeToken = api.emailProof.beginEmailOwnershipProof(user().email.lowercase())

        onSuccess()
    }

    private suspend fun confirmPasscode(passcode: String, onSuccess: () -> Unit) {
        val api = selectedApi().api

        val proof = api.emailProof.proveEmailOwnership(FinishProof(passcodeToken!!, passcode))
        try {
            println("Created user is " + api.user.create(CreateUser(user().copy(email = user.value.email.lowercase()), proof)))
        } catch (e: LsErrorException) {
            if (e.status == 400.toShort() && e.error.detail == "unique") {
               // If they already have an email registered then log them in
                if (e.error.message?.let { Regexes.isolateUniqueKey(it) } != "email") throw e
            } else throw e
        }

        println("Logging in now...")
        val session = api.userAuth.logIn(listOf(proof))
        println("Session: $session")
        sessionToken.value = session.session

        currentSession.onNextSuccess(onSuccess)
    }

    fun ViewWriter.form() {
        col {
            spacing = 1.5.rem

            label {
                content = "Name"

                val first = user.lensPath { it.firstName }.validate { it.isNotBlank() }
                val last = user.lensPath { it.lastName }.validate { it.isNotBlank() }

                row {
                    expanding - fieldTheme - validate(first) - textInput {
                        hint = "First"
                        content bind first
                    }
                    expanding - fieldTheme - validate(last) - textInput {
                        hint = "Last"
                        content bind last
                    }
                }
            }

            label {
                content = "Phone Number"

                val phoneNumber = user.lensPath { it.phoneNumber }.validate { it.matches(Regexes.phoneNumber) }

                fieldTheme - validate(phoneNumber) - phoneNumberInput {
                    hint = "123-456-7890"
                    content bind phoneNumber
                }
            }

            label {
                content = "Email"

                val email = user.lensPath { it.email } .validate { it.matches(Regexes.email) }

                fieldTheme - validate(email) - textInput {
                    hint = "fake@email.com"
                    content bind email
                    keyboardHints = KeyboardHints.email
                }
            }

            label {
                content = "Company Name and/or Account Number"

                val company = user.lensPath { it.desiredCompany }.validate { !it.isNullOrBlank() }

                fieldTheme - validate(company) - textInput {
                    hint = "\"Fake Company\" or \"#ABC123\""
                    content bind company.nullToBlank()
                }
            }
        }
    }

    inner class RegistrationScreen : Screen, AuthScreen {
        override fun ViewWriter.render() {
            scrolls - col {
                expanding - space()
                sizeConstraints(width = 35.rem) - centered - dialog - col {
                    h2("Register")

                    form()

                    atEnd - important - button {
                        ::enabled { allValid() }
                        centered - text("Create User")

                        onClick {
                            beginProof {
                                mainScreenNavigator.navigate(ConfirmEmailScreen())
                            }
                        }
                    }
                }
                expanding - space()
            }
        }
    }

    inner class ConfirmEmailScreen : Screen, AuthScreen {

        private val pin = Property("").validate { it.length == 7 }

        lateinit var mainNav: ScreenNavigator
        lateinit var dialogNav: ScreenNavigator
        val confirm = Action("Submit PIN", Icon.done) {
            confirmPasscode(pin()) {
                mainNav.reset(CatalogScreen)
                dialogNav.navigate(
                    object : Screen {
                        override fun ViewWriter.render() {
                            dismissBackground {
                                centered - card - sizeConstraints(maxWidth = 30.rem) - col {
                                    spacing = 1.5.rem
                                    text("""
                                        Thank you for registering for our Mobile App.
                                        Please note that you will have limited access to features until our office completes and approves of your login.
                                    """.trimIndent())
                                    atStart - important - button {
                                        centered - text("Close")
                                        onClick { dialogScreenNavigator.dismiss() }
                                    }
                                }
                            }
                        }
                    }
                )
            }
        }

        override fun ViewWriter.render() {
            stack {
                mainNav = mainScreenNavigator
                dialogNav = dialogScreenNavigator
                centered - card - col {
                    spacing = 1.5.rem

                    h2("Confirm Email")

                    text("Please confirm your email by entering the 7 letter code sent to \n${user.value.email}")

                    fieldTheme - validate(pin) - textInput {
                        hint = "Passcode"
                        keyboardHints = KeyboardHints.id
                        content bind pin
                        action = confirm
                    }

                    important - button {
                        spacing = 0.5.rem

                        ::enabled { allValid() }

                        centered - text("Submit")

                        action = confirm
                    }
                }
            }
        }
    }
}