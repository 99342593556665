package com.vandenbussche.admin

import com.ilussobsa.views.multiselect
import com.lightningkite.UUID
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.locale.*
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.views.components.atEndOfDay
import com.vandenbussche.views.components.atStartOfDay
import com.vandenbussche.views.screens.account.orders.OrderScreen
import kotlinx.datetime.*



@Routable("/orders")
class AdminOrdersScreen : Screen {
    override val title: Readable<String> = Constant<String>("Orders")

    @QueryParameter("status")
    val status = Property<OrderStatusCode?>(null)

    @QueryParameter("orderNum")
    val orderNum = Property<String?>(null)

    @QueryParameter("poNum")
    val poNum = Property<String?>(null)

    @QueryParameter("includeRemoved")
    val includeRemoved = Property<Boolean>(false)

    @QueryParameter("startDate")
    val startDate = Property<LocalDate?>(null)

    @QueryParameter("endDate")
    val endDate = Property<LocalDate?>(null)

    @QueryParameter("accounts")
    val accounts = Property<Set<UUID>>(setOf())

    @QueryParameter("users")
    val users = Property<Set<UUID>>(setOf())

    val query = shared {
        Query(
            condition<Order> {
                Condition.And(
                    listOfNotNull(
                        Condition.Always(),
                        startDate()?.atStartOfDay()?.let { d -> it.orderedAt.gte(d) },
                        endDate()?.atEndOfDay()?.let { d -> it.orderedAt.lte(d) },
                        if (!includeRemoved()) it.hidden neq true else null,
                        status()?.let { d -> it.status.code eq d },
                        accounts().takeIf { it.isNotEmpty() }?.let { d -> it.account inside d },
                        users().takeIf { it.isNotEmpty() }?.let { d -> it.orderedBy inside d },
////                        currentSession().customerAccounts.get(it.account)()?.erpId.toString() eq accountNum.value,
                        poNum.debounce(500)()?.let { d -> it.poNum.contains(d, true) },
                        orderNum.debounce(500)()?.let { d -> it.erpId.notNull.contains(d, true) },
//                        it.account eq companies().find{ it.}
//                        it.account eq users().find{ user -> it.account == user.account}.account
//                        it.account eq accountNum()
//                                accountNum().let { d -> it.account d },
//                        company().let{ d -> it.}
//                        accountNum().let { d -> currentSession().customerAccounts[it.account]().erpId.toString() eq d },
                    )
                )
            },
            limit = 100,
            orderBy = sort { it.orderedAt.descending() }
        )
    }
    private val orders = shared {
        currentSession().orders.query(query.await())
    }

    override fun ViewWriter.render() = col {
        row {
            centered - text("Status:")
            fieldTheme - compact - select {
                bind<OrderStatusCode?>(
                    edits = shared { status.value }.withWrite { value -> status.set(value) },
                    data = Constant(listOf(null) + OrderStatusCode.entries)
                ) { it?.display ?: "Any" }
            }
            space()
            centered - text("From:")
            fieldTheme - compact - localDateField { content bind startDate }
            centered - text("To:")
            fieldTheme - compact - localDateField { content bind endDate }
            row {
                centered - checkbox {
                    checked bind includeRemoved
                }
                centered - text("Show removed orders")
            }
        }
        row {
            centered - text("Order # :")
            fieldTheme - compact - textInput {
                content bind orderNum.nullToBlank()
            }
            space()
            centered - text("PO # :")
            fieldTheme - compact - textInput {
                content bind poNum.nullToBlank()
            }
            space()
            centered - text("Status:")
            fieldTheme - compact - select {
                bind(
                    status,
                    Constant(listOf(null) + OrderStatusCode.values()),
                    render = { it?.display ?: "Any" }
                )
            }
            space()
            centered - text("Companies: ")
            fieldTheme - compact - multiselect(
                query = { q ->
                    currentSession().customerAccounts.query(Query(Condition.And(listOfNotNull(
                        Condition.Always(),
                        q.takeUnless { it.isBlank() }?.let { q ->
                            Condition.And(q.split(' ').map { part ->
                                Condition.Or(
                                    listOf(
                                        condition<CustomerAccount> {
                                            it.address.businessName.contains(
                                                part,
                                                true
                                            )
                                        },
                                        condition<CustomerAccount> { it.email.contains(part, true) },
                                        condition<CustomerAccount> { it.phoneNumber.contains(part, true) },
                                    )
                                )
                            })
                        }
                    ))))()
                },
                pull = {
                    currentSession().customerAccounts[it]() ?: CustomerAccount(
                        _id = it,
                        email = "?",
                        phoneNumber = "?",
                        address = Address.EMPTY
                    )
                },
                toString = { it.address.businessName },
                getId = { it._id },
                items = accounts
            )
            space()
            centered - text("Users: ")
            fieldTheme - compact - multiselect(
                query = { q ->
                    currentSession().users.query(Query(Condition.And(listOfNotNull(
                        Condition.Always(),
                        q.takeUnless { it.isBlank() }?.let { q ->
                            Condition.And(q.split(' ').map { part ->
                                Condition.Or(
                                    listOf(
                                        condition<User> { it.firstName.contains(part, true) },
                                        condition<User> { it.lastName.contains(part, true) },
                                        condition<User> { it.email.contains(part, true) },
                                        condition<User> { it.phoneNumber.contains(part, true) },
                                    )
                                )
                            })
                        }
                    ))))()
                },
                pull = {
                    currentSession().users[it]() ?: User(
                        _id = it,
                        email = "?",
                        phoneNumber = "?",
                        firstName = "?",
                        lastName = "?"
                    )
                },
                toString = { it.name },
                getId = { it._id },
                items = users
            )
        }

        separator()


        padded - row {
            weight(1f) - col { text("Order #") }
            weight(1f) - col { text("Date (UTC)") }
//            weight(1f) - col { text("Representative") }
            weight(3f) - col { text("Company") }
            weight(1f) - col { text("Account #") }
            weight(2f) - col { text("PO #") }
            weight(1f) - col { text("Status") }
            weight(2f) - col { text("Actions") }

        }
        expanding - recyclerView {
            reactiveScope {
                if (lastVisibleIndex() > orders().limit - 20)
                    orders().limit = lastVisibleIndex() + 100
            }
            val items = shared { orders.await().await() }
            children(items) { order: Readable<Order> ->
                card - row {
                    weight(1f) - centered - text { ::content{ order().erpId.toString() } }
                    weight(1f) - centered - text { ::content{ order().orderedAt.renderToString(RenderSize.Numerical) } }
//                        weight(1f) - col { text{::content{
//                            currentSession().users.query(Query(
//                                condition {
//                                    it.email eq order().contactEmail
//                                }
//                            ))
//                            }} }
                    weight(3f) - centered - button {
                        text {
                            wraps = false
                            ellipsis = true
                            ::content{
                                order().account.let {
                                    currentSession().customerAccounts[it].invoke()?.address?.businessName
                                        ?: "Not Found"
                                }
                            }
                        }
                        onClick {
                            order().account?.let {
                                currentSession().customerAccounts[it]()?.let {
                                    dialogScreenNavigator.navigate(AdminAccountScreenDialog(it))
                                }
                            }
                        }
                    }

                    weight(1f) - centered - text {
                        ::content{
                            (order().account.let {
                                currentSession().customerAccounts[it].invoke()?.erpId?.toString()
                            } ?: "Not Found")
                        }
                    }

                    weight(2f) - centered - text {
                        wraps = false
                        ellipsis = true
                        ::content{ order().poNum }
                    }
                    weight(1f) - centered - text { ::content{ order().status.code.toString() } }
                    weight(2f) - row {
                        compact - button {
                            centered - text("View")
                            onClick {
                                val item = order()._id
                                screenNavigator.navigate(OrderScreen(item))
                            }
                        }
                        danger - compact - button {
                            ::exists { !order().hidden }
                            centered - text("Remove")
                            onClick {
                                confirmDanger("Remove Order", "This action will cancel and hide the order here.") {
                                    currentSession().orders[order()._id].modify(modification<Order> {
//                                            it.status.code assign OrderStatusCode.Cancelled
                                        it.hidden assign true
                                    })
                                }
                            }
                        }
                    }
                }
            }

        }
    }

}