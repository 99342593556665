// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.vandenbussche.models

import com.lightningkite.lightningdb.*
import com.lightningkite.UUID
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareOrderFields() {
    val props: Array<SerializableProperty<Order, *>> = arrayOf(Order__id, Order_account, Order_orderedBy, Order_erpId, Order_fromCart, Order_warehouse, Order_shipTo, Order_items, Order_listPriceTotal, Order_total, Order_shipASAP, Order_fulfillmentExpectation, Order_orderedAt, Order_status, Order_comment, Order_poNum, Order_hidden, Order_requestJson, Order_responseJson)
    Order.serializer().properties { props }
}
val <K> DataClassPath<K, Order>._id: DataClassPath<K, UUID> get() = this[Order__id]
val <K> DataClassPath<K, Order>.account: DataClassPath<K, UUID> get() = this[Order_account]
val <K> DataClassPath<K, Order>.orderedBy: DataClassPath<K, UUID?> get() = this[Order_orderedBy]
val <K> DataClassPath<K, Order>.erpId: DataClassPath<K, String?> get() = this[Order_erpId]
val <K> DataClassPath<K, Order>.fromCart: DataClassPath<K, UUID?> get() = this[Order_fromCart]
val <K> DataClassPath<K, Order>.warehouse: DataClassPath<K, UUID> get() = this[Order_warehouse]
val <K> DataClassPath<K, Order>.shipTo: DataClassPath<K, UUID?> get() = this[Order_shipTo]
val <K> DataClassPath<K, Order>.items: DataClassPath<K, Set<CartItem>> get() = this[Order_items]
val <K> DataClassPath<K, Order>.listPriceTotal: DataClassPath<K, PriceInCents> get() = this[Order_listPriceTotal]
val <K> DataClassPath<K, Order>.total: DataClassPath<K, PriceInCents> get() = this[Order_total]
val <K> DataClassPath<K, Order>.shipASAP: DataClassPath<K, Boolean> get() = this[Order_shipASAP]
val <K> DataClassPath<K, Order>.fulfillmentExpectation: DataClassPath<K, Instant?> get() = this[Order_fulfillmentExpectation]
val <K> DataClassPath<K, Order>.orderedAt: DataClassPath<K, Instant> get() = this[Order_orderedAt]
val <K> DataClassPath<K, Order>.status: DataClassPath<K, OrderStatus> get() = this[Order_status]
val <K> DataClassPath<K, Order>.comment: DataClassPath<K, String> get() = this[Order_comment]
val <K> DataClassPath<K, Order>.poNum: DataClassPath<K, String> get() = this[Order_poNum]
val <K> DataClassPath<K, Order>.hidden: DataClassPath<K, Boolean> get() = this[Order_hidden]
val <K> DataClassPath<K, Order>.requestJson: DataClassPath<K, String> get() = this[Order_requestJson]
val <K> DataClassPath<K, Order>.responseJson: DataClassPath<K, String> get() = this[Order_responseJson]
inline val Order.Companion.path: DataClassPath<Order, Order> get() = path<Order>()


object Order__id: SerializableProperty<Order, UUID> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(0) }
    override fun get(receiver: Order): UUID = receiver._id
    override fun setCopy(receiver: Order, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object Order_account: SerializableProperty<Order, UUID> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(1) }
    override fun get(receiver: Order): UUID = receiver.account
    override fun setCopy(receiver: Order, value: UUID) = receiver.copy(account = value)
    override val serializer: KSerializer<UUID> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(1) }
}
object Order_orderedBy: SerializableProperty<Order, UUID?> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(2) }
    override fun get(receiver: Order): UUID? = receiver.orderedBy
    override fun setCopy(receiver: Order, value: UUID?) = receiver.copy(orderedBy = value)
    override val serializer: KSerializer<UUID?> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<UUID?> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(2) }
    override val default: UUID? = null
    override val defaultCode: String? = "null" 
}
object Order_erpId: SerializableProperty<Order, String?> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(3) }
    override fun get(receiver: Order): String? = receiver.erpId
    override fun setCopy(receiver: Order, value: String?) = receiver.copy(erpId = value)
    override val serializer: KSerializer<String?> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(3) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object Order_fromCart: SerializableProperty<Order, UUID?> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(4) }
    override fun get(receiver: Order): UUID? = receiver.fromCart
    override fun setCopy(receiver: Order, value: UUID?) = receiver.copy(fromCart = value)
    override val serializer: KSerializer<UUID?> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<UUID?> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(4) }
    override val default: UUID? = null
    override val defaultCode: String? = "null" 
}
object Order_warehouse: SerializableProperty<Order, UUID> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(5) }
    override fun get(receiver: Order): UUID = receiver.warehouse
    override fun setCopy(receiver: Order, value: UUID) = receiver.copy(warehouse = value)
    override val serializer: KSerializer<UUID> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(5) }
}
object Order_shipTo: SerializableProperty<Order, UUID?> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(6) }
    override fun get(receiver: Order): UUID? = receiver.shipTo
    override fun setCopy(receiver: Order, value: UUID?) = receiver.copy(shipTo = value)
    override val serializer: KSerializer<UUID?> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<UUID?> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(6) }
    override val default: UUID? = null
    override val defaultCode: String? = "null" 
}
object Order_items: SerializableProperty<Order, Set<CartItem>> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(7) }
    override fun get(receiver: Order): Set<CartItem> = receiver.items
    override fun setCopy(receiver: Order, value: Set<CartItem>) = receiver.copy(items = value)
    override val serializer: KSerializer<Set<CartItem>> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<Set<CartItem>> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(7) }
}
object Order_listPriceTotal: SerializableProperty<Order, PriceInCents> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(8) }
    override fun get(receiver: Order): PriceInCents = receiver.listPriceTotal
    override fun setCopy(receiver: Order, value: PriceInCents) = receiver.copy(listPriceTotal = value)
    override val serializer: KSerializer<PriceInCents> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<PriceInCents> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(8) }
    override val default: PriceInCents = 0.cents
    override val defaultCode: String? = "0.cents" 
}
object Order_total: SerializableProperty<Order, PriceInCents> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(9) }
    override fun get(receiver: Order): PriceInCents = receiver.total
    override fun setCopy(receiver: Order, value: PriceInCents) = receiver.copy(total = value)
    override val serializer: KSerializer<PriceInCents> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[9] as KSerializer<PriceInCents> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(9) }
}
object Order_shipASAP: SerializableProperty<Order, Boolean> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(10) }
    override fun get(receiver: Order): Boolean = receiver.shipASAP
    override fun setCopy(receiver: Order, value: Boolean) = receiver.copy(shipASAP = value)
    override val serializer: KSerializer<Boolean> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[10] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(10) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object Order_fulfillmentExpectation: SerializableProperty<Order, Instant?> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(11) }
    override fun get(receiver: Order): Instant? = receiver.fulfillmentExpectation
    override fun setCopy(receiver: Order, value: Instant?) = receiver.copy(fulfillmentExpectation = value)
    override val serializer: KSerializer<Instant?> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[11] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(11) }
    override val default: Instant? = null
    override val defaultCode: String? = "null" 
}
object Order_orderedAt: SerializableProperty<Order, Instant> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(12) }
    override fun get(receiver: Order): Instant = receiver.orderedAt
    override fun setCopy(receiver: Order, value: Instant) = receiver.copy(orderedAt = value)
    override val serializer: KSerializer<Instant> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[12] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(12) }
    override val default: Instant = now()
    override val defaultCode: String? = "now()" 
}
object Order_status: SerializableProperty<Order, OrderStatus> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(13) }
    override fun get(receiver: Order): OrderStatus = receiver.status
    override fun setCopy(receiver: Order, value: OrderStatus) = receiver.copy(status = value)
    override val serializer: KSerializer<OrderStatus> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[13] as KSerializer<OrderStatus> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(13) }
    override val default: OrderStatus = OrderStatus(OrderStatusCode.Ordered)
    override val defaultCode: String? = "OrderStatus(OrderStatusCode.Ordered)" 
}
object Order_comment: SerializableProperty<Order, String> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(14) }
    override fun get(receiver: Order): String = receiver.comment
    override fun setCopy(receiver: Order, value: String) = receiver.copy(comment = value)
    override val serializer: KSerializer<String> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[14] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(14) }
    override val default: String = ""
    override val defaultCode: String? = "\"\"" 
}
object Order_poNum: SerializableProperty<Order, String> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(15) }
    override fun get(receiver: Order): String = receiver.poNum
    override fun setCopy(receiver: Order, value: String) = receiver.copy(poNum = value)
    override val serializer: KSerializer<String> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[15] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(15) }
    override val default: String = ""
    override val defaultCode: String? = "\"\"" 
}
object Order_hidden: SerializableProperty<Order, Boolean> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(16) }
    override fun get(receiver: Order): Boolean = receiver.hidden
    override fun setCopy(receiver: Order, value: Boolean) = receiver.copy(hidden = value)
    override val serializer: KSerializer<Boolean> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[16] as KSerializer<Boolean> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(16) }
    override val default: Boolean = false
    override val defaultCode: String? = "false" 
}
object Order_requestJson: SerializableProperty<Order, String> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(17) }
    override fun get(receiver: Order): String = receiver.requestJson
    override fun setCopy(receiver: Order, value: String) = receiver.copy(requestJson = value)
    override val serializer: KSerializer<String> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[17] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(17) }
    override val default: String = ""
    override val defaultCode: String? = "\"\"" 
}
object Order_responseJson: SerializableProperty<Order, String> {
    override val name: String by lazy { Order.serializer().descriptor.getElementName(18) }
    override fun get(receiver: Order): String = receiver.responseJson
    override fun setCopy(receiver: Order, value: String) = receiver.copy(responseJson = value)
    override val serializer: KSerializer<String> by lazy { (Order.serializer() as GeneratedSerializer<*>).childSerializers()[18] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { Order.serializer().descriptor.getElementAnnotations(18) }
    override val default: String = ""
    override val defaultCode: String? = "\"\"" 
}
