package com.vandenbussche.views.screens.products

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.Routable
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.reactive.reactiveScope
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.direct.onlyWhen
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import com.vandenbussche.mappings.getWritable
import com.vandenbussche.models.*
import com.vandenbussche.sdk.currentSession
import com.vandenbussche.sdk.utils.modify
import com.vandenbussche.theming.chevronDown
import com.vandenbussche.theming.chevronUp
import com.vandenbussche.theming.heart
import com.vandenbussche.theming.heartFilled
import com.vandenbussche.toggle
import com.vandenbussche.tooltip
import com.vandenbussche.views.components.*
import com.vandenbussche.views.emptyView
import com.vandenbussche.views.screens.common.ContentDeterminedByAccount
import com.vandenbussche.views.screens.common.HasNarrowContent
import com.vandenbussche.views.screens.common.userHasAccount
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.joinAll
import kotlinx.coroutines.launch

val favorites = shared(useLastWhileLoading = true) {
    val session = currentSession()
    if (session.accountId == null) return@shared null

    session.favorites.watch(Query(
        condition { it._id.account eq session.accountId },
        orderBy = sort {
            it.priority.descending()
        }, limit = 1000
    ))()
}

private data class ProductAndFavorite(val product: Product, val favorite: Favorite) {
    init {
        if (product._id != favorite.product) throw IllegalStateException("Product and favorite don't match")
    }

    val priority get() = favorite.priority

    val inFavorites = Constant(product).inFavorites()
}

@Routable("Favorites")
class Favorites : ContentDeterminedByAccount, HasNarrowContent {
    override val noAccountMessage = "You do not have an account, so you can't have favorites"

    private val prodFaves = shared {
        val faves = favorites() ?: return@shared emptyList()
        val ids = faves.map { it.product }

        currentSession().products
            .query(
                Query(
                    condition = condition{ it._id inside ids },
                    limit = 1000
                )
            )()
            .mapNotNull { prod ->
                faves.find { it.product == prod._id }?.let { ProductAndFavorite(prod, it) }
            }
            .sortedByDescending { it.priority }
    }

    private fun ViewWriter.controlPanel(
        product: Readable<ProductAndFavorite>,
        expand: Boolean
    ) {
        padded - sizeConstraints(height = 5.5.rem) - centered - row {
            if (expand) expanding
            quantityField(product.lens { it.product })

            val inFavorites = product.getWritable { it.inFavorites }

            sizeConstraints(aspectRatio = Pair(1, 1)) - buttonTheme - button {
                onClick {
                    inFavorites.toggle()
                }
                ::enabled { currentSession().mayOrder }
                ::exists { userHasAccount() }

                centered - icon {
                    ::source {
                        if (inFavorites()) Icon.heartFilled
                        else Icon.heart
                    }
                }
            } in tooltip(PopoverPreferredDirection.aboveLeft) {
                if (inFavorites()) "Remove from favorites"
                else "Add to favorites"
            }
        }
    }

    override fun ViewWriter.hasAccount() {
        stack {
            onlyWhen { prodFaves().isEmpty() } - emptyView { centered - h2("No favorites, yet..") }

            expanding - recyclerView {
                children(prodFaves) { prodAndFave ->
                    val product = prodAndFave.lens { it.product }
                    val favorite = prodAndFave.lens { it.favorite }

                    compact - card - rowCollapsingToColumn {

                        expanding - row {
                            centered - col {
                                val controlsSpacing = 0.3.rem
                                spacing = 0.px

                                button {
                                    spacing = controlsSpacing
                                    centered - icon(Icon.chevronUp, "Move up")
                                    ::enabled { favorite().priority < prodFaves().size - 1 && currentSession().mayOrder }
                                    onClick {
                                        favorite().increment()
                                    }
                                }

                                button {
                                    spacing = controlsSpacing
                                    centered - icon(Icon.chevronDown, "Move down")
                                    ::enabled { favorite().priority > 0 && currentSession().mayOrder }
                                    onClick {
                                        favorite().decrement()
                                    }
                                }
                            }

                            expanding - productCard(product, 5.rem)
                        }

                        stack {
                            val split = split()

                            reactiveScope {
                                clearChildren()

                                split.controlPanel(prodAndFave, narrow())
                            }
                        }
                    }
                }
            }
        }
    }
}

suspend fun Favorite.increment() {
    val f = currentSession().favorites
    val nextHighest = favorites()?.reversed()?.firstOrNull { it.priority > priority } ?: run {
        println("None found for next highest")
        return
    }

    // Swap priorities
    coroutineScope {
        listOf(
            launch { f.get(_id).modify { it.priority assign nextHighest.priority } },
            launch { f.get(nextHighest._id).modify { it.priority assign priority } },
        ).joinAll()
    }
}

suspend fun Favorite.decrement() {
    val f = currentSession().favorites
    val nextLowest = favorites()?.firstOrNull { it.priority < priority } ?: run {
        println("None found for next lowest")
        return
    }

    // Swap priorities
    coroutineScope {
        listOf(
            launch { f.get(_id).modify { it.priority assign nextLowest.priority } },
            launch { f.get(nextLowest._id).modify { it.priority assign priority } },
        ).joinAll()
    }
}
