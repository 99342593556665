// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport", "UNCHECKED_CAST")

package com.vandenbussche.models

import com.lightningkite.lightningdb.*
import com.lightningkite.UUID
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.lightningserver.files.*
import com.lightningkite.now
import kotlin.time.Duration
import kotlinx.datetime.*
import kotlinx.serialization.SerialInfo
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import com.lightningkite.*

// Contextual types: com.lightningkite.UUID, kotlinx.datetime.Instant, com.lightningkite.lightningserver.files.ServerFile
fun prepareShippingAddressFields() {
    val props: Array<SerializableProperty<ShippingAddress, *>> = arrayOf(ShippingAddress__id, ShippingAddress_erpId, ShippingAddress_name, ShippingAddress_account, ShippingAddress_address)
    ShippingAddress.serializer().properties { props }
}
val <K> DataClassPath<K, ShippingAddress>._id: DataClassPath<K, UUID> get() = this[ShippingAddress__id]
val <K> DataClassPath<K, ShippingAddress>.erpId: DataClassPath<K, String?> get() = this[ShippingAddress_erpId]
val <K> DataClassPath<K, ShippingAddress>.name: DataClassPath<K, String?> get() = this[ShippingAddress_name]
val <K> DataClassPath<K, ShippingAddress>.account: DataClassPath<K, UUID?> get() = this[ShippingAddress_account]
val <K> DataClassPath<K, ShippingAddress>.address: DataClassPath<K, Address> get() = this[ShippingAddress_address]
inline val ShippingAddress.Companion.path: DataClassPath<ShippingAddress, ShippingAddress> get() = path<ShippingAddress>()


object ShippingAddress__id: SerializableProperty<ShippingAddress, UUID> {
    override val name: String by lazy { ShippingAddress.serializer().descriptor.getElementName(0) }
    override fun get(receiver: ShippingAddress): UUID = receiver._id
    override fun setCopy(receiver: ShippingAddress, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (ShippingAddress.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { ShippingAddress.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = UUID.random()
    override val defaultCode: String? = "UUID.random()" 
}
object ShippingAddress_erpId: SerializableProperty<ShippingAddress, String?> {
    override val name: String by lazy { ShippingAddress.serializer().descriptor.getElementName(1) }
    override fun get(receiver: ShippingAddress): String? = receiver.erpId
    override fun setCopy(receiver: ShippingAddress, value: String?) = receiver.copy(erpId = value)
    override val serializer: KSerializer<String?> by lazy { (ShippingAddress.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { ShippingAddress.serializer().descriptor.getElementAnnotations(1) }
    override val default: String? = null
    override val defaultCode: String? = "null" 
}
object ShippingAddress_name: SerializableProperty<ShippingAddress, String?> {
    override val name: String by lazy { ShippingAddress.serializer().descriptor.getElementName(2) }
    override fun get(receiver: ShippingAddress): String? = receiver.name
    override fun setCopy(receiver: ShippingAddress, value: String?) = receiver.copy(name = value)
    override val serializer: KSerializer<String?> by lazy { (ShippingAddress.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { ShippingAddress.serializer().descriptor.getElementAnnotations(2) }
    override val default: String? = ""
    override val defaultCode: String? = "\"\"" 
}
object ShippingAddress_account: SerializableProperty<ShippingAddress, UUID?> {
    override val name: String by lazy { ShippingAddress.serializer().descriptor.getElementName(3) }
    override fun get(receiver: ShippingAddress): UUID? = receiver.account
    override fun setCopy(receiver: ShippingAddress, value: UUID?) = receiver.copy(account = value)
    override val serializer: KSerializer<UUID?> by lazy { (ShippingAddress.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<UUID?> }
    override val annotations: List<Annotation> by lazy { ShippingAddress.serializer().descriptor.getElementAnnotations(3) }
    override val default: UUID? = null
    override val defaultCode: String? = "null" 
}
object ShippingAddress_address: SerializableProperty<ShippingAddress, Address> {
    override val name: String by lazy { ShippingAddress.serializer().descriptor.getElementName(4) }
    override fun get(receiver: ShippingAddress): Address = receiver.address
    override fun setCopy(receiver: ShippingAddress, value: Address) = receiver.copy(address = value)
    override val serializer: KSerializer<Address> by lazy { (ShippingAddress.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<Address> }
    override val annotations: List<Annotation> by lazy { ShippingAddress.serializer().descriptor.getElementAnnotations(4) }
}
